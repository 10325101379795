import * as React from 'react';
import { SafeAreaView, Text, TouchableOpacity, View } from 'react-native';

import { RootStackScreenProps } from '../types';

import styles_common from '../assets/styles/styles_common';


export default function NotFoundScreen({ navigation }: RootStackScreenProps<'NotFound'>) {
  return (
    <SafeAreaView style={styles_common.container_fl1_bg1}>
      <Text style={styles_common.title}>This screen doesn't exist.</Text>
      <TouchableOpacity onPress={() => navigation.replace('Root')} style={styles.link}>
        <Text >Go to home screen!</Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
}
