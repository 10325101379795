import { Dimensions, Platform, PixelRatio } from "react-native";
const { width, height } = Dimensions.get("window");

var scale = width / 640;

if (height > width) {
  scale = height / 500;
}

export function normalize_style(size) {
  var newSize = size * scale
  if (width > 1400) {
    newSize = newSize / 1.2
  }
  if (width > 1800) {
    newSize = newSize / 1.2
  }
  if (width > 2200) {
    newSize = newSize / 1.2
  }
  if (Platform.OS === 'ios') {
    newSize = Math.round(PixelRatio.roundToNearestPixel(newSize))
  } else {
    newSize = Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
  }
  if ((size > 0) && (newSize < 1)) {
    newSize = 1;
  }
  return newSize ;
}

export const COLORS = {
  // base colors
  primary: "#194868", // Dark Blue
  secondary: "#FF615F",   // peach

  // colors
  black: "#1E1F20",
  white: "#FFFFFF",
  lightGray: "#F5F7F9",
  lightGray2: '#FAFBFD',
  gray: "#BEC1D2",
  blue: '#42B0FF',
  darkgray: '#898C95',
  yellow: '#FFD573',
  lightBlue: '#95A9B8',
  darkgreen: '#008159',
  peach: '#FF615F',
  purple: '#8e44ad',
  red: '#FF0000',
  bgg_ci: '#222222',
  bg0_ci: '#000011',
  bg1_ci: '#000022',
  bg2_ci: '#001133',
  bg3_ci: '#001144',
  bg4_ci: '#002288',
  bg5_ci: '#0044aa',
  bg6_ci: '#2266cc',
  bgw1_ci: '#ffffff',
};

const stylessizes = {
  mini: {
    fontSize: normalize_style(12),
  },
  small: {
    fontSize: normalize_style(15),
  },
  medium: {
    fontSize: normalize_style(17),
  },
  large: {
    fontSize: normalize_style(20),
  },
  xlarge: {
    fontSize: normalize_style(24),
  },
};


export const SIZES = {
  // global sizes
  base: 8,
  font: 14,
  radius: 12,
  padding: 24,
  padding2: 36,

  // font sizes
  largeTitle: 50,
  h1: normalize_style(24),
  h2: normalize_style(18),
  h3: normalize_style(16),
  h4: normalize_style(14),
  h5: normalize_style(12),
  h6: normalize_style(10),
  h7: normalize_style(8),
  body1: 30,
  body2: 20,
  body3: 16,
  body4: 10,

  // app dimensions
  width,
  height
};

// const fontfamilymain ="Roboto-Regular";
// const fontfamilyhigh ="Roboto-Black";
// const fontfamilybold ="Roboto-Bold";

const fontfamilymain = "sans-serif";
const fontfamilyhigh = "sans-serif";
const fontfamilybold = "sans-serif";


export const FONTS = {
  largeTitle: { fontFamily: fontfamilymain, fontSize: SIZES.largeTitle, lineHeight: 55 },
  h1: { fontFamily: fontfamilyhigh, fontSize: SIZES.h1, lineHeight: normalize_style(36) },
  h2: { fontFamily: fontfamilybold, fontSize: SIZES.h2, lineHeight: normalize_style(20) },
  h3: { fontFamily: fontfamilybold, fontSize: SIZES.h3, lineHeight: normalize_style(16) },
  h4: { fontFamily: fontfamilybold, fontSize: SIZES.h4, lineHeight: normalize_style(15) },
  h5: { fontFamily: fontfamilybold, fontSize: SIZES.h5, lineHeight: normalize_style(14) },
  h6: { fontFamily: fontfamilybold, fontSize: SIZES.h6, lineHeight: normalize_style(12) },
  h7: { fontFamily: fontfamilybold, fontSize: SIZES.h7, lineHeight: normalize_style(9) },
  body1: { fontFamily: fontfamilymain, fontSize: SIZES.body1, lineHeight: 36 },
  body2: { fontFamily: fontfamilymain, fontSize: SIZES.body2, lineHeight: 30 },
  body3: { fontFamily: fontfamilymain, fontSize: SIZES.body3, lineHeight: 22 },
  body4: { fontFamily: fontfamilymain, fontSize: SIZES.body4, lineHeight: 22 },
};

const appTheme = { COLORS, SIZES, FONTS };

export default appTheme;