import React, { useEffect } from 'react';
import { log, logerror } from './Common';

import { SafeAreaView } from 'react-native-safe-area-context';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import { TextInput, View, Text, FlatList, TouchableHighlight, Image, Linking, Platform } from 'react-native';
import styles_screens from '../assets/styles/styles_screens'
import SubFlatListSwapMusicSelected from './SubFlatListSwapMusicSelected';
import { getlink, getlink_thumb, getlink_item } from './Common';
import { Video, AVPlaybackStatus } from 'expo-av';


export default class SearchScreen extends React.Component {
    video = React.createRef();

    constructor(props) {
        log('SearchScreen constructor  ');
        super(props);
        this.video = React.createRef();
        log('SearchScreen constructor this.props.route.params ' + JSON.stringify(this.props.route.params));
        var paramsearch = "";
        try {
            const params = this.props.route.params;
            paramsearch = params.searchtxt;
        } catch (e) {
            log('Error video  pauseAsync')
        }
        log('SearchScreen constructor params.searchtxt ' + paramsearch);
        this.state = {
            paused: props.paused,
            totalLength: 1,
            currentPosition: 0,
            selectedTrack: 0,
            repeatOn: false,
            shuffleOn: false,
            searchresults: [],
            isSearching: true,
            isMusic: false,
            isPhoto: false,
            isVideo: false,
            selected: [],
            search: "",
            searchtxt: paramsearch,
            videourl: ""
        }

    }

    componentDidUpdate() {
        log('SearchScreen componentDidUpdate  ');
        this.stopVideo();
    }

    componentDidMount() {
        log('SearchScreen componentDidUpdate  ');
        this.searchFunction(this.state.searchtxt);
        //this.searchFunction("gal");
    }

    setDuration(data) {
        this.setState({ totalLength: Math.floor(data.duration) });
        log('setDuration   ' + Math.floor(data.duration));
    }

    setTime(data) {
        this.setState({ currentPosition: Math.floor(data.currentTime) });
        log('setTime   ' + Math.floor(data.currentTime));
    }

    seek(time) {
        time = Math.round(time);
        this.refs.audioElement && this.refs.audioElement.seek(time);
        this.setState({
            currentPosition: time,
            paused: false,
        });
        log('seek   ' + time);
    }

    stopVideo() {

        try {
            log('stopVideo   ');
            this.video.current.pauseAsync();
            log('stopVideo done  ');
        } catch (e) {
            log('Error video  pauseAsync')
        }
        try {
            log('stopVideo   ');
            this.video.pauseAsync();
            log('stopVideo done  ');
        } catch (e) {
            log('Error video  pauseAsync')
        }
        log('stopVideo exit  ');
        this.state.videoloading = false;
    }

    getlinkVideo(item: []) {
        let href = global.videos_ovhbe;
        href = href + item.addfield2 + "/" + item.link_href;
        log('getlinkVideo ' + href); 0
        return href;
    }

    getlinkVideoPhoto(item: []) {
        let href = global.videos_ovhbe;
        href = href + item.addfield2 + "/" + item.picture;
        log('getlinkPhoto ' + href);
        return href;
    }


    getlinkPhoto_thumb(item: []) {
        let href = global.pictures + item.year + "/";
        if (item.direct != "") {
            href = href + item.direct + "/";
        }
        href = href + "thumb-" + item.link_href + ".jpg";

        // log('getlinkPhoto_thumb ' + href);
        return href;
    }

    searchFunction = (text: string) => {
        log('SearchScreen searchFunction text ' + text);
        if (text == "450267")
        {
            global.searchsucceed = true;
        }
        var textlen = 2;
        if (text.length > 0) {
            var s = text.substring(0, 1);
            if ((s == "1") || (s == "2")) {
                textlen = 3;
            }
        }
        if (text.length > textlen) {
            this.setState({ isSearching: true });
            var hlink = global.docker + 'search/search?searchtxt=' + text;
            fetch(encodeURI(hlink), {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                    'Content-Type': 'application/json'
                }
            }
            )
                .then((response) => response.json())
                .then((json) => {
                    log('searchFunction ' + json.length);
                    this.setState({ searchresults: json });
                }
                )
                .catch((error) => logerror("SearchScreen searchFunction error", error))
                .finally(() => this.setState({ isSearching: false }));
        }
    };

    getTableName(tablename: string) {
        let resptxt = "unknown";
        if (tablename == "video") {
            resptxt = "Video"
        }
        if (tablename == "photo") {
            resptxt = "Photo"
        }
        if (tablename == "music") {
            resptxt = "Musique"
        }
        return resptxt;
    }

    selectItem(item) {
        log('selectItem ' + JSON.stringify(item));
        this.stopVideo();

        global.yearselected = item.year;
        var itemv = item;
        itemv.orisinger = itemv.addfield2;
        itemv.orititle = itemv.addfield1;
        itemv.oripicture = itemv.picture;
        this.setState({ selected: itemv })

        if (item.tablename == "music") {
            this.setState({ isMusic: true })
            try {
                this.video.current.playAsync();
            } catch (e) {
                log('Error video  pauseAsync')
            }
        } else {
            this.setState({ isMusic: false })
        }
        if (item.tablename == "photo") {
            this.setState({ isPhoto: true })
        } else {
            this.setState({ isPhoto: false })
        }
        if (item.tablename == "video") {
            this.setState({ isVideo: true })
            this.setState({ videourl: this.getlinkVideo(item) })

            try {
                this.video.current.playAsync();
            } catch (e) {
                log('Error video  pauseAsync')
            }
        } else {
            this.setState({ isVideo: false })
        }
    }


    openphotourl(imageURL: string) {
        Linking.canOpenURL(imageURL).then(supported => {
            if (supported) {
                if (Platform.OS == 'web') {
                    window.open(imageURL, '_blank');
                } else {
                    Linking.openURL(imageURL);
                }
                1
            } else {
                log("Don't know how to open URI: " + href);
            }
        });
    }

    renderItem = (itemv: string) => {
        // log('SubFlatListPagination renderItem ');
        // log('item ' + JSON.stringify(item));
        var item = itemv.item;
        // log('item  ' + JSON.stringify(item));
        var f1 = this.getTableName(item.tablename);
        var f2 = item.year;
        var f3 = item.title;
        var f4 = "";
        var f5 = "";
        if (item.tablename == "music") {
            f4 = item.addfield1;
            f5 = item.addfield2;
        }
        var pictureUrl = "";
        if (item.tablename == "video") {
            pictureUrl = global.videos_ovhbe + item.addfield1 + "/" + item.addfield2;
        }

        return (
            <View style={styles_screens.container}>
                <TouchableHighlight
                    onPress={() => this.selectItem(item)}
                >
                    <View style={styles_screens.container_col_line}>
                        <View style={styles_screens.tableRow}>
                            <View style={styles_screens.container_col}>
                                {item.tablename == "video" ?
                                    <View style={styles_screens.tableRow}>
                                        <View style={styles_screens.tableColumnRegular1}>
                                            <Text style={styles_screens.mtext1}>
                                                {f2}
                                            </Text>
                                            <Text style={styles_screens.mtext1}>
                                                {f1}
                                            </Text>
                                        </View>
                                        <View style={styles_screens.tableColumnRegular2}>
                                            <Text style={styles_screens.mtext1}>
                                                {f3}
                                            </Text>
                                        </View>
                                        <View style={styles_screens.container}>
                                            <Image
                                                style={styles_screens.imageStyleThumb}
                                                source={{ uri: this.getlinkVideoPhoto(item) }}
                                                resizeMode="contain"
                                            />
                                        </View>
                                    </View>
                                    :
                                    null
                                }
                                {item.tablename == "photo" ?
                                    <View style={styles_screens.tableRow}>
                                        <View style={styles_screens.tableColumnRegular1}>
                                            <Text style={styles_screens.mtext1}>
                                                {f2}
                                            </Text>
                                            <Text style={styles_screens.mtext1}>
                                                {f1}
                                            </Text>
                                        </View>
                                        <View style={styles_screens.tableColumnRegular2}>
                                            <Text style={styles_screens.mtext1}>
                                                {f3}
                                            </Text>
                                        </View>
                                        <View style={styles_screens.container}>
                                            <Image
                                                style={styles_screens.imageStyleThumb}
                                                source={{ uri: this.getlinkPhoto_thumb(item) }}
                                                resizeMode="contain"
                                            />
                                        </View>
                                    </View>
                                    :
                                    null
                                }

                                {item.tablename == "music" ?
                                    <View style={styles_screens.tableRow}>
                                        <View style={styles_screens.tableColumnRegular1}>
                                            <Text style={styles_screens.mtext1}>
                                                {f2}
                                            </Text>
                                        </View>
                                        <View style={styles_screens.tableColumnRegular2}>
                                            <Text style={styles_screens.mtext1}>
                                                {f3}
                                            </Text>
                                        </View>
                                        <View style={styles_screens.tableColumnRegular2}>
                                            <Text style={styles_screens.mtext1}>
                                                {f4}
                                            </Text>
                                        </View>
                                    </View>
                                    :
                                    null
                                }
                                {item.tablename == "music" ?
                                    <View style={styles_screens.tableRow}>
                                        <View style={styles_screens.tableColumnRegular1}>
                                            <Text style={styles_screens.mtext1}>
                                                {f1}
                                            </Text>
                                        </View>
                                        <View style={styles_screens.tableColumnRegular2}>
                                            <Text style={styles_screens.mtext1}>
                                                { }
                                            </Text>
                                        </View>
                                        <View style={styles_screens.tableColumnRegular2}>
                                            <Text style={styles_screens.mtext1}>
                                                {f5}
                                            </Text>
                                        </View>

                                    </View>
                                    :
                                    null
                                }
                            </View>
                        </View>
                    </View>
                </TouchableHighlight >
            </View >
        )
    }
    renderStopButton() {
        if (this.state.playPressed) {
            return (
                <Button
                    onPress={() => {
                        //stop() does not work and video plays again
                        //trick below is a dirty alternative but works
                        setTimeout(() => this.player.seek(0), 0);
                        this.player.pause();
                    }}
                    title="Stop"
                />
            );
        }
    }

    render() {
        log("***** SearchScreen render " + this.state.isSearching);
        return (
            <View style={styles_screens.container}>
                <View style={styles_screens.container_dir}>
                    <View style={styles_screens.container}>
                        <View style={styles_screens.container_search}>
                            <TextInput
                                autoCapitalize="none"
                                autoCorrect={false}
                                clearButtonMode="always"
                                onChangeText={(text) => this.searchFunction(text)}
                                placeholder="Search"
                                style={styles_screens.search}
                            />
                            {this.state.isSearching ?
                                null
                                :
                                <View style={styles_screens.container_search_flatlist}>
                                    <FlatList
                                        data={this.state.searchresults}
                                        renderItem={item => this.renderItem(item)}
                                    />
                                </View>
                            }
                        </View>
                    </View>
                    <View style={styles_screens.container}>
                        {this.state.isMusic ?
                            <View style={styles_screens.container}>
                                <SubFlatListSwapMusicSelected
                                    music={this.state.selected}
                                    yearsel={global.yearselected}
                                />

                            </View>
                            :
                            null
                        }
                        {this.state.isPhoto ?
                            <TouchableHighlight
                                style={styles_screens.imageStyle}
                                key={this.state.selected.id}
                                onPress={() => {


                                    this.openphotourl(getlink_item(this.state.selected.year, this.state.selected));
                                }}>
                                <Image
                                    style={styles_screens.imageStyle}
                                    source={{ uri: getlink_item(this.state.selected.year, this.state.selected) }}
                                    resizeMode="contain"
                                />
                            </TouchableHighlight>
                            :
                            null
                        }
                        {this.state.isVideo ?
                            <View style={styles_screens.container}>
                                <Video
                                    style={styles_screens.video}
                                    ref={r => this.video = r}
                                    source={{
                                        uri: this.state.videourl,
                                    }}

                                    rate={1.0}
                                    volume={1.0}
                                    isMuted={false}
                                    resizeMode="contain"
                                    shouldPlay={true}
                                    useNativeControls
                                />
                            </View>
                            :
                            null
                        }
                    </View>
                </View>
            </View>
        );
    }
}
