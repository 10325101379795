import * as React from 'react';
import { StyleSheet } from 'react-native-webview';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import styles from '../assets/styles/styles_main';
import { WebView } from 'react-native-webview';
import Constants from 'expo-constants';
import { Platform } from "react-native";
import { log, error } from './Common';

export default function AboutScreen({ navigation }: RootTabScreenProps<'About'>) {
  var vhref = "https://www.gmaxi.ovh/about_ci.html";


  return Platform.OS === "web" ? (
    <iframe src={vhref} height={'100%'} width={'100%'} />
  ) : (
    <View style={{ flex: 1 }}>
      <WebView
        source={{ uri: vhref }}
        style={{ marginTop: 22, flex: 1 }}
      />
    </View>
  )
}

