import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';
import { normalize_style } from '../constants/theme';

const styles_tag = StyleSheet.create({
    container_subtags: {
        backgroundColor: COLORS.bg1_ci,
        alignItems: 'flex-start',
    },
    container: {
        flex: 1,
    },
    container_no_flex: {
        width: '60%',
    },
    
    container_noflex: {
        width: '100%',
        maxHeight: 200,
    },
    max_height: {
        maxHeight: 200,
    },
    container_space: {
        height: 20,
    },

    container_cols: {
        backgroundColor: COLORS.bg1_ci,
        flexDirection: "column",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
    },
    container_col: {
        flex: 1,
        backgroundColor: "transparent",
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        flexDirection: "column",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
    },


    container_row_top: {
        backgroundColor: COLORS.bg1_ci,
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
    },
    container_add_tag: {
        backgroundColor: "transparent",
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: "column",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
    },
    container_row: {
        backgroundColor: "transparent",
        justifyContent: 'flex-start',
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
    },
    text_tags: {
        backgroundColor: "transparent",
        ...FONTS.h5,
        color: "#ffffff"
    },
    text: {
        backgroundColor: "transparent",
        ...FONTS.h5,
        margin: 3,
        color: "#ffffff"
    },
    search: {
        backgroundColor: "transparent",
        ...FONTS.h5,
        margin: 2,
        color: "#ffffff",
        height: 30,
        borderWidth: 1,
        minWidth: 60,
        maxWidth: 200,
        padding: 2,
        borderColor: "#ffffff",
    },
    container_search_flatlist: {
        minHeight: 10,
    },
});

export default styles_tag;
