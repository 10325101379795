import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';
import { normalize } from 'react-native-elements';
import { normalize_style } from '../../screens/Common';

const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width

const fontfamilymain = "Roboto-Bold";
import {getProportion} from './styles__functions';


const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.bg1_ci,
        flex: 1,
    },
    container_home_lefts: {
        width: getProportion(140),
        minWidth: 125,
        flexDirection: "column",
    },

    container_image_ci: {
        alignItems: 'stretch',
        width: '100%',
        height: undefined,
        aspectRatio: 1,
        backgroundColor: COLORS.bg3_ci,
    },
    container_main: {
        justifyContent: 'center',
        alignItems: 'stretch',
        backgroundColor: COLORS.bg1_ci,
        flex: 1,

    },

    container_hor: {
        backgroundColor: COLORS.bg1_ci,
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        flex: 1,
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
    },
    container_home_left_years: {
        flex: 1,
    },
    width100: {
        width: "100%",
    },

    image_ci: {
        minHeight: 90,
        minWidth: 60,
        margin: 8,
        width: null,
        height: null,
        resizeMode: 'contain'
    },
    image_ci_bottom: {
        minHeight: 50,
        minWidth: 80,
        width: null,
        height: null,
        resizeMode: 'contain'
    },
    image_asbo_bottom: {
        minHeight: 40,
        minWidth: 30,
        maxHeight: 40,
        maxWidth: 50,
        resizeMode: 'contain'
    },

    image_ci_icon: {
        padding: 2 ,
        minWidth: 40,
    },
    container_home_right_year: {
        justifyContent: 'space-between',
        backgroundColor: COLORS.bg1_ci,
        height: normalize(60),
    },
    scrollview_left: {
        backgroundColor: COLORS.bg1_ci,
        flexGrow: 6,
        minWidth: 90,
        maxHeight: height - 115
    },
    flatlist: {
        display: 'flex', backgroundColor: COLORS.bg2_ci,
        borderWidth: 0,
    },
    container_main_left_down: {
        backgroundColor: COLORS.bg3_ci,
        flexDirection: "row",
    },
    imageThumbnail: {
        height: 100,
    },
    container_main_overflow: {
        overflow: 'scroll'
    },
    data_year: {
        position: 'absolute',
        top: 0,
        ...FONTS.h1,
        color: '#ffffff',
        textAlign: 'center',
        alignContent: 'flex-start',
        backgroundColor: COLORS.bg2_ci,
        width: '100%',
        height: '100%',
    },
    dataphotos: {
        backgroundColor: COLORS.bg4_ci,
        alignContent: 'center',
        flex: 1,
        margin: 5,
    },
    datavideos: {
        backgroundColor: COLORS.bg4_ci,
        alignContent: 'center',
        flex: 1,
        margin: 5,
    },
    toplinetext: {
        color: COLORS.white,
        ...FONTS.h2,
        textAlign: 'center', // <-- the magic
        marginLeft: 20,
        marginRight: 20,
        paddingTop: 8,
        paddingBottom: 4,
    },
    year_text: {
        color: COLORS.white,
        ...FONTS.h3,
        backgroundColor: COLORS.bg1_ci,
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },
    year_textselected: {
        color: COLORS.white,
        ...FONTS.h3,
        backgroundColor: COLORS.bg6_ci,
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },

    count_text: {
        color: COLORS.white,
        minWidth: 30,
        ...FONTS.h3,
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },
    customScrollBar: {
        backgroundColor: '#ccc',
        borderRadius: 3,
        width: 6,
    },
    customScrollBarBackground: {
        backgroundColor: COLORS.bg4_ci,
        borderRadius: 3,
        height: '100%',
        width: 6,
    },

    container_bottom: {
        flex: 1,
        position: 'absolute',
        bottom: 0
    },
    container_center: {
        backgroundColor: COLORS.bg1_ci,
        color: 'white',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        marginTop: 16,
        paddingVertical: 8,
        borderWidth: 4,
        borderColor: '#20232a',
        borderRadius: 6,
        backgroundColor: COLORS.bg4_ci,
        color: '#20232a',
        display: 'flex',
        textAlign: 'center',
        fontSize: 30,
        fontWeight: 'bold',
    },
    image_photo: {
        height: 362 * ratio, //362 is actual height of image
        width: width,
    },
    image: {
        flex: 1,
        resizeMode: 'stretch'
    },
    imagebackgroundfit: {
        aspectRatio: 1, position: 'absolute',
        width: '100%',
        height: '100%', justifyContent: "center",
        display: 'flex',
    },

    imagephotofit: {
        backgroundColor: COLORS.bg1_ci,
        position: 'absolute',
        aspectRatio: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
    },

    imagevideofit: {
        flex: 2,
        backgroundColor: COLORS.bg1_ci,
        justifyContent: "center",
        aspectRatio: 1,
    },

    affiche: {
        backgroundColor: COLORS.bg4_ci,
        flex: 1,
        justifyContent: "center",
        resizeMode: 'stretch',
        display: 'flex',
        padding: 5
    },
    redbox: {
        backgroundColor: '#f00',
        height: 20,
        width: 20,
    },
    test: {
        color: 'white',
        backgroundColor: '#f00',
        fontFamily: fontfamilymain
    },
    flex1: {
        flex: 1,
        color: COLORS.white,
        backgroundColor: '#ffffff',
    },


    submenu: {
        backgroundColor: COLORS.bg1_ci,
        position: 'absolute',
        right: 12,
        bottom: 12,
        padding: 0,
        alignContent: 'flex-end',
        width: getProportion(220),
        opacity: 1,
    },
    androidios: {
        backgroundColor: COLORS.bgw1_ci,
        position: 'absolute',
        bottom: normalize_style(20),
        left: normalize_style(20),
        padding: 0,
        height: normalize_style(50),
        width: "50%",
        minWidth: 300,
        maxWidth: 700,
        borderWidth: normalize_style(1.8),
        borderColor: COLORS.bg6_ci,
        color: COLORS.bg1_ci,
        justifyContent: 'center', //Centered vertically
    },
    androidios_container: {
        backgroundColor: COLORS.bgw1_ci,
        justifyContent: 'center', //Centered vertically
        flexDirection: 'row',
        width: "100%",
        height: "100%",
        flex: 1,
    },
    container_lineclosed: {
        backgroundColor: COLORS.bgw1_ci,
        flex: 1,
        flexDirection: 'row',
        width: "100%",
        height: "100%",
        justifyContent: 'center', //Centered vertically
    },
    container_line_midclosed: {
        backgroundColor: COLORS.bgw1_ci,
        width: "100%",
        height: "100%",
        justifyContent: 'center', //Centered vertically
        alignItems: 'flex-start', 
    },
    androidios_text: {
        backgroundColor: COLORS.bgw1_ci,
        color: COLORS.bg1_ci,
        paddingLeft : 25,
        width: "100%",
        fontSize: normalize_style(10),
        fontWeight: "bold",
    },
    androidios_left: {
        backgroundColor: COLORS.bgw1_ci,
        width: "100%",
        height: "100%",
        flexWrap: "wrap",
    },
    androidios_right: {
        backgroundColor: COLORS.bgw1_ci,
        position: 'absolute',
        top: 10,
        right: 10,
    },
    ios_icon: {
        width: normalize_style(24),
        height: normalize_style(36),
        resizeMode: 'contain'
    },
    submenuclosed: {
        backgroundColor: COLORS.bg4_ci,
        position: 'absolute',
        right: 12,
        bottom: 12,
        padding:0,
        alignContent: 'flex-end',
        width: getProportion(120),
        opacity: 1,
    },

});

export default styles;