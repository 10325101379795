import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { normalize } from 'react-native-elements';
import { normalize_style, normalize_style_font } from '../../screens/Common';

import { Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width

const fontfamilymain = "Roboto-Bold";
import { getProportion, getFlexPhotoVideos, getFlexBottomAffiche, getLineTextPadding } from './styles__functions';

const bottomAffiche = getFlexBottomAffiche();
const flexPhotoVideos = getFlexPhotoVideos();

const styles_home = StyleSheet.create({
    container: {
        backgroundColor: COLORS.bg0_ci,
        flex: 1
    },
    container_home_lefts: {
        backgroundColor: 'transparent',
        width: getProportion(140),
        minWidth: 125,
        flexDirection: "column",
    },

    container_home_cols: {
        backgroundColor: "transparent",
        flexDirection: 'column',
    },
    container_hor_top: {
        backgroundColor: "transparent",
        flexDirection: 'row',
        paddingTop: normalize_style(5),
    },
    
    container_home_rights: {
        backgroundColor: COLORS.bg6_ci,
        flexDirection: 'column',
        flex: bottomAffiche,
        flexWrap: "wrap",
    },

    container_image_ci: {
        alignItems: 'stretch',
        width: '100%',
        height: undefined,
        aspectRatio: 1,
    },

    container_hor: {
        backgroundColor: COLORS.bg1_ci,
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        flex: 1,
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
    },

    container_home_left_years: {
        backgroundColor: COLORS.bg1_ci,
        flex: 1,
    },

    container_home_right_top_years: {
        justifyContent: 'space-between',
        backgroundColor: COLORS.bg1_ci,
        height: normalize(70),
    },

    container_home_right_top_year_hor_main: {
        backgroundColor: "transparent",
        justifyContent: 'flex-end',
        height:"100%",
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
    },

    container_home_right_top_year_hor: {
        flex: 1,
        backgroundColor: "transparent",
        justifyContent: 'center',
        top : 15,
        height:"100%",
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
    },

    container_home_right_top_year_space: {
        backgroundColor: "transparent",
        width: 6,
    },

    container_home_right_top_year_ham: {
        backgroundColor: COLORS.bg1_ci,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: "column",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        paddingTop: normalize_style(5),
        paddingRight: normalize_style(6),
    },

    text_ham: {
        color: COLORS.white,
        ...FONTS.h7,
        textAlign: 'center', // <-- the magic
    },

    container_home_right_main: {
        backgroundColor: COLORS.bg1_ci,
        flex: 1,
        flexGrow: 1,  // number
        width: '100%',
        height: '100%',
        borderColor: COLORS.bg5_ci,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        padding: 4
    },

    container_flexDirection_row: {
        flexDirection: "row",
    },

    container_flexDirection_column: {
        flexDirection: "column",
    },

    container_home_right_main_zone_pv: {
        backgroundColor: 'transparent',
        flexDirection: "row",
        flex: flexPhotoVideos,
        width: '100%',
        height: '100%',
        minHeight: 50,
        minWidth: 100,
        flexWrap: 'wrap',
        margin: 4
    },

    container_home_right_main_zone_bottom_affiche: {
        flex: bottomAffiche,
        flexGrow: bottomAffiche,
        alignSelf: "stretch",
        backgroundColor: 'transparent',
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
        height: '100%',
        padding: 5,
        minHeight: 50,
        minWidth: 100,
        flexWrap: "wrap",
    },
    zone_pv_photos_touchable: {
        backgroundColor: 'transparent',
        flex: 6,
        flexGrow: 6,
        justifyContent: "center",
        alignContent: 'center',
        minHeight: 100,
        minWidth: 50,
        flexWrap: "wrap",
        margin: 10,
    },
    zone_pv_photos_view: {
        backgroundColor: 'transparent',
        justifyContent: "center",
        alignContent: 'center',
        width: '96%',
        height: '96%',
        minHeight: 100,
        minWidth: 50,
        flexWrap: "wrap",
    },
    zone_pv_photos_image_sel: {
        backgroundColor: 'transparent',
        justifyContent: "flex-end",
        position: 'absolute',
        aspectRatio: 1,
        width: '100%',
        height: '90%',
        display: 'flex',
    },

    zone_pv_photos_image_bg: {
        backgroundColor: 'transparent',
        aspectRatio: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: "center",
    },
    zone_pv_videos_touchable: {
        backgroundColor: 'transparent',
        flex: 6,
        flexGrow: 6,
        width: '96%',
        height: '96%',
        minHeight: 100,
        minWidth: 200,
        flexWrap: "wrap",
        marginTop: 5,
    },
    zone_pv_videos_view: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignContent: 'flex-start',
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
        height: '100%',
        flex: 1,

    },
    zone_pv_videos_image_zone_image_sel_col: {
        backgroundColor: 'transparent',
        justifyContent: 'space-evenly',
        flexDirection: "column",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        flexBasis: 1,
        flexShrink: 1,
        flexGrow: 1,
        flex: 1,
    },
    zone_pv_videos_image_zone_topdown: {
        width: '100%',
        height: '100%',
        flex: 10,
        flexBasis: 10,
        flexShrink: 10,
        flexGrow: 10,
        backgroundColor: COLORS.red,
    },
    zone_pv_videos_image_zone_image_sel_row: {
        backgroundColor: 'transparent',
        justifyContent: 'center', //Centered vertically
        alignContent: "center",
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
        height: '100%',
        flexBasis: 1,
        flexShrink: 1,
        flexGrow: 1,
        flex: 1,
    },
    zone_pv_videos_image_sel: {
        flex: 20,
        backgroundColor: 'transparent',
        alignContent: 'flex-start',
        width: '96%',
        height: '96%',
        justifyContent: "flex-start",
        minHeight: 20,
        minWidth: 30,
    },

    zone_pv_videos_image_zone_right: {
        flex: 6,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    },

    zone_pv_videos_image_bg: {
        backgroundColor: 'transparent',
        aspectRatio: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: "center",
    },

    zone_af_touchable: {
        backgroundColor: 'transparent',
        flex: 6,
        flexGrow: 6,
        width: '96%',
        height: '96%',
        minHeight: 100,
        minWidth: 200,
        flexWrap: "wrap",
        marginTop: 5,
    },
    zone_af_view: {
        backgroundColor: 'transparent',
        alignContent: 'center',
        width: '100%',
        height: '100%',
        flex: 1,
    },

    zone_af_image: {
        backgroundColor: 'transparent',
        aspectRatio: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: "center",
    },


    dataaffiche_touchable: {
        padding: 10,
        flex: 1,
        width: "100%",
        height: "100%",
    },


    dataaffiche: {
        backgroundColor: 'transparent',
        flex: 1,
    },
    zone_af_affiche: {
        flex: 2,
        backgroundColor: 'transparent',
        resizeMode: 'contain',
        height: "100%",
        minHeight: 100,
        minWidth: 200,
        aspectRatio: 1,
    },
    width100: {
        width: "100%",
    },

    image_ci: {
        backgroundColor: 'transparent',
        minHeight: 90,
        minWidth: 60,
        width: "90%",
        height: null,
        margin: 8,
        resizeMode: 'contain'
    },
    image_ci_bottom: {
        minHeight: 50,
        minWidth: 80,
        width: null,
        height: null,
        resizeMode: 'contain'
    },
    image_asbo_bottom: {
        minHeight: 40,
        minWidth: 30,
        maxHeight: 40,
        maxWidth: 50,
        resizeMode: 'contain'
    },

    image_ci_icon: {
        padding: 2
    },

    scrollview_left: {
        backgroundColor: COLORS.bg1_ci,
        flexGrow: 6,
        minWidth: 90,
        maxHeight: height - 115
    },
    flatlist: {
        display: 'flex', backgroundColor: COLORS.bg2_ci,
        borderWidth: 0,
    },
    container_main_left_down: {
        backgroundColor: COLORS.bg3_ci,
        flexDirection: "row",
    },
    imageThumbnail: {
        height: 100,
    },
    container_main_overflow: {
        overflow: 'scroll'
    },
    data_year: {
        position: 'absolute',
        top: 0,
        ...FONTS.h1,
        color: '#ffffff',
        textAlign: 'center',
        alignContent: 'flex-start',
        backgroundColor: COLORS.bg2_ci,
        width: '100%',
        height: '100%',
    },
    toplinetext: {
        color: COLORS.white,
        ...FONTS.h3,
        textAlign: 'center', // <-- the magic
        marginLeft: getLineTextPadding(),
        marginRight: getLineTextPadding(),
        paddingTop: getLineTextPadding(),
        paddingBottom: getLineTextPadding(),
    },
    year_text: {
        color: COLORS.white,
        ...FONTS.h3,
        backgroundColor: COLORS.bg1_ci,
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },
    year_textselected: {
        color: COLORS.white,
        ...FONTS.h3,
        backgroundColor: COLORS.bg6_ci,
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },

    count_text: {
        color: COLORS.white,
        minWidth: 30,
        ...FONTS.h3,
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },
    customScrollBar: {
        backgroundColor: '#ccc',
        borderRadius: 3,
        width: 6,
    },
    customScrollBarBackground: {
        backgroundColor: COLORS.bg4_ci,
        borderRadius: 3,
        height: '100%',
        width: 6,
    },

    container_bottom: {
        flex: 1,
        position: 'absolute',
        bottom: 0
    },
    container_center: {
        backgroundColor: COLORS.bg1_ci,
        color: 'white',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        marginTop: 16,
        paddingVertical: 8,
        borderWidth: 4,
        borderColor: '#20232a',
        borderRadius: 6,
        backgroundColor: COLORS.bg4_ci,
        color: '#20232a',
        display: 'flex',
        textAlign: 'center',
        fontSize: 30,
        fontWeight: 'bold',
    },
    image_photo: {
        height: 362 * ratio, //362 is actual height of image
        width: width,
    },
    image: {
        flex: 1,
        resizeMode: 'stretch'
    },
    redbox: {
        backgroundColor: '#f00',
        height: 20,
        width: 20,
    },
    test: {
        color: 'white',
        backgroundColor: '#f00',
        fontFamily: fontfamilymain
    },
    flex1: {
        flex: 1,
        color: COLORS.white,
        backgroundColor: '#ffffff',
    },


    scrollView: {
        backgroundColor: "transparent",
        position: 'absolute',
        right: 12,
        bottom: 12,
        height: "80%",
        width: getProportion(270),
        opacity: 1,
    },
    submenu: {
        backgroundColor: COLORS.bg1_ci,
        position: 'absolute',
        right: 12,
        bottom: 12,
        padding: 0,
        alignContent: 'flex-end',
        width: getProportion(270),
        opacity: 1,
    },

    zone_androidios_absolute: {
        backgroundColor: COLORS.bgw1_ci,
        position: 'absolute',
        bottom: normalize_style(12),
        left: normalize_style(12),
        padding: 0,
        height: normalize_style(55),
        width: "55%",
        minWidth: 300,
        maxWidth: 900,
        borderWidth: normalize_style(1.8),
        borderColor: COLORS.bg6_ci,
        color: COLORS.bg1_ci,
        justifyContent: 'center', //Centered vertically
    },
    zone_androidios_container: {
        backgroundColor: COLORS.bgw1_ci,
        justifyContent: 'center', //Centered vertically
        flexDirection: 'row',
        width: "100%",
        height: "100%",
        flex: 1,
    },
    zone_androidios_lineclosed: {
        backgroundColor: COLORS.bgw1_ci,
        flex: 1,
        flexDirection: 'row',
        width: "100%",
        height: "100%",
        justifyContent: 'center', //Centered vertically
    },
    zone_androidios_line_midclosed: {
        backgroundColor: COLORS.bgw1_ci,
        width: "100%",
        height: "100%",
        justifyContent: 'space-evenly', //Centered vertically
        alignItems: 'center',
    },
    zone_androidios_text: {
        backgroundColor: COLORS.bgw1_ci,
        color: COLORS.bg1_ci,
        paddingLeft: 25,
        width: "100%",
        fontSize: normalize_style_font(10),
        fontWeight: "bold",
    },
    zone_androidios_left: {
        backgroundColor: COLORS.bgw1_ci,
        width: "100%",
        height: "100%",
        flexWrap: "wrap",
    },
    zone_androidios_right: {
        backgroundColor: COLORS.bgw1_ci,
        position: 'absolute',
        top: 5,
        right: 5,
    },
    zone_androidios_ios_icon: {
        width: normalize_style(36),
        height: normalize_style(48),
        resizeMode: 'contain'
    },
    submenuclosed: {
        backgroundColor: COLORS.bg4_ci,
        position: 'absolute',
        right: 12,
        bottom: 12,
        padding: 0,
        alignContent: 'flex-end',
        width: getProportion(120),
        opacity: 1,
    },

});

export default styles_home;