import {
  StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';
import { normalize_style } from '../constants/theme';
import { getProportion } from './styles__functions';


const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  container_web: {
    marginTop: 20,
    minHeight: 200,
    minWidth: 200,
    backgroundColor: 'transparent',
  },
  container_hor: {
    flex: 1,
    flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
    backgroundColor: 'transparent',
  },
  container_col: {
    flexDirection: "column",  // enum('row', 'row-reverse', 'column', 'column-reverse')
    backgroundColor: 'transparent',
  },
  container_videoleft: {
    minWidth: normalize_style(130),
    justifyContent: 'center',
    flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
    backgroundColor: 'transparent',
  },
  container_videoright: {
    flex: 1,
    height: '100%',
    width: '100%',
    alignItems: 'flex-start',
    borderTopWidth: normalize_style(1.8),
    borderLeftWidth: normalize_style(1.8),
    borderColor: COLORS.bg6_ci,

  },
  container_max: {
    flex: 1,
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
    alignItems: 'flex-start',
 },
 carousel: {
    minWidth: normalize_style(120),
    flex: 1,
    margin: 3,
  },
  slideImageThumb: {
    justifyContent: "center",
    minWidth: normalize_style(100),
    minHeight: normalize_style(70),
    alignItems: "center",
  },

  video: {
    flex: 1,
    minHeight: 200,
    minWidth: 100,
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },

  titleStyle: {
    padding: 16,
    ...FONTS.h4,
    color: 'white',
  },
  imageContainerStyle: {
    flex: 1,
    flexDirection: 'column',
    margin: 4,
  },
  imageStyle: {
    height: 200,
    width: '100%',
    resizeMode: 'stretch',
  },
  fullImageStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%',
    width: '95%',
  },
  modalStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.bg2_ci,
  },
  closeButtonStyle: {
    top: 8,
    right: 8,
    position: 'absolute',
  },
  icon: {
    padding: 8,
  },
  container_subtags: {
    width: '100%',
    maxHeight: 150,
    backgroundColor: COLORS.bg2_ci,
  },

});

export default styles;
