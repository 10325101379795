import React, { useEffect, useState } from 'react';
import { getStyle, log, logerror } from './Common';

import { SafeAreaView } from 'react-native-safe-area-context';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import { TextInput, View, Text, FlatList, TouchableHighlight, Image, Linking, Platform } from 'react-native';
import styles_screens from '../assets/styles/styles_screens'
import SubFlatListSwapMusicSelected from './SubFlatListSwapMusicSelected';
import { getlink, getlink_thumb, getlink_item } from './Common';
import { Video, AVPlaybackStatus } from 'expo-av';

export default function Tags(this: any, { navigation }: RootTabScreenProps<'Tags'>) {

    let [searchresultsmaster, setSearchresultsmaster] = useState([]);
    let [searchresults, setSearchresults] = useState([]);
    let [search, setSearch] = useState("");
    let [isSearching, setSisSearching] = useState(false);


    useEffect(() => {
        log('------------  Tags useEffect');

        getAllTags();
    }, [])

    async function getAllTags() {
        log('Tags getAllTags  ');
        setSisSearching(true);
        var hlink = global.docker + 'tag/listcount';
        fetch(encodeURI(hlink))
            .then((response) => response.json())
            .then((json) => {
                log('Tags getAllTags ' + json.length);
                setSearchresultsmaster(json);
                setSearchresults(json);
            }
            )
            .catch((error) => logerror("Tags getAllTags error", error))
            .finally(() => setSisSearching(false));
    };

    async function searchFilterFunction(text: string) {
        // Check if searched text is not blank
        if (text) {
            // Inserted text is not blank
            // Filter the masterDataSource
            // Update FilteredDataSource
            const newData = searchresultsmaster.filter(
                function (item) {
                    const itemData = item.title
                        ? item.title.toUpperCase()
                        : ''.toUpperCase();
                    const textData = text.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
            setSearchresults(newData);
            setSearch(text);
        } else {
            // Inserted text is blank
            // Update FilteredDataSource with masterDataSource
            setSearchresults(searchresultsmaster);
            setSearch(text);
        }
    };


    function selectItem(item: String) {
        var itemv = item;
        navigation.navigate('Search', { searchtxt: item.title });
    }

    function renderItem(itemv: JSON) {
        // log('SubFlatListPagination renderItem ');
        // log('item ' + JSON.stringify(item));
        var item = itemv.item;
        var f1 = item.title;
        var f2 = item.count;
        var f3 = item.info;
        log('item  ' + f3);

        return (
            < View style={[styles_screens.container]} >
                <TouchableHighlight
                    onPress={() => selectItem(item)}
                >
                    <View style={styles_screens.container_col}>
                        <View style={styles_screens.tableRow}>
                            <View style={styles_screens.tableColumnRegular0}>
                                <Text style={styles_screens.mtext1}>
                                    {f2}
                                </Text>
                            </View>
                            <View style={styles_screens.tableColumnRegular2}>
                                <Text style={styles_screens.mtext1}>
                                    {f1}
                                </Text>
                            </View>
                            <View style={styles_screens.tableColumnRegular3}>
                                <Text style={styles_screens.mtexts}>
                                    {f3}
                                </Text>
                            </View>
                        </View>
                    </View>
                </TouchableHighlight>
            </View >
        )
    }


    return (
        <View style={styles_screens.container}>
            <View style={styles_screens.container_col}>
                <View style={styles_screens.container_row_search}>
                    <TextInput
                        style={styles_screens.search}
                        onChangeText={(text) => searchFilterFunction(text)}
                        value={search}
                        underlineColorAndroid="transparent"
                        placeholder="Search Here"
                    />
                    <View style={styles_screens.container_found}>
                        <Text style={styles_screens.titleStyle}>
                            Trouvé : {searchresults.length}
                        </Text>
                    </View>
                </View>
                <View style={styles_screens.flatList_search}>
                    <FlatList
                        data={searchresults}
                        renderItem={item => renderItem(item)}
                    />
                </View>
            </View>
        </View>
    );
}
