import React, { useCallback, memo, useRef, useState } from "react";

import { StyleSheet, Text, View, Dimensions, WebView, Button } from 'react-native';
import { SafeAreaView, FlatList, Modal, Image, Platform, TouchableOpacity, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import styles_swap from '../assets/styles/styles_swap';
import styles_music from '../assets/styles/styles_music';
import styles_ori_landscape from '../assets/styles/styles_ori_landscape';
import { getlink } from './Common.tsx';
import { getlink_thumb, getlink_item } from "./Common";
import ci from '../assets/images/ci.png';
import { Audio, AVPlaybackStatus } from 'expo-av';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { getOrientation, getStyle } from './Common';
import { getlink_music_photo } from './Common';
import { log, error } from './Common';


export default class SubFlatListSwapMusicSelected extends React.Component {

  constructor(props) {
    log('SubFlatListSwapMusicSelected constructors  ');
    super(props);
    sound: Audio.Sound | null;

    this.state = {
      isPlaying: false,
      playbackStatus: null,
      loading: false,
      playbackObject: new Audio.Sound(),
      music: [],
      id_song: '',
      title: '',
      orititle: '',
      orisinger: '',
      photo: '',
      yearsel: '',
      musicurl: '',
      videosacive: false,
      sizeIcon: 22,
    }
    this.state.music = this.props.music;
    this.state.videosacive = (global.enablevideo == "1");
  }

  componentDidUpdate(prevProps) {
    // log('SubFlatListSwapMusicSelected componentDidUpdate  ');
    //log('SubFlatListSwapMusicSelected props ' + JSON.stringify(this.props.music));
    //log('SubFlatListSwapMusicSelected prevProps ' + JSON.stringify(prevProps.music));
    //log('SubFlatListSwapMusicSelected prevProps ' + (this.props.music != prevProps.music));
    //log('SubFlatListSwapMusicSelected prevProps ' + (this.props.music.length));
    if ((this.props.music != prevProps.music)) {
      log('SubFlatListSwapMusicSelected changed ');
      this.setState({ music: this.props.music })
      this.setState({ id_song: this.props.music.id_song })
      this.setState({ title: this.props.music.title })
      this.setState({ orititle: this.props.music.orititle })
      this.setState({ orisinger: this.props.music.orisinger })
      this.setState({ photo: this.props.music.oripicture })
      this.setState({ musicurl: this.props.music.link_href })
      this.setState({ yearsel: this.props.yearsel })
      global.yearsel = this.props.yearsel;
      log('id_song ' + this.state.id_song)
      log('title ' + this.state.title)
    }
    this.state.musicloading = false;
    //    log('SubFlatListSwap componentDidUpdate  ' + JSON.stringify(this.state.imageSrc));
    this.showStatus("componentDidUpdate");
  }


  showStatus(step) {
    log(step + "  playbackStatus " + this.state.playbackStatus + "  isPlaying " + this.state.isPlaying + "  musicloading " + this.state.musicloading);
  }

  getlinkMusic() {
    //  log('item ' + JSON.stringify(item));
    let href = global.videos_ovhbe + 'ci/musics/' + this.yearsel + "/" + item.link_href;
    log('getlinkMusic ' + href);
    return href;
  }

  getlinkPhoto(item: []) {
    let href = global.videos_ovhbe + 'ci/musics/' + item.vdir + "/" + item.photo;
    log('getlinkPhoto ' + href);
    return href;
  }

  componentDidMount() {
    log('SubFlatListSwapMusicSelected  componentDidMount');
  }

  componentWillUnmount() {
    log('SubFlatListSwapMusicSelected  componentWillUnmount');
    if (this.state.isPlaying) {
      this.state.playbackObject.pauseAsync();
    }
  }

  render() {
    function viewPrevious() {
      indexRef.current = indexRef.current - 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);
    }

    function viewNext() {
      indexRef.current = indexRef.current + 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);
      onScroll;
    }

    const handleAudioPlayPause = async () => {
      this.showStatus("handleAudioPlayPause bef");
      try {

        if (this.state.playbackObject !== null && this.state.playbackStatus === null) {
          log("handleAudioPlayPause null loadAsync ")
          const urival = "https://gmaxi.be/ci/musics/" + this.yearsel + "/" + this.state.musicurl + ".mp3"
          log("handleAudioPlayPause urival " + urival)
          const status = await this.state.playbackObject.loadAsync(
            { uri: urival },
            { shouldPlay: true }
          );
          this.setState({ isPlaying: true })
          this.setState({ playbackStatus: status })
          return status;
        }

        // It will pause our audio
        if (this.state.isPlaying) {
          log("handleAudioPlayPause isPlaying ")
          const status = await this.state.playbackObject.pauseAsync();
          this.setState({ isPlaying: false })
          this.setState({ playbackStatus: status })
          return status;
        }

        // It will resume our audio
        if (!this.state.playbackStatus.isPlaying) {
          log("handleAudioPlayPause not isPlaying ")
          const status = await this.state.playbackObject.playAsync();
          this.setState({ isPlaying: true })
          this.setState({ playbackStatus: status })
          return status;
        }
      } catch (e) {
        log('Error handleAudioPlayPause ' + e)
      }

      this.showStatus("handleAudioPlayPause aft");

    };

    const handleAudioStop = async () => {
      try {
        log("handleAudioStop ")
        log("handleAudioPlayPause isPlaying ")
        await this.state.playbackObject.stopAsync()
        await this.state.playbackObject.unloadAsync()
        this.setState({ isPlaying: false })
        this.setState({ playbackStatus: status })
      } catch (e) {
        log('Error handleAudioStop ' + e)
      }


      log("handleAudioPlayPause isPlaying ")
      const urival = global.musics + this.state.yearsel + "/" + this.state.musicurl + ".mp3"
      log("handleAudioPlayPause urival " + urival)
      const status = await this.state.playbackObject.loadAsync(
        { uri: urival },
        { shouldPlay: true }
      );
      this.setState({ isPlaying: true })
      this.setState({ playbackStatus: status })
      return status;

    };

    function renderRow(itemleft, itemright) {
      log("Dimensions width " + width)
      log("Dimensions height " + height)
      //  log("Dimensions getStyle " + JSON.stringify(getStyle()) )

      return (
        <View style={styles_music.container_ver}>
          <View style={styles_music.tableRow}>
            <View style={styles_music.tableColumnRegular1}>
              <Text style={styles_music.mtext1}>
                {itemleft}
              </Text>
            </View>
            <View style={styles_music.tableColumnRegular2}>
              <Text style={styles_music.mtext1}>
                {itemright}
              </Text>
            </View>
          </View>
        </View>


      );
    }

    const loadInBrowser = async () => {
      const urival = global.musics + this.state.yearsel + "/" + this.state.musicurl + ".mp3"
      if (Platform.OS == 'web') {
        window.open(urival, '_blank');
      } else {
        Linking.openURL(urival)
      }

    };

    return (
      <SafeAreaView style={styles_music.container_hor}>
        <View style={{ flex: 1 }} >
          {(this.state.title.length == 0) ?
            <View style={styles_music.container_ver}>
              <Text style={styles_music.button_zone_text}>
                Choisir une chanson ... ...
              </Text>
            </View>
            :

            <View style={styles_music.container_ver}>
              <View style={styles_music.container_music_top}>
                <View style={styles_ori_landscape.container_hv1}>
                  <View style={styles_music.tableContainer}>
                    {renderRow("Chanson:", this.state.title)}
                    {renderRow("Titre:", this.state.orititle)}
                    {renderRow("Auteur:", this.state.orisinger)}
                  </View>
                  {this.state.videosacive ?

                    <View style={styles_ori_landscape.container_hv2}>
                      <TouchableOpacity style={[styles_music.button_zone]} onPress={handleAudioStop}>
                        <Text style={styles_music.button_zone_text}>Play</Text>
                        <Ionicons
                          style={[styles_music.icon]}
                          size={this.state.sizeIcon}
                          name="arrow-redo-outline"
                        />
                      </TouchableOpacity>

                      <TouchableOpacity style={[styles_music.button_zone]} onPress={handleAudioPlayPause}>
                        <Text style={styles_music.button_zone_text}>{this.state.isPlaying ? 'Pause' : null}</Text>
                        <Ionicons
                          style={[styles_music.icon]}
                          size={this.state.sizeIcon}
                          name={this.state.isPlaying ? 'pause' : null}
                        />
                      </TouchableOpacity>

                      <TouchableOpacity style={[styles_music.button_zone]} onPress={loadInBrowser}>
                        <Text style={styles_music.button_zone_text}>WEB</Text>
                        <Ionicons
                          style={[styles_music.icon]}
                          size={this.state.sizeIcon}
                          name="at-sharp"
                        />
                      </TouchableOpacity>
                    </View>
                    :
                    null
                  }
                </View>
              </View>
              <View style={getStyle().container_image}              >
                <TouchableOpacity style={styles_ori_landscape.container_image}
                  onPress={() => {
                    if (Platform.OS == 'web') {
                      window.open(getlink_music_photo(this.state.yearsel, this.state.photo), '_blank');
                    } else {
                      Linking.openURL(getlink_music_photo(this.state.yearsel, this.state.photo))
                    }
                  }
                  }
                >
                  <Image
                    style={styles_music.imageStyle}
                    source={{ uri: getlink_music_photo(this.state.yearsel, this.state.photo) }}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            </View>


          }
        </View>

      </SafeAreaView >
    )
  }
}

const { width: windowWidth, height: windowHeight } = Dimensions.get("window");
const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width
