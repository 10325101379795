import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';
import { normalize_style } from '../constants/theme';

const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width

const fontfamilymain = "Roboto-Bold";

function minHeightMenu() {
    var minh = 40;
    var newh = (height - 300) / 14;
   // console.log("newh " + newh);
    if (newh > 20) { return newh }
    else {return 15}
    
}

const styles_submenu = StyleSheet.create({
    container: {
        backgroundColor: "#004400",
        flexDirection: 'column',
        flex: 1,
        alignContent: 'flex-end',
        flexWrap: "wrap",
        opacity: 0.5,
        textAlign: 'right',
    },
    container_line: {
        margin: 1,
        marginTop: normalize_style(3),
        flex: 1,
        flexDirection: 'row',
        height: "100%",
        alignContent: 'flex-end',
        textAlign: 'right',
        borderWidth: 1,
        minHeight: minHeightMenu(),
        borderColor: COLORS.bg6_ci,
    },
    container_linegr: {
        margin: 1,
        backgroundColor: "#004400",
        flex: 1,
        flexDirection: 'row',
        height: "100%",
        minHeight: minHeightMenu(),
        alignContent: 'flex-end',
        textAlign: 'right',
        borderWidth: normalize_style(1.8),
        borderColor: "#669966",
    },
    padding4: {
        padding: 5
    },
    container_lineclosed: {
        backgroundColor: COLORS.bg1_ci,
        marginTop: 4,
        flex: 1,
        flexDirection: 'row',
        height: "100%",
        alignContent: 'flex-end',
        textAlign: 'right',
        borderWidth: normalize_style(1.8),
        borderColor: COLORS.bg6_ci,
    },
    bgr0: {
        backgroundColor: "#002200",
    },
    bgr1: {
        backgroundColor: "#003300",
    },
    bgr2: {
        backgroundColor: "#115511",
    },
    bgr3: {
        backgroundColor: "#339933",
    },
    container_line_left: {
        backgroundColor: COLORS.bg1_ci,
        width: normalize_style(30),
        justifyContent: 'center', //Centered vertically
        alignItems: 'center', // Centered horizontally
    },
    container_line_mid: {
        backgroundColor: COLORS.bg3_ci,
        flex: 2,
        justifyContent: 'center', //Centered vertically
        alignItems: 'center', // Centered horizontally
    },
    container_line_midclosed: {
        backgroundColor: COLORS.bg3_ci,
        flex: 2,
        justifyContent: 'center', //Centered vertically
        alignItems: 'center', // Centered horizontally
    },

    container_line_mid1: {
        backgroundColor: COLORS.bg3_ci,
        flex: 2,
        justifyContent: 'center', //Centered vertically
        alignItems: 'center', // Centered horizontally
    },
    container_line_mid2: {
        backgroundColor: COLORS.bg3_ci,
        flex: 1,
        justifyContent: 'center', //Centered vertically
        alignItems: 'flex-end', // Centered horizontally
        textAlign: 'right',
    },
    container_line_right: {
        padding: normalize_style(2),
        backgroundColor: COLORS.bg1_ci,
        width: normalize_style(22),
        justifyContent: 'center', //Centered vertically
        alignItems: 'center', // Centered horizontally
    },
    command: {
        fontSize: normalize_style(10),
        paddingLeft: 9,
        justifyContent: 'center', //Centered vertically
        color: '#ffffff',
        textAlign: 'left',
        width: "100%",
    },

    commandright: {
        fontSize: normalize_style(10),
        paddingRight: 8,
        justifyContent: 'center', //Centered vertically
        color: '#ffffff',
        textAlign: 'right',
        width: "100%",
    },
    container_closed_right: {
        borderWidth: normalize_style(1.8),
        borderColor: COLORS.bg6_ci,
    },

    icon_left: {
    },
    image_ci_icon: {
    },
    image_ci_icon2: {
        width: normalize_style(20),
        height: normalize_style(10),
    },
    image_asbo_icon: {
        width: normalize_style(12),
        height: normalize_style(15),
    },

    image_ham_icon: {
        paddingTop: normalize_style(0),
        paddingRight: normalize_style(0),
    },
    image_ham_icon_sub1: {
        paddingTop: normalize_style(6),
        paddingRight: normalize_style(14),
    },
    image_ham_icon_sub2: {
        paddingTop: normalize_style(0),
        paddingRight: normalize_style(8),
    },
    
});

export default styles_submenu;
