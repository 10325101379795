import React, { useEffect, useState } from 'react';

import { Text, View } from '../components/Themed';

import { Platform, FlatList, TouchableOpacity, TouchableHighlight, Linking } from 'react-native';

import styles_photos from '../assets/styles/styles_photos';
import styles_music from '../assets/styles/styles_music';
import SubFlatListSwapMusic from './SubFlatListSwapMusic';
import { checkYearExists, checkYearExistsMusic } from './Common.tsx';
import Icon from 'react-native-ionicons';
import { log, logerror } from './Common';

export default function SubMusicsCountViev({ parentToChild }) {
    const [groupsel, setGroupsel] = useState("");
    const [musicsgroup, setMusicsgroup] = useState([]);
    const [musics, setMusics] = useState([]);
    let [isLoadingmusicsgroup, setLoadingmusicsgroup] = useState(true);
    let [isLoadingmusicgroup, setLoadingmusicgroup] = useState(true);
    const [isOnline, setIsOnline] = useState(null);

    function onPressLower() {
        global.yearselected = parseInt(global.yearselected) - 1;
        checkYearExistsMusic(-1);
        global.yearselectedmusicsub = global.yearselected;
        getInitialData();
    }

    function onPressMore() {
        global.yearselected = parseInt(global.yearselected) + 1;
        checkYearExistsMusic(1);
        global.yearselectedmusicsub = global.yearselected;
        getInitialData();
    }

    function getlinkMusic(item: []) {
        let href = global.docker + 'cimusics/' + item.vdir + "/" + item.link_href;
        log('getlinkMusic ' + href);
        return href;
    }

    function getlinkPhoto(item: []) {
        let href = global.docker + 'cimusics/' + item.vdir + "/" + item.photo;
        // log('getlinkPhoto ' + href);
        return href;
    }

    function handle_setMusicsGroups(response: JSON) {
        // log('handle_setMusicsGroups ' + JSON.stringify(response));
        setMusics([]);
        setMusicsgroup(response);
        if (response.length > 0) {
            //  log('response ' + JSON.stringify(response[0]));
            let selected = response[0];
            selectedId(selected.kgroup);
        } else {
            selectedId("");
        }
    }

    function openmusicurl(item: []) {
        let href = getlinkMusic(item);
        Linking.canOpenURL(href).then(supported => {
            if (supported) {
                if (Platform.OS == 'web') {
                    window.open(href, '_blank');
                } else {
                    Linking.openURL(href);
                }
            }
        });
    }

    function selectedId(musicgroupsel: string) {
        log('selectedId ' + musicgroupsel);
        setGroupsel(musicgroupsel);
        setMusics([]);
        log('selectedId getMusicsGroup ' + musicgroupsel);
        getMusicsGroup(musicgroupsel);
        setLoadingmusicsgroup(false);
        setLoadingmusicgroup(false);
        log('selectedId closed ' + musicgroupsel);
    }

    async function getMusicsGroups(yearsel: string) {
        log('getMusicsGroups ' + yearsel);
        fetch(global.docker + 'music/listgroups?year=' + yearsel, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((json) => handle_setMusicsGroups(json))
            .catch((error) => logerror("SubMusicsCountViev getPhotosCount", error))
            .finally(() => setLoadingmusicsgroup(false));
    }

    async function getMusicsGroup(groupsel: string) {
        log('--------- getMusicGroup ' + groupsel);
        const hlink = global.docker + 'music/getmusicsfromgroup?year=' +
            global.yearselected + "&kgroup=" + groupsel;
        log('getMusicGroup ' + encodeURI(hlink));
        fetch(encodeURI(hlink), {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((json) => {
                if (json.length > 0) {
                    // log('getMusicsGroup > 0' + JSON.stringify(json));
                    setMusics(json);

                }
            }
            )
            .catch((error) => logerror("SubMusicsCountViev getPhotosCount", error))
            .finally(() => setLoadingmusicgroup(false));
    }

    const getInitialData = async () => {
        log('getInitialData ' + global.yearselected);
        setLoadingmusicsgroup(true);
        setLoadingmusicgroup(true);
        setMusics([]);

        getMusicsGroups(global.yearselected);
    }

    if (global.yearselected != global.yearselectedmusicsub) {
        global.yearselectedmusicsub = global.yearselected;
        getInitialData();
    }

    return (
        <View style={styles_photos.container}>
            <View style={styles_photos.container_main_hor}>
                <View style={styles_photos.container_years}>
                    <TouchableHighlight
                        onPress={() => onPressLower()}>
                        <Text style={styles_photos.toplinetext}>{'<<'}</Text>
                    </TouchableHighlight>
                    <Text style={styles_photos.toplinetext}>{global.yearselected}</Text>
                    <TouchableHighlight
                        onPress={() => onPressMore()}>
                        <Text style={styles_photos.toplinetext}>{'>>'}</Text>
                    </TouchableHighlight>
                </View>
            </View>
            {isLoadingmusicgroup ?
                <Text style={styles_music.button_zone_text}>
                    Loading ...
                </Text>
                :
                <View style={{ flex: 1 }}>
                    <SubFlatListSwapMusic
                        musics={musics}
                        groupsel={groupsel}
                        yearsel={global.yearselected}
                    />
                </View >
            }
        </View >
    )
}

