import React, { useEffect, useState } from 'react';
import { StatusBar, Dimensions, Button, ScrollView } from 'react-native';


import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';

import styles_common from '../assets/styles/styles_common';

import SubMusicsCountViev from '../screens/SubMusicsCountViev';
import { SafeAreaView } from 'react-native-safe-area-context';
import { log } from './Common';

export default function MusicScreen({ route, navigation }: RootTabScreenProps<'Music'>) {
    var vspec = "";
    try {
        log("route " + route)
        log("route.params " + JSON.stringify(route.params))
        log("route.params.spec " + route.params.year)
        vspec = route.params.year;
    } catch (e) {
        log('route.params no param')
    }

    if (vspec) { global.yearselected = vspec; }

    if (!global.yearselected) { global.yearselected = "1988"; }

    if (global.yearselected == "Affiches") {
        global.yearselected = "1988";
    }

    return (
        <SafeAreaView style={styles_common.container_fl1_bg1}>
            <SubMusicsCountViev />
        </SafeAreaView>
    );
}

