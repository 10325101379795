import React, { useEffect } from 'react';
import { getStyle, log, logerror } from './Common';

import { SafeAreaView } from 'react-native-safe-area-context';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import { TextInput, View, Text, FlatList, TouchableHighlight, Image, Linking, Platform } from 'react-native';
import styles_screens from '../assets/styles/styles_screens'
import SubFlatListSwapMusicSelected from './SubFlatListSwapMusicSelected';
import { getlink, getlink_thumb, getlink_item } from './Common';
import { AntDesign } from '@expo/vector-icons';
import { normalize_style } from './Common';
import styles_submenu from '../assets/styles/styles_submenu';

var lineid = true;

export default class LastScreen extends React.Component {
    constructor(props) {
        log('LastScreen constructor  ');
        super(props);
        this.state = {
            searchresults: [],
            search: "",
        }
        // this.props.navigation.navigate('Photo')
    }

    navigateDomain = () => {
        this.props.navigation.navigate("music");
    };

    componentDidMount() {
        log('LastScreen componentDidUpdate  ');
        this.getLastEntries();
    }

    componentDidUpdate() {
        log('LastScreen componentDidUpdate  ');
        //  this.stopVideo();
    }

    getLastEntries = () => {
        log('LastScreen getLastEntries  ');
        this.setState({ isSearching: true });
        var hlink = global.docker + 'search/lastentries';
        fetch(encodeURI(hlink))
            .then((response) => response.json())
            .then((json) => {
                log('searchFunction ' + json.length);
                this.setState({ searchresults: json });
            }
            )
            .catch((error) => logerror("LastScreen getLastEntries error", error))
            .finally(() => this.setState({ isSearching: false }));
    };

    openurl(item: JSON) {
        log("***** openurl " + JSON.stringify(item));
        var hlink = item.hlink;
        var domain = item.domain;
        var domain_sub = item.domain_sub;
        var id_domain = item.id_domain;
        var year = item.year;

        if (domain == "music") {
            this.props.navigation.navigate("Music",
                {
                    year: year,
                });
        }

        if (domain == "photo") {
            this.props.navigation.navigate("Photo",
                {
                    year: year,
                    domain_sub: domain_sub,
                });
        }

        if (domain == "video") {
            this.props.navigation.navigate("Video",
                {
                    year: year,
                    domain_sub: domain_sub,
                });
        }

        if (domain == "tag") {
            this.props.navigation.navigate("Search",
                {
                    searchtxt: hlink,
                });
        }

        if (domain == "external") {
            Linking.canOpenURL(hlink).then(supported => {
                if (supported) {
                    if (Platform.OS == 'web') {
                        window.open(hlink, '_blank');
                    } else {
                        Linking.openURL(hlink);
                    }
                    1
                } else {
                    log("Don't know how to open URI: " + hlink);
                }
            });
        }
        if (domain == "document") {
            Linking.canOpenURL(hlink).then(supported => {
                if (supported) {
                    if (Platform.OS == 'web') {
                        window.open(hlink, '_blank');
                    } else {
                        Linking.openURL(hlink);
                    }
                    1
                } else {
                    log("Don't know how to open URI: " + hlink);
                }
            });
        }

    }


    render() {


        const renderItem = (itemv) => {
            // log('SubFlatListPagination renderItem ');
            // log('item ' + JSON.stringify(item));
            var item = itemv.item;
            // log('item  ' + JSON.stringify(item));
            var f1 = item.id;
            var f2 = item.year;
            var f3 = item.title;
            var f4 = item.hlink;

            return (
                < View style={[styles_screens.container]} >
                    <TouchableHighlight
                        onPress={() => { this.openurl(item); }}
                    >
                        <View style={styles_screens.container_col}>
                            <View style={styles_screens.tableRow_doc}>
                                <View style={styles_screens.tableColumnRegular1}>
                                    <Text style={styles_screens.mtext_docs}>
                                        {f2}
                                    </Text>
                                </View>
                                <View style={styles_screens.tableColumnRegular3}>
                                    <Text style={styles_screens.mtext_docs}>
                                        {f3}
                                    </Text>
                                </View>
                                <View style={styles_screens.tableColumnRegular1}>
                                    <AntDesign name="rightcircleo" size={24} color={"#ffffff"} style={styles_screens.image_action} />
                                </View>
                            </View>
                        </View>
                    </TouchableHighlight>
                </View >

            )
        }
        return (
            <View style={styles_screens.container}>
                {this.state.isSearching ?
                    null
                    :
                    <View style={styles_screens.container}>
                        <FlatList
                            data={this.state.searchresults}
                            renderItem={item => renderItem(item)}
                        />
                    </View>
                }
            </View>
        );
    }
}
