import React, { useEffect } from 'react';
import { getStyle, log, logerror } from './Common';

import { SafeAreaView } from 'react-native-safe-area-context';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import { TextInput, View, Text, FlatList, TouchableHighlight, Image, Linking, Platform } from 'react-native';
import styles_screens from '../assets/styles/styles_screens'
import SubFlatListSwapMusicSelected from './SubFlatListSwapMusicSelected';
import { getlink, getlink_thumb, getlink_item } from './Common';
import { AntDesign } from '@expo/vector-icons';
import { normalize_style } from './Common';
import styles_submenu from '../assets/styles/styles_submenu';

var lineid = true;

export default class DocumentScreen extends React.Component {
    constructor(props) {
        log('DocumentScreen constructor  ');
        super(props);
        this.state = {
            searchresults: [],
            search: "",
            videourl: ""
        }

    }

    componentDidMount() {
        log('DocumentScreen componentDidUpdate  ');
        //  this.stopVideo();
        this.getAllDocuments();
        lineid = true;
    }

    componentDidUpdate() {
        log('DocumentScreen componentDidUpdate  ');
        //  this.stopVideo();
    }


    getAllDocuments = () => {
        log('DocumentScreen getAllDocuments  ');
        this.setState({ isSearching: true });
        var hlink = global.docker + 'search/alldocuments';
        fetch(encodeURI(hlink))
            .then((response) => response.json())
            .then((json) => {
                log('searchFunction ' + json.length);
                this.setState({ searchresults: json });
            }
            )
            .catch((error) => logerror("SearchScreen searchFunction error", error))
            .finally(() => this.setState({ isSearching: false }));
    };


    render() {
        log("***** SearchScreen render ");

        function openurl(imageURL: string) {
            Linking.canOpenURL(imageURL).then(supported => {
                if (supported) {
                    if (Platform.OS == 'web') {
                        window.open(imageURL, '_blank');
                    } else {
                        Linking.openURL(imageURL);
                    }
                    1
                } else {
                    log("Don't know how to open URI: " + href);
                }
            });
        }


        function renderItem(itemv) {
            // log('SubFlatListPagination renderItem ');
            // log('item ' + JSON.stringify(item));
            var item = itemv.item;
            // log('item  ' + JSON.stringify(item));
            var f1 = item.id;
            var f2 = item.year;
            var f3 = item.title;
            var f4 = item.hlink;

            return (
                < View style={[styles_screens.container]} >
                    <TouchableHighlight
                        onPress={() => openurl(item.hlink)}
                    >
                        <View style={styles_screens.tableRow_doc}>
                            <View style={styles_screens.tableColumnRegular1}>
                                <Text style={styles_screens.mtext_docs}>
                                    {f2}
                                </Text>
                            </View>
                            <View style={styles_screens.tableColumnRegular3}>
                                <Text style={styles_screens.mtext_docs}>
                                    {f3}
                                </Text>
                            </View>
                            <View style={styles_screens.tableColumnRegular1}>
                                <AntDesign name="rightcircleo" size={24} color={"#ffffff"} style={styles_screens.image_action} />
                            </View>
                        </View>
                    </TouchableHighlight>
                </View >

            )
        }
        return (
            <View style={styles_screens.container}>
                {this.state.isSearching ?
                    null
                    :
                    <View style={styles_screens.container}>
                        <FlatList
                            data={this.state.searchresults}
                            renderItem={item => renderItem(item)}
                        />
                    </View>
                }
            </View>
        );
    }
}
