import React, { useEffect } from 'react';
import { log, logerror } from './Common';

import { SafeAreaView } from 'react-native-safe-area-context';

import SubVideosCountViev from '../screens/SubVideosCountViev';
import styles_common from '../assets/styles/styles_common';

export default class ASBOScreen extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        log("***** ASBOScreen render ");
        return (
            <SafeAreaView style={styles_common.container_fl1_bg1}>
                <SubVideosCountViev
                    ac={false}
                    asbo={true}
                    best={false}
                />
            </SafeAreaView>
        );
    }
}

