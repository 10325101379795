import React, { useEffect, useState } from 'react';
import { StatusBar, Dimensions } from 'react-native';


import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';

import styles from '../assets/styles/styles_main';

import SubVideosCountViev from '../screens/SubVideosCountViev';
import { useIsFocused } from "@react-navigation/native";

import { SafeAreaView } from 'react-native-safe-area-context';
import { log } from './Common';

import { useNavigation } from '@react-navigation/native';

export default function VideoScreen({ route, navigation }: RootTabScreenProps<'Video'>) {
    const isFocused = useIsFocused();

    global.videosupdaterequested = true;
    global.best = false;
    global.asbo = false;
    global.ac = false;

    var vspec = "";
    try {
        log("route " + route)
        log("route.params " + JSON.stringify(route.params))
        log("route.params.spec " + route.params.year)
        vspec = route.params.year;
    } catch (e) {
        log('route.params no param')
    }

    if (vspec) { global.yearselected = vspec; }


    useEffect(() => {
        log("***** PhotoScreen useEffect ");
        if (global.yearselected == "Affiches") {
            global.yearselected = "1988";
        }
    }, [])


    return (
        <SafeAreaView style={styles.container_main}>
            <SubVideosCountViev
                best={false}
                ac={false}
                asbo={false}
            />
        </SafeAreaView>
    );
}

