import {
    StyleSheet,
} from 'react-native';


import { Dimensions } from 'react-native';
import { normalize } from 'react-native-elements';

const { width, height } = Dimensions.get('window');

import { log } from '../../screens/Common';

export function getProportion(valw: Number) {
    var retval = normalize(valw);
    if (width < 450) {
        retval = normalize(valw * 1.1);
        log("getProportion " + retval);
    }
    if (width < 500) {
        retval = normalize(valw * 0.9);
    }
    if (width > 500) {
        retval = normalize(valw * 1.0);
    }
    if (width > 700) {
        retval = normalize(valw * 1.0);
    }
    if (width > 800) {
        retval = normalize(valw * 1.0);
    }
    if (width > 1800) {
        retval = normalize(valw * 1.35);
    }
    if (width > 2400) {
        retval = normalize(valw * 1.6);
    }
    return retval;
}

export function getFlexPhotoVideos() {
    var retv = 2
    if (width < 400) {
        retv = 2
    }
    if ((width >= 400) && (width < 500) && (height > 800)) {
        retv = 2
    }
    if ((width >= 750) && (width < 800) && (height > 800) && (height < 900)) {
        retv = 3
    }
    if ((width >= 750) && (width < 800) && (height > 1000) && (height < 1200)) {
        retv = 3
    }
   log("getFlexPhotoVideos " + retv);
    return retv
}

export function getFlexBottomAffiche() {
    var retv = 2

    if (width < 400) {
        retv = 1
    }
    if ((width >= 400) && (width < 500) && (height > 800)) {
        retv = 3
    }
    if ((width >= 750) && (width < 800) && (height > 800) && (height < 900)) {
        retv = 1
    }
    if ((width >= 750) && (width < 800) && (height > 1000) && (height < 1200)) {
        retv = 1
    }
    log("getFlexBottomAffiche " + retv); 
    return retv
}

export function getLineTextPadding() {
    var retv = 18

    if (width < 400) {
        retv = 4
    }
    if ((width >= 400) && (width < 800) ) {
        retv = 6
    }
    log("getLineTextPadding " + retv); 
    return retv
}
