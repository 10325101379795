import React, { useCallback, memo, useRef, useState } from "react";

import { StyleSheet, Text, View, Dimensions, WebView } from 'react-native';
import { SafeAreaView, FlatList, Modal, Image, TouchableHighlight } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import styles_swap from '../assets/styles/styles_swap';
import styles_screens from '../assets/styles/styles_screens';

import { getlink } from './Common.tsx';
import { getlink_thumb, getlink_item } from "./Common";
import ci from '../assets/images/ci.png';
import { Audio, AVPlaybackStatus } from 'expo-av';
import { Ionicons } from '@expo/vector-icons';
import { Button } from "react-native-elements/dist/buttons/Button";

import SubFlatListSwapMusicSelected from './SubFlatListSwapMusicSelected';
import { log, error } from './Common';

let { width, height } = Dimensions.get('window');

export default class SubFlatListSwapMusic extends React.Component {

  constructor(props) {
    log('SubFlatListSwapMusic constructors  ');
    super(props);

    this.state = {
      loading: false,
      selItem: [],
      index: 0,
      musics: [],
      videosacive: false,
      selmusic: [],
    }
    this.state.videosacive = (global.enablevideo == "1");
    this.state.musics = this.props.musics;
    // log('SubFlatListSwapMusic  ' + JSON.stringify(this.props.musics));
    this.yearsel = this.props.yearsel;
    // log('yearsel  ' + this.yearsel);
    //  log('SubFlatListSwap constructors  ' + this.state.imageSrc);
  }

  // This method will execute everytime a state or props change.
  componentDidUpdate(prevProps) {
    log('SubFlatListSwapMusic componentDidUpdate  ');
    // log('SubFlatListSwap componentDidUpdate prevProps ' + JSON.stringify(prevProps.musics));
    // log('SubFlatListSwap componentDidUpdate musics ' + JSON.stringify(this.props.musics));
    if ((this.props.musics != prevProps.musics) && (this.props.musics.length > 0)) {
      this.setState({ musics: this.props.musics })
      this.groupsel = this.props.groupsel;
      this.yearsel = this.props.yearsel;
      this.setState({ index: 0 })
    }
  }

  componentDidMount() {
    log('SubFlatListSwapMusic  componentDidMount');
  }

  componentWillUnmount() {
    log('SubFlatListSwapMusic  componentWillUnmount');
  }


  selectItem(item) {
    log('selectItem ');
    //  log('item ' + JSON.stringify(item));
    this.setState({ selmusic: item })
  }

  render() {

    const renderItem = (item) => {
      // log('SubFlatListSwapMusic renderItem ');
      return (
        <TouchableHighlight
          onPress={() => this.selectItem(item.item)}
        >
          {(width  < 600) ?
            <View style={styles_screens.container_col}>
              <View style={styles_screens.tableRow}>
                <View style={styles_screens.tableColumnRegular4}>
                  <Text style={styles_screens.mtext1}>
                    {item.item.title.substring(0, 30)}
                  </Text>
                </View>
              </View>
              <View style={styles_screens.tableRow}>
                <View style={styles_screens.tableColumnRegular4}>
                  <Text style={styles_screens.mtext1}>
                    {item.item.orititle.substring(0, 30)}
                  </Text>
                </View>
                <View style={styles_screens.tableColumnRegular4}>
                  <Text style={styles_screens.mtext1}>
                    {item.item.orisinger.substring(0, 30)}
                  </Text>
                </View>
              </View>
            </View>
            :
            <View style={styles_screens.container_col}>
              <View style={styles_screens.tableRow}>
                <View style={styles_screens.tableColumnRegular2}>
                  <Text style={styles_screens.mtext1}>
                    {item.item.title.substring(0, 30)}
                  </Text>
                </View>
                <View style={styles_screens.tableColumnRegular2}>
                  <Text style={styles_screens.mtext1}>
                    {item.item.orisinger.substring(0, 30)}
                  </Text>
                </View>
                <View style={styles_screens.tableColumnRegular2}>
                  <Text style={styles_screens.mtext1}>
                    {item.item.orititle.substring(0, 30)}
                  </Text>
                </View>
              </View>
            </View>
          }
        </TouchableHighlight>
      )
    }

    function viewPrevious() {
      indexRef.current = indexRef.current - 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);
    }

    function viewNext() {
      indexRef.current = indexRef.current + 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);
      onScroll;
    }


    return (
      <SafeAreaView style={styles.container_ver}>
        <View style={styles.container_musiclist}  >
          {(this.state.musics.length > 0) ?
            <FlatList
              data={this.state.musics}
              style={styles.carousel}
              renderItem={item => renderItem(item)}
              pagingEnabled
              vertical
              showsHorizontalScrollIndicator={true}
              bounces={false}
            /> :
            <View style={styles.container_minwidth250}  >
              <Text style={styles.titleStyle}>
                Aucune musique cette année ...
              </Text>
            </View>
          }
        </View>
        <View style={styles.container_musicdetail}  >
          {this.state.loading ?
            <View style={styles.container_max}>
              <Text >
                Loading ...
              </Text>
            </View>
            :
            <View style={styles.container_music}>
              <SubFlatListSwapMusicSelected
                music={this.state.selmusic}
                yearsel={global.yearselected}
              />
            </View>
          }
        </View>
      </SafeAreaView>
    )
  }
}

const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

const styles = StyleSheet.create({
  container_ver: {
    backgroundColor: COLORS.bg2_ci,
    flex: 1,
  },
  container: {
    height: '100%',
    flex: 1,
  },
  container_musiclist: {
    flex: 1,
    height: '100%',
    minWidth: 30
  },
  container_musicdetail: {
    flex: 1,
    height: '100%',
    backgroundColor: "#00ff00",
    minWidth: 30
  },
  container_musiclistold: {
    justifyContent: 'center',
    flex: 1,
    flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
    backgroundColor: COLORS.bg1_ci,
    minWidth: 30
  },
  container_musicdetailold: {
    justifyContent: 'center',
    flex: 5,
    backgroundColor: COLORS.bg1_ci,
    minWidth: 30
  },
  carousel: {
    height: '100%',
    flex: 1,
    margin: 3,
  },
  container_music: {
    flex: 1,
    borderTopColor: COLORS.bg6_ci,
    borderTopWidth: 5,
  },
  container_max: {
    flex: 1,
    height: '100%',
    width: '100%',
    alignItems: 'flex-end',
    backgroundColor: "green",
  },

  container_web: {
    marginTop: 20,
    minHeight: 200,
    minWidth: 200,

    backgroundColor: "red",
  },
  container_hor: {
    flex: 1,
    flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
  },
  music: {
    flex: 1,
    minHeight: 200,
    minWidth: 200,
    height: '100%',
    width: '100%',
    backgroundColor: COLORS.bg2_ci,
  },

  titleStyle: {
    padding: 16,
    fontSize: 20,
    color: 'white',
  },
  imageContainerStyle: {
    flex: 1,
    flexDirection: 'column',
    margin: 4,
  },
  imageStyle: {
    height: 200,
    width: '100%',
    resizeMode: 'stretch',
  },
  fullImageStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%',
    width: '95%',
  },
  modalStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.bg2_ci,
  },
  closeButtonStyle: {
    top: 8,
    right: 8,
    position: 'absolute',
  },
  icon: {
    padding: 8,
  },
  firstrow: {
    flex: 1,
    backgroundColor: "#ffc857"
  },
  secondrow: {
    flex: 1,
    backgroundColor: "#4b3f72"
  },
  thirdrow: {
    flex: 1,
    backgroundColor: "#119da4"
  },
  fourthrow: {
    flex: 1,
    backgroundColor: "#19647e"
  }

});
