import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, Image, ScrollView, Platform } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import ci from '../assets/images/ci.png';
import { SafeAreaView } from 'react-native-safe-area-context';

import { getlink_photo_spec } from './Common';

export default function HistoryScreen({ navigation }: RootTabScreenProps<'History'>) {
  const [status, setStatus] = React.useState({});

  function get_link_file(year: string, direct: string, file: string) {
    const link = getlink_photo_spec(year, direct, file);

    return getlink_photo_spec(year, direct, file);
  }

  return (
    <SafeAreaView style={styles_spec.container}>
      <ScrollView>
        <View style={styles_spec.container_row_top}>

          <View style={styles_spec.container_ci}>
            <Image source={ci} style={styles_spec.image_ci_about} />
          </View>

          <View style={styles_spec.container_text_top}>
            <Text style={styles_spec.toplinetext}>
              Cercle Industriel
            </Text>
            <Text style={styles_spec.toplinetext2}>
              Archives 1872 - 2022
            </Text>
          </View>
        </View>

        <View style={{ backgroundColor: 'transparent', height: 25 }}>
        </View>

        <Text style={styles_spec.text}>
          Si on retrace l'histoire du CI, on arrive à sa création le 15 Décembre 1872.
          Les status de 1872 et ceux révisés de 1873 sont disponibles.
          Le CI est donc né le 15 Décembre 1872, créé par des professeurs dans un but "d'instruction intellectuelle et morale" et de "resserrer les liens par des réunions intimes et régulières".
          Pas de doute, ils avaient déjà le sens de l'humour, peut-être sans le savoir.
        </Text>
        <Image source={{ uri: get_link_file("1872", "", "affiche") }} style={styles_spec.image_medium} />
        <Text style={styles_spec.text}>

          Au départ la revue n'est pas un spectacle mais une publication qui rapporte les grands "travaux" du CI (exposés hebdomadaires scientifiques. Les premières traces d'un spectacle dit "revue" datent de 1922 (anniversaires 50 ans du CI). Depuis cette date, traditionnellement aux fêtes de Sante-Barbe et Saint-Eloi, le CI organise une revue. L'après-guerre est un tournant pour le CI; les étudiants prennent le pouvoir. La revue devient un vrai spectacle qui se moque des profs.
        </Text>
        <Text style={styles_spec.text}>

          L'année 62 est un tournant avec un président très entreprenant.; la "revue papier" se divise en 3: « avenir et opinions » très intello, le "Rivet" qui est moyennement sérieux et une feuille satirico-hebdomadaire "Salopinform". Le comité lance son propre bar (45 Avenue des Alliés, Leuven). Le CI (9, Rue Mathieu) compte 650 membres et le SICI existe déjà. Mais les problèmes linguistiques pourrissent l'ambiance de Louvain et le CI s'installe à LLN dans son immeuble actuel au 58 Rue des Wallons. C'est le premier Cercle et qui lui vaut encore d'avoir le plus petit bar de cercle de LLN.
        </Text>
        <View style={styles_spec.container_row}>
          <Image source={{ uri: get_link_file("1929", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1931", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1933", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1957", "", "program/affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1958", "", "program/affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1963", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1964", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1972", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1972", "", "affiche") }} style={styles_spec.image_revue_small} />

        </View>

        <Text style={styles_spec.text}>
          La Revue des Ingénieurs est un spectacle satyrique amateur qui parodie entre autres les professeurs de l’Ecole Polytechnique de Louvain, les cours et la vie universitaire en général. Le spectacle se base sur un thème connu du grand public, différent chaque année, et est entrecoupé de musiques, de danses, ainsi que de petits sketchs comiques. La Revue est organisée dans sa quasi-totalité par des étudiants ingénieurs de l’Université catholique de Louvain et compte chaque année plus de 300 participants. Depuis plus de 20 années consécutives, la Revue se joue sur la prestigieuse scène de l’Aula Magna à Louvain-la-Neuve durant trois soirs qui affichent toujours complets. Le thème de la Revue reste secret depuis le mois de septembre et n'est dévoilé que quelques semaines avant la première représentation par le célèbre vice-revue, membre du comité CI et responsable de l'organisation de cet événément immanquable de notre faculté.
        </Text>
        <Text style={styles_spec.text}>

          La Revue du CI a toujours été un moment fort de l'année estudiantine. On a beaucoup d'archives, elles sont maintenant sur le web. On retrouve des programmes complets de 1922 et quasiment tous depuis 1957. Des vidéos depuis 1984, des affiches depuis 1922 et des photos depuis 1983. Dans ces souvenirs, on a le premier dessin animé, le premier a capella, du Brel, U2, Led Zeppelin, des décors somptueux (1992), des présidents, … et des vannes à deux sous (« la cuisine ça la botte Annick » 1985Vincent Colson).
          Si on retrarce l'histoire de la revue, on arrive à remonter à 1958. Ce site contient tous les liens vers les affiches, les programmes, les vidéos des revues. Les documents ont été déposés sur des sites spésialisés pour faciliter le travail. Pour des raisons de droits, les vidéos publiques (sur Youtube) ne contiennent pas les chansons. Mais, un groupe spécifique a été créé sur Facebook.
        </Text>
        <View style={styles_spec.container_row}>
          <Image source={{ uri: get_link_file("1977", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1980", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1987", "", "program/affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1988", "", "program/affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1990", "", "program/affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1996", "", "program/affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("1999", "", "program/affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("2003", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("2004", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("2010", "", "affiche") }} style={styles_spec.image_revue_small} />
          <Image source={{ uri: get_link_file("2014", "", "affiche") }} style={styles_spec.image_revue_small} />
        </View>

        <Text style={styles_spec.text}>
          v183
        </Text>

      </ScrollView>
    </SafeAreaView>
  );
}

const styles_spec = StyleSheet.create({
  container: {
    paddingTop: 10,
    backgroundColor: COLORS.bg3_ci,
    flex: 1,
  },
  container_row_top: {
    backgroundColor: COLORS.bg3_ci,
    flex: 1,
    width: "100%",
    maxHeight: 120,
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  container_ci: {
    backgroundColor: 'transparent',
    height: '100%',
    width: undefined,
    minWidth: 90,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  container_text_top: {
    backgroundColor: COLORS.bg3_ci,
    flex: 2,
  },

  image_ci_about: {
    backgroundColor: 'transparent',
    flex: 1,
    aspectRatio: 1,
    height: undefined,
    width: '100%',
    resizeMode: 'contain'
  },

  image_medium: {
    margin: 8,
    minWidth: 250,
    minHeight: 600,
    maxWidth: 500,
    maxHeight: 900,
    resizeMode: 'contain'
  },
  image_revue_small: {
    flex: 1,
    margin: 8,
    minWidth: 70,
    minHeight: 130,
    width: 200,
    height: 130,
    resizeMode: 'contain'
  },

  container_row: {
    backgroundColor: COLORS.bg3_ci,
    flexDirection: 'row',
  },
  toplinetext: {
    color: COLORS.blue,
    ...FONTS.h1,
    textAlign: 'center',
  },
  toplinetext2: {
    color: COLORS.blue,
    ...FONTS.h2,
    textAlign: 'center',
  },
  text: {
    color: COLORS.white,
    ...FONTS.h4,
    textAlign: 'left',
    padding: 8,
  },
});

