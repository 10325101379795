import {
    StyleSheet,
} from 'react-native';
import { Dimensions } from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

const { width, height } = Dimensions.get('window');
import {getFlexPhotoVideos,getFlexBottomAffiche} from './styles__functions';

const styles_ori_portrait = StyleSheet.create({
    container_hv1: {
        flex: 1,
        flexDirection: 'column',
    },
    container_hv2: {
        flex: 2,
        flexDirection: 'column',
    },
    container_home_right_landscap_portrait: {
        flexDirection: 'column',
        justifyContent: "space-evenly",
        flexGrow: 1,  // number
    },


    dataphotos: {
        backgroundColor: COLORS.bg1_ci,
        alignContent: 'center',
        flex: 1,
        resizeMode: 'contain',
        margin: 15,
    },



    container_image: {
        flex: 2,
        flexDirection: 'column',
    },

    container_main_year: {
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        backgroundColor: "#000022",
        height: 30,
    },

    container_content_top: {
        backgroundColor: COLORS.bg1_ci,
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        flex: 1,
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        flexBasis: 1,
        flexGrow: 1,  // number
        flexShrink: 1,  // number
        flexWrap: "wrap",  // enum('wrap', 'nowrap', 'wrap-reverse'),
        width: '100%',
        padding: 5,
    },

    dataaffiche: {
        backgroundColor: COLORS.bg1_ci,
        alignContent: 'flex-end',

        flex: 1,
    },
    datavideos: {
        backgroundColor: COLORS.bg1_ci,
        alignContent: 'center',
        flex: 1,
        resizeMode: 'stretch',
        margin: 5,
    },


    data_text: {
        position: 'absolute',
        ...FONTS.h2,
        bottom: 3,
        color: '#ffffff',
        textAlign: 'center',
        alignContent: 'flex-end',
        backgroundColor: COLORS.bg1_ci,
        width: '100%',
        height: 40,
        opacity: 0.7,
    },

    image: {
        justifyContent: 'flex-start',
        borderTopRightRadius: 20,
        flex: 2,
        width: null,
        height: null,
        maxHeight: '90%',
        resizeMode: 'contain'
    },

    affiche: {
        backgroundColor: COLORS.red,
        top: 5,
        left: 5,
        flex: 1,
        justifyContent: "center",
        resizeMode: 'contain',
        display: 'flex',
        padding: 20
    },
    submenu: {
        position: 'absolute',
        ...FONTS.h2,
        bottom: 3,
        color: '#ffffff',
        textAlign: 'center',
        alignContent: 'flex-end',
        backgroundColor: COLORS.bg1_ci,
        width: '100%',
        height: 40,
        opacity: 0.7,

    },

});

export default styles_ori_portrait;
