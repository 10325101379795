import {
  StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';
import { normalize_style } from '../constants/theme';
import { getProportion } from './styles__functions';

const styles_subflatlistbrowser = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: COLORS.bg0_ci,
    borderWidth: 0,
    borderColor: COLORS.bg2_ci,
  },
  container_subtags: {
    width: '100%',
    backgroundColor: COLORS.bg2_ci,
  },
  titleStyle: {
    padding: 16,
    fontSize: 20,
    color: 'white',
    backgroundColor: 'green',
  },
  text: {
    padding: 16,
    fontSize: 28,
    color: 'white',
  },
  text_small: {
    bottom: 88,
    right: 88,
    position: 'absolute',
    fontSize: 12,
    color: 'white',
  },
  imageContainerStyle: {
    flex: 1,
    flexDirection: 'column',
    margin: 4,
  },
  imageStyle: {
    height: getProportion(200),
    width: '100%',
  },
  fullImageStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50,
    minWidth: 100,
    height: '90%',
    width: '95%',
  },
  modalStyle: {
    flex: 1,
    height: '100%',
    width: '100%',
    minWidth: 200,
    minHeight: 200,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.bg2_ci,
  },
  closeButtonStyle: {
    bottom: 8,
    left: 8,
    position: 'absolute',
  },
  webButtonStyle: {
    bottom: 8,
    left: 88,
    position: 'absolute',
  },
  prevButtonStyle: {
    bottom: 8,
    right: 88,
    position: 'absolute',
  },
  nextButtonStyle: {
    bottom: 8,
    right: 8,
    position: 'absolute',
  },
  transparentLeft: {
    backgroundColor: "#66ff00",
    opacity: 0.0,
    bottom: 80,
    left: 8,
    width: "20%",
    height: "60%",
    position: 'absolute',
  },
  transparentMid: {
    backgroundColor: "#66ff00",
    opacity: 0.0,
    bottom: 80,
    left: "40%",
    width: "20%",
    height: "60%",
    position: 'absolute',
  },
  transparentRight: {
    backgroundColor: "#66ff00",
    opacity: 0.0,
    bottom: 80,
    right: 8,
    width: "20%",
    height: "60%",
    position: 'absolute',
  },

  icon: {
    padding: 8,
  },

});


export default styles_subflatlistbrowser;
