import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width

const fontfamilymain = "Roboto-Bold";

const styles_music = StyleSheet.create({
    container_hor: {
        flex: 1,
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        backgroundColor: COLORS.bg2_ci,
    },
    container_ver: {
        flex: 1,
        flexDirection: "column",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        backgroundColor: COLORS.bg2_ci,
    },
    container_music_top: {
        flex: 1,
    },

    tableColumnRegular1: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: COLORS.bg2_ci,
        alignItems: "baseline",
        padding: 2,
        minHeight: 40,
    },
    tableColumnRegular2: {
        flex: 3,
        flexDirection: "column",
        backgroundColor: COLORS.bg2_ci,
        alignItems: "baseline",
        padding: 2,
        minHeight: 40,
    },
    tableContainer: {
        minHeight: 100,
        borderRadius: 5,
        flex: 1,
        marginTop: 0,
        padding: 10
    },
    tableRow: {
        backgroundColor: COLORS.bg2_ci,
        minHeight: 40,
        flex: 2,
        flexDirection: "row",
    },
    mtext1: {
        minHeight: 40,
        ...FONTS.h5,
        margin: 3,
        color: "#ffffff"
    },
    mtext2: {
        minHeight: 40,
        ...FONTS.h5,
        margin: 3,
        color: "#ffffff"
    },
    icon: {
        padding: 4,
        color: 'white',
    },
    button_zone: {
        flex: 1,
        backgroundColor: COLORS.bg4_ci,
        borderColor: COLORS.bg1_ci,
        borderWidth: 2,
        flexDirection: "row",
        justifyContent: "space-between",
        minWidth: 80,
        minHeight: 50,
    },
    button_zone_text: {
        padding: 4,
        ...FONTS.h3,
        color: 'white',
    },
    imageStyle: {
        alignContent: 'flex-start',
        margin: 10,
        minHeight: 200,
        minWidth: 200,
        height: '90%',
        width: '100%',
        resizeMode: "contain"
    },

});

export default styles_music;
