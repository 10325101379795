import React, { useEffect, useState, useRef } from 'react';
import { TouchableHighlight, BackHandler, Animated } from 'react-native';

import { View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import cia from '../assets/images/ci800a.png';
import cib from '../assets/images/ci800b.png';
import cic from '../assets/images/ci800c.png';
import cid from '../assets/images/ci800d.png';
import cie from '../assets/images/ci800e.png';
import { log, logerror } from './Common';

import styles_splash from '../assets/styles/styles_splash';

export default function SplashScreen({ navigation }: RootTabScreenProps<'Splash'>) {
  log('SplashScreen');
  let [timePassed, setTimePassed] = useState(false);

  const boxaAnimationValue = React.useRef(new Animated.Value(0)).current;
  const boxbAnimationValue = React.useRef(new Animated.Value(0)).current;
  const boxcAnimationValue = React.useRef(new Animated.Value(0)).current;

  const boxciAnimationValue = React.useRef(new Animated.Value(0)).current;

  const boxg1AnimationValue = React.useRef(new Animated.Value(0)).current;
  const boxg2AnimationValue = React.useRef(new Animated.Value(0)).current;

  Animated.sequence([
    Animated.delay(400),
    Animated.timing(boxaAnimationValue, {
      toValue: 1,
      duration: 2000,
      useNativeDriver: true,
    }),
  ]).start()

  Animated.sequence([
    Animated.delay(800),
    Animated.timing(boxbAnimationValue, {
      toValue: 1,
      duration: 1600,
      useNativeDriver: true,
    }),
  ]).start()

  Animated.sequence([
    Animated.delay(1200),
    Animated.timing(boxcAnimationValue, {
      toValue: 1,
      duration: 1600,
      useNativeDriver: true,
    }),
  ]).start()

  Animated.sequence([
    Animated.delay(600),
    Animated.timing(boxciAnimationValue, {
      toValue: 1,
      duration: 3600,
      useNativeDriver: true,
    }),
  ]).start()

  Animated.sequence([
    Animated.delay(600),
    Animated.timing(boxciAnimationValue, {
      toValue: 1,
      duration: 3600,
      useNativeDriver: true,
    }),
  ]).start()


  function _onHome() {
    log('------------  HomeScreen _onContact');
    navigation.navigate('Root')
  }


  useEffect(() => {
    log('useEffect');
    // getStats(global.yearselected);

    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => true)
    setTimeout(() => {
      setTimePassed(true);
      log("time passed");
      navigation.navigate('Root')
    }, 6000);
    return () => backHandler.remove()
  }, [])

  return (
    <TouchableHighlight
      onPress={() => _onHome()}
      style={styles_splash.container}
    >
      <View style={styles_splash.container}>
        <View style={styles_splash.container_top}>
          <Animated.Image
            source={cia}
            style={[
              styles_splash.image_ci,
              {
                transform: [
                  {
                    scale: boxaAnimationValue.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                    }),
                  },
                ],
              }]}
          />
          <Animated.Image
            source={cib}
            style={[
              styles_splash.image_ci,
              {
                transform: [
                  {
                    scale: boxbAnimationValue.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                    }),
                  },
                ],
              }]}
          />
          <Animated.Image
            source={cic}
            style={[
              styles_splash.image_ci,
              {
                transform: [
                  {
                    scale: boxcAnimationValue.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                    }),
                  },
                ],
              }]}
          />
          <Animated.Image
            source={cic}
            style={[
              styles_splash.image_ci,
              {
                transform: [
                  {
                    scale: boxcAnimationValue.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                    }),
                  },
                ],
              }]}
          />
        </View>
        <View style={styles_splash.container_mid}>
          <Animated.Image
            source={cid}
            style={[
              styles_splash.image_ci,
              {

                opacity: boxciAnimationValue
              }
            ]}
          />
        </View>
        <View style={styles_splash.container_bottom}>
          <Animated.Image
            source={cie}
            style={[
              styles_splash.image_ci,
              {

                opacity: boxciAnimationValue
              }
            ]}
          />
        </View>
      </View>
    </TouchableHighlight>
  );
}
