import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';
import { normalize_style} from '../constants/theme';

const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width

const fontfamilymain = "Roboto-Bold";

const styles_swap = StyleSheet.create({
    container: {
        flex: 1,
        width : "100%",
        padding: 8,
    },
    slide: {
        flex: 1,
        padding: 5,
        justifyContent: "center",
        alignItems: "center",
        minWidth: 128,
        minHeight: 128,
    },
    slideImage: {
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
        minWidth: 128,
        minHeight: 128,
        width: '100%',
        height: '100%',
        resizeMode: "contain",
    },
    slideImageThumb: {
        justifyContent: "center",
        width: '100%',
        minWidth:  normalize_style(100),
        minHeight:  normalize_style(60),
        alignItems: "center",
    },
    slideImageold: {
        width: width * 1.0, height: height * 0.7,
    },
    slideTitle: { fontSize: 24 },
    slideSubtitle: { fontSize: 18 },

    pagination: {
        position: "absolute",
        bottom: 8,
        width: "100%",
        height: 30,
        flexDirection: "row",
        backgroundColor: "lightblue"
    },
    paginationDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginHorizontal: 2,
    },
    paginationDotActive: { backgroundColor: "red" },
    paginationDotInactive: { backgroundColor: "green" },

    carousel: {
        minWidth:  normalize_style(120),
        flex: 1,
        margin: 3,
    },
    mtext1: {
        ...FONTS.h4,
        margin: 3,
        color : "#ffffff"
    },
    mtext2: {
        ...FONTS.h4,
        margin: 3,
        color : "#ffffff"
    },
    text1: {
        flex: 1,
        margin: 3,
        color : "#ffffff"
    },
    text2: {
        ...FONTS.h4,
        flex: 4,
        margin: 3,
        color : "#ffffff"
    },
    text_video_year: {
        backgroundColor: COLORS.bg1_ci,
        opacity : 0.6,
        position : 'absolute',
        bottom : 5,
        left : 5,
        ...FONTS.h6,
        color : "#ffffff",
        paddingTop : 6,
        paddingLeft : 4,
        paddingRight : 12,
        paddingBottom : 6,
    },
    
});

export default styles_swap;
