import React from 'react';

import { StyleSheet, Text, View, Dimensions } from 'react-native';
import { SafeAreaView, FlatList, Modal, Image, TouchableOpacity, TouchableHighlight, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import { getlink, getlink_thumb, getlink_item } from './Common.tsx';
import { log, error } from './Common';
import SubTagList from './SubTagList';
import styles from '../assets/styles/styles_subflatlistbrowser';


export default class SubFlatListBrowser extends React.Component {
  constructor(props) {
    log('SubFlatListBrowser constructors  ');
    super(props);
    this.state = { testf: false, loading: false, index: 0, imageurl: '', imageref: '', id_photo: 0, modalVisibleStatus: false, photos: [], element: [], id: "" }
    this.index = 0;
    this.state.photos = this.props.photos;
    this.groupsel = this.props.groupsel;
    this.yearsel = this.props.yearsel;
    this.numcols = this.props.numcols;
    log('SubFlatListBrowser constructors this.props.photos  ' + this.props.photos.length + ' ' + this.yearsel + ' groupsel  ' + this.groupsel);
  }

  // This method will execute everytime a state or props change.
  componentDidUpdate(prevProps) {
    log('SubFlatListBrowser componentDidUpdate yearsel:' + this.yearsel + "  groupsel:" + this.groupsel + " numcols  " + this.numcols);
    if (this.props.photos != prevProps.photos) {
      log('SubFlatListBrowser componentDidUpdate  changed ');
      this.setState({ photos: this.props.photos })
      this.setState({ modalVisibleStatus: false });
      this.groupsel = this.props.groupsel;
      this.yearsel = this.props.yearsel;
      this.numcols = this.props.numcols;
      // log('SubFlatListBrowser componentDidUpdate ' + JSON.stringify(this.props.photos));
    }
  }

  get_link_index() {

    // log('get_link_index --------------------------------------');
    // log('this.state.index ' + this.state.index);
    // log('this.state.photos.length ' + this.state.photos.length);
    log('get_link_index ' + this.state.index);
    if (this.state.photos.length > 0) {
      //   get_link_index
      const item = this.state.photos[this.state.index];
      log('get_link_index ' + JSON.stringify(item));
      this.setState({ id_photo: item.id });
      log('SubFlatListBrowser this.state.id_photo ' + this.state.id_photo);
      return item;
    } else {
      return this.state.photos[0];
    }
  }

  onPressPrev() {
    // log('onPressPrev ' + this.state.index);
    this.state.index = this.state.index - 1;
    if (this.state.index < 0) {
      this.state.index = 0;
    }
    const item = this.get_link_index();
    this.showModalFunction(true, getlink_item(this.yearsel, item))
  }

  onPressNext() {
    // log('onPressNext ' + this.state.index);
    this.state.index = this.state.index + 1;
    if (this.state.index == this.state.photos.length) {
      this.state.index = this.state.photos.length - 1;
    }
    const item = this.get_link_index();
    // log('item ' + JSON.stringify(item));
    this.showModalFunction(true, getlink_item(this.yearsel, item))
  }


  showModalFunction(visible, imageURL) {
    this.setState({ modalVisibleStatus: visible });
    this.setState({ imageurl: imageURL });
  }

  renderItemComponent(item) {

    // log("this.state.photos " + this.state.photos);
    return (
      <View style={styles.imageContainerStyle}>
        <TouchableOpacity
          key={item.id}
          style={{ flex: 1 }}
          onPress={() => {
            var i;
            //  log('item.id  ' + item.item.id);
            for (i = 0; i < this.state.photos.length; i++) {
              // log('this.state.photos  ' + this.state.photos[i].id);
              if (item.item.id == this.state.photos[i].id) {
                this.state.index = i;
                this.setState({ id_photo: this.state.photos[i].id });
                log('renderItemComponent this.state.id_photo ' + this.state.id_photo);
              }
            }
            log('renderItemComponent ' + this.state.index);
            // log('renderItemComponent ' + JSON.stringify(item));
            this.showModalFunction(true, getlink_item(this.yearsel, item.item));
          }}>
          <Image
            style={styles.imageStyle}
            source={{ uri: getlink_thumb(this.yearsel, item) }}
            resizeMode="contain"
          />
        </TouchableOpacity>
        {this.state.testf ?
          <Text style={styles.text_small}>
            {JSON.stringify(item.item.direct)}
            {JSON.stringify(item.item.id)}
            {JSON.stringify(item.item.link_href)}
          </Text>
          :
          null
        }
      </View>
    );
  }

  openphotourl(imageURL) {
    Linking.canOpenURL(imageURL).then(supported => {
      if (supported) {
        Linking.openURL(imageURL);
      } else {
        log("Don't know how to open URI: " + href);
      }
    });
  }


  render() {
    function viewPrevious() {
      indexRef.current = indexRef.current - 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);
    }

    function viewNext() {
      indexRef.current = indexRef.current + 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);

      // onScroll;
    }

    return (
      <SafeAreaView style={styles.container}>
        {(this.state.photos.length == 0) ?
          <Text style={styles.text}>Pas de photos</Text>
          :
          null
        }
        {this.state.modalVisibleStatus ? (
          <SafeAreaView style={styles.container}>
            <View
              style={styles.container}>
              <SubTagList
                type={"photo"}
                id={this.state.id_photo}
                style={styles.container_subtags}
              />
              <View style={styles.modalStyle}>
                <Image
                  style={styles.fullImageStyle}
                  source={{ uri: this.state.imageurl }}
                  resizeMode="contain"
                />
                <TouchableOpacity
                  activeOpacity={0.5}
                  style={styles.closeButtonStyle}
                  onPress={() => {
                    this.showModalFunction(false, '');
                  }}>
                  <AntDesign name="closesquareo" size={36} color="red" style={styles.icon} />
                </TouchableOpacity>
                <TouchableOpacity
                  activeOpacity={0.5}
                  style={styles.webButtonStyle}
                  onPress={() => {
                    this.openphotourl(this.state.imageurl);
                  }}>
                  <AntDesign name="download" size={36} color="green" style={styles.icon} />
                </TouchableOpacity>
                <TouchableOpacity
                  activeOpacity={0.5}
                  style={styles.prevButtonStyle}
                  onPress={() => {
                    this.onPressPrev();
                  }}>
                  <AntDesign name="caretleft" size={36} color="green" style={styles.icon} />
                </TouchableOpacity>
                <TouchableOpacity
                  activeOpacity={0.5}
                  style={styles.nextButtonStyle}
                  onPress={() => {
                    this.onPressNext();
                  }}>
                  <AntDesign name="caretright" size={36} color="green" style={styles.icon} />
                </TouchableOpacity>
                {this.state.testf ?!
                  <Text style={styles.text_small}>{this.state.imageurl}</Text>
                  :
                  null
                }
              </View>
              <TouchableOpacity
                style={styles.transparentLeft}
                onPress={() => {
                  this.onPressPrev();
                }}>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.transparentMid}
                onPress={() => {
                  this.openphotourl(this.state.imageurl);
                }}>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.transparentRight}
                onPress={() => {
                  this.onPressNext();
                }}>

              </TouchableOpacity>
            </View>
          </SafeAreaView>
        ) : (
          <View style={styles.container} >
            <FlatList
              data={this.state.photos}
              renderItem={item => this.renderItemComponent(item)}
              keyExtractor={item => item.id.toString()}
              refreshing={this.state.loading}
              numColumns={this.numcols}
            />
          </View>
        )}
      </SafeAreaView>
    )
  }
}
