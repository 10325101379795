import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

function getDirection() {

    if (width > height) {
        return 'row';
    } else {
        return 'column';
    }

}

const styles_screens = StyleSheet.create({
    container: {
        backgroundColor: COLORS.bg0_ci,
        flex: 1,
    },
    container_border: {
        backgroundColor: COLORS.bg0_ci,
        flex: 1,
        borderBottomColor: COLORS.bg6_ci,
        borderBottomWidth:2,
    },

    container_row: {
        flexDirection: "row",
        flex: 1,
    },
    container_row_search: {
        flexDirection: "row",
       height: 100,
    },
    flatList_search: {
        flex: 1,
    },
    container_found: {
        justifyContent: 'center', //Centered vertically
        paddingLeft : 15,
        flex: 1,
    },
   
    container_col_line: {
        flexDirection: "column",
        flex: 1,
        borderTopColor: COLORS.bg6_ci,
        borderTopWidth: 2
    },

    container_col: {
        flexDirection: "column",
        flex: 1,
    },

    container_dir: {
        backgroundColor: COLORS.bg0_ci,
        flexDirection: getDirection(),
        flex: 1,
    },

    container_search: {
        flex: 1,
        ...FONTS.h2,
        color: "#ffffff",
        height: 50,
        padding: 10,
    },

    container_search_flatlist: {
        flex: 1,
        borderBottomWidth: 4,
        borderBottomColor: COLORS.bg5_ci,
    },


    titleStyle: {
        fontSize: 28,
        color: 'white',
    },
    line_bg1: {
        backgroundColor: COLORS.bg2_ci,
        flex: 2,
        flexDirection: "row",
    },
    line_bg2: {
        backgroundColor: COLORS.bg0_ci,
        flex: 2,
        flexDirection: "row",
    },
    tableRow: {
        flex: 2,
        flexDirection: "row",
    },
    tableRow_doc: {
        minHeight: 55,
        flex: 1,
        flexDirection: "row",
        borderBottomWidth: 2,
        borderBottomColor: COLORS.bg5_ci,
   },
    search: {
        backgroundColor: COLORS.bg0_ci,
        ...FONTS.h3,
        margin: 13,
        color: "#ffffff",
        height: 60,
        borderWidth: 1,
        padding: 10,
        borderColor: "#ffffff",
    },
    mtexts: {
        ...FONTS.h7,
        margin: 3,
        color: "#ffffff"
    },
    mtext1: {
        ...FONTS.h6,
        margin: 3,
        color: "#ffffff"
    },
    mtext2: {
        ...FONTS.h6,
        margin: 3,
        color: "#ffffff" 
    },
    mtext_docs: {
        ...FONTS.h5,
        height:"100%",
        marginTop: 6,
        color: "#ffffff"
    },
    tableColumnRegular0: {
        width: "10%",
        flexDirection: "column",
        alignItems: "baseline",
        padding: 2,
    },
    tableColumnRegular1: {
        width: "20%",
        flexDirection: "column",
        alignItems: "baseline",
        padding: 2,
    },
    tableColumnRegular2: {
        width: "35%",
        flexDirection: "column",
        alignItems: "baseline",
        padding: 2,
    },

    tableColumnRegular3: {
        width: "45%",
        flexDirection: "column",
        alignItems: "baseline",
        padding: 2,
    },
    tableColumnRegular4: {
        width: "50%",
        flexDirection: "column",
        alignItems: "baseline",
        padding: 2,
    },
    image_action: {
        height: 30,
        marginTop : 6,
     },
    imageStyle: {
        margin: 5,
        height: '100%',
        width: '100%',
        resizeMode: "contain"
    },
    imageStyleThumb: {
        height: 120,
        width: undefined,
        resizeMode: "contain"
    },
    video: {
        flex: 1,
        minHeight: 200,
        minWidth: 200,
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.bg2_ci,
    },

});

export default styles_screens;

