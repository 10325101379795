import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

const styles_ori_landscape = StyleSheet.create({
    container_hv1: {
        flexDirection: 'row',
    },
    container_hv2: {
        flexDirection: 'column',
    },

    container_image: {
        flex: 2,
        flexDirection: 'column',
    },

    container_content_top: {
        backgroundColor: COLORS.bg1_ci,
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        flex: 1,
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        flexBasis: 1,
        flexGrow: 1,  // number
        flexShrink: 1,  // number
        flexWrap: "wrap",  // enum('wrap', 'nowrap', 'wrap-reverse'),
        width: '100%',
    },

    dataaffiche: {
        backgroundColor: COLORS.bg1_ci,
        alignContent: 'center',
        flex: 1,
    },

    dataphotos: {
        backgroundColor: COLORS.bg1_ci,
        alignContent: 'center',
        flex: 1,
        resizeMode: 'stretch',
        margin: 5,
    },

    datavideos: {
        backgroundColor: COLORS.bg1_ci,
        alignContent: 'center',
        flex: 1,
        resizeMode: 'stretch',
        margin: 5,
    },

    data_text: {
        position: 'absolute',
        ...FONTS.h2,
        bottom: 3,
        color: '#ffffff',
        textAlign: 'center',
        alignContent: 'flex-end',
        backgroundColor: COLORS.bg1_ci,
        width: '100%',
        height: 60,
        opacity: 0.7,
    },

    image: {
        borderTopRightRadius: 20,
        flex: 1,
        width: null,
        height: null,
        resizeMode: 'contain'
    },
    affiche: {
        top: 5,
        left: 5,
        width: "100%",
        height: "100%",
        backgroundColor: COLORS.bg0_ci,
        flex: 1,
        justifyContent: "center",
        resizeMode: 'contain',
        display: 'flex',
        padding: 2
    },

    submenu: {
        position: 'absolute',
        right: 12,
        bottom: 12,
        alignContent: 'flex-end',
        width: '100%',
        opacity: 0.7,

    },

});



export default styles_ori_landscape;
