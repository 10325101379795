/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator, } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Image, Dimensions, Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Fontisto } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import ci from '../assets/images/ci.png';
import Icon from 'react-native-vector-icons/FontAwesome';
import HeaderBackButton from '@react-navigation/stack';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import asbo from '../assets/images/asbo.png';

import SplashScreen from '../screens/SplashScreen';
import Colors from '../constants/Colors';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import HomeScreen from '../screens/HomeScreen';
import PhotoScreen from '../screens/PhotoScreen';
import VideoScreen from '../screens/VideoScreen';
import DocumentScreen from '../screens/DocumentScreen';
import MusicScreen from '../screens/MusicScreen';
import MusicsScreen from '../screens/MusicsScreen';
import ContactScreen from '../screens/ContactScreen';
import AboutScreen from '../screens/AboutScreen';
import Tags from '../screens/Tags';
import { RootStackParamList, RootTabParamList, RootTab2ParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import styles from '../assets/styles/styles_main';
import LastScreen from '../screens/LastScreen';
import HistoryScreen from '../screens/HistoryScreen';
import WebViewScreen from '../screens/WebViewScreen';
import AcappellaScreen from '../screens/AcappellaScreen';
import SearchScreen from '../screens/SearchScreen';
import ASBOScreen from '../screens/ASBOScreen';
import BestoffScreen from '../screens/BestoffScreen';
import styles_submenu from '../assets/styles/styles_submenu';
import { MaterialIcons } from '@expo/vector-icons';

import { createNavigationContainerRef } from '@react-navigation/native';

import { getStyle, log, logerror } from '../screens/Common';

let { width, height } = Dimensions.get('window');

const navigationRef = createNavigationContainerRef()

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

export function navigateTo(name, params) {
  log('navigateTo  ');
  navigationRef.navigate(name, params);
}


function normalize_nav() {
  var retsize = 80;
  const { width, height } = Dimensions.get('window');
  if (width < 450) {
    retsize = 70;
  }
  if ((width > 450) && (width < 600)) {
    retsize = 55;
  }
  if ((width > 600) && (width < 800)) {
    retsize = 70;
  }
  if (width > 800) {
    retsize = 80;
  }
  return retsize;
}

function normalize_icon() {
  var retsize = 32;
  if (width < 450) {
    retsize = 30;
  }
  if ((width > 450) && (width < 600)) {
    retsize = 26;
  }
  if ((width > 600) && (width < 800)) {
    retsize = 36;
  }
  if (width > 800) {
    retsize = 48;
  }
  return retsize;
}

function add_title_based_on_width(text: String) {
  if (width > 500) {
    return "Cercle Industriel Archives 1872-2022 - " + text;
  } else {
    return text;
  }
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Splash" component={SplashScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false, title: 'CI Archives 1872-2022' }} />
      <Stack.Screen name="History" component={HistoryScreen} options={{ title: add_title_based_on_width('Histoire').toString() }} />
      <Stack.Screen name="Photo" component={PhotoScreen} options={{ headerShown: true, title: add_title_based_on_width('Photos').toString() }} />
      <Stack.Screen name="Document" component={DocumentScreen} options={{ title: add_title_based_on_width('Documents').toString() }} />
      <Stack.Screen name="Music" component={MusicScreen} options={{ title: add_title_based_on_width('Musique').toString() }} />
      <Stack.Screen name="Musics" component={MusicsScreen} options={{ title: add_title_based_on_width('Musiques').toString() }} />
      <Stack.Screen name="Tags" component={Tags} options={{ title: add_title_based_on_width('Personnes').toString() }} />
      <Stack.Screen name="Video" component={VideoScreen} options={{ title: add_title_based_on_width('Videos').toString() }} />
      <Stack.Screen name="Acappella" component={AcappellaScreen} options={{ title: add_title_based_on_width('A cappella').toString() }} />
      <Stack.Screen name="ASBO" component={ASBOScreen} options={{ title: add_title_based_on_width('ASBO').toString() }} />
      <Stack.Screen name="Bestoff" component={BestoffScreen} options={{ title: add_title_based_on_width('Best Off').toString() }} />
      <Stack.Screen name="Search" component={SearchScreen} options={{ title: add_title_based_on_width('Recherche').toString() }} />
      <Stack.Screen name="Contact" component={ContactScreen} options={{ title: add_title_based_on_width('Contact').toString() }} />
      <Stack.Screen name="News" component={WebViewScreen} options={{ title: add_title_based_on_width('News').toString() }} />
      <Stack.Screen name="Last" component={LastScreen} options={{ title: add_title_based_on_width('Dernières archives').toString() }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: add_title_based_on_width('Oops!').toString() }} />
      <Stack.Screen name="CI" component={BottomTab2Navigator} options={{ headerShown: false, title: 'CI Archives 1872-2022' }} />
      <Stack.Screen name="About" component={AboutScreen} options={{ title: add_title_based_on_width('A Propos').toString() }} />

    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();
const BottomTab2 = createBottomTabNavigator<RootTab2ParamList>();

function BottomTabNavigator() {
  const colorScheme = "dark";

  return (
    <BottomTab.Navigator
      // initialRouteName="Splash"
      initialRouteName="Home"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
        tabBarStyle: {
          height: normalize_nav(50),
          borderTopWidth: 4,
          borderTopColor: COLORS.bg6_ci,
          backgroundColor: COLORS.bg1_ci,
        },
        headerShown: false
      }}>
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <TabBarAntIcon name="home" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }}
      />
      <BottomTab.Screen
        name="Photo"
        component={PhotoScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Photos/Programmes',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="camera" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }}
      />
      <BottomTab.Screen
        name="Music"
        component={MusicScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Musiques',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <Entypo name="beamed-note" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }}
      />
      <BottomTab.Screen
        name="Video"
        component={VideoScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Vidéos',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <MaterialIcons name="live-tv" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }}
      />
      <BottomTab.Screen
        name="Bestoff"
        component={BestoffScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Best Off',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <FontAwesome name="star" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }} />
      <BottomTab.Screen
        name="ASBO"
        component={ASBOScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - ASBO',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <Image source={asbo} size={normalize_icon(36)} style={styles.image_asbo_bottom} />,
        }} />
      <BottomTab.Screen
        name="Acappella"
        component={AcappellaScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - A cappella',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <Entypo name="note" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }} />
      {(width > 800) ?
        <BottomTab.Screen
          name="About"
          component={AboutScreen}
          options={{
            title: 'Cercle Industriel Archives 1872-2022 - A Propos',
            tabBarShowLabel: false,
            tabBarIcon: ({ color }) => <AntDesign name="infocirlceo" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
          }} />
        :
        null
      }
      {(width > 800) ?
        <BottomTab.Screen
          name="History"
          component={HistoryScreen}
          options={{
            title: 'Cercle Industriel Archives 1872-2022 - Histoire',
            tabBarShowLabel: false,
            tabBarIcon: ({ color }) => <Image source={ci} size={normalize_icon(36)} style={styles.image_ci_bottom} />,
          }} />
        :
        null
      }

      {(width > 800) ?
        <BottomTab.Screen
          name="Contact"
          component={ContactScreen}
          options={{
            title: 'Cercle Industriel Archives 1872-2022 - Contact',
            tabBarShowLabel: false,
            tabBarIcon: ({ color }) => <AntDesign name="mail" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
          }} />
        :
        null
      }

      {(width > 800) ?
        <BottomTab.Screen
          name="Search"
          component={SearchScreen}
          options={{
            title: 'Cercle Industriel Archives 1872-2022 - Recherche',
            tabBarShowLabel: false,
            tabBarIcon: ({ color }) => <AntDesign name="search1" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
          }} />
        :
        null
      }

      {(width > 800) ?
        <BottomTab.Screen
          name="News"
          component={WebViewScreen}
          options={{
            title: 'Cercle Industriel Archives 1872-2022 - News',
            tabBarShowLabel: false,
            tabBarIcon: ({ color }) => <Entypo name="new" size={normalize_icon(36)} color={"#9999ff"} style={styles.image_ci_icon} />,
          }}
        />
        :
        null
      }

    </BottomTab.Navigator>
  );
}

function BottomTab2Navigator() {
  const colorScheme = "dark";

  return (
    <BottomTab.Navigator
      // initialRouteName="Splash"
      initialRouteName="Home"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
        tabBarStyle: {
          height: normalize_nav(50),
          borderTopWidth: 4,
          borderTopColor: COLORS.bg6_ci,
          backgroundColor: COLORS.bg1_ci,
        },
        headerShown: false
      }}>
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <TabBarAntIcon name="home" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }}
      />
      <BottomTab.Screen
        name="Document"
        component={DocumentScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Documents',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <Ionicons name="document-attach-outline" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }} />
      <BottomTab.Screen
        name="About"
        component={AboutScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - A Propos',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <AntDesign name="infocirlceo" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }} />
      <BottomTab.Screen
        name="History"
        component={HistoryScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Histoire',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <Image source={ci} size={normalize_icon(36)} style={styles.image_ci_bottom} />,
        }} />
      <BottomTab.Screen
        name="Contact"
        component={ContactScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Contact',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <AntDesign name="mail" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }} />
      <BottomTab.Screen
        name="Search"
        component={SearchScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - Recherche',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <AntDesign name="search1" size={normalize_icon(36)} color={color} style={styles.image_ci_icon} />,
        }} />
      <BottomTab.Screen
        name="News"
        component={WebViewScreen}
        options={{
          title: 'Cercle Industriel Archives 1872-2022 - News',
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => <Entypo name="new" size={normalize_icon(36)} color={"#9999ff"} style={styles.image_ci_icon} />,
        }}
      />

    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={normalize_nav(36)} style={{ marginBottom: -3 }} {...props} />;
}
function TabBarAntIcon(props: {
  name: React.ComponentProps<typeof AntDesign>['name'];
  color: string;
}) {
  return <AntDesign size={normalize_nav(36)} style={{ marginBottom: -3 }} {...props} />;
}


