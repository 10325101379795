import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { StyleSheet, Text, View } from 'react-native';
import Constants from 'expo-constants';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  // global.docker = "https://api.gmaxi.com/";
  global.docker = "https://www.gmaxiapi.ovh/";
  global.videos_local = global.docker + 'civideos/';
  global.videos_ovhbe = "https://gmaxi.be/ci/videos/";
  global.videos_ovh = "https://www.gmaxi.ovh/ci/videos/";
  global.videos_easycgi = "http://gmaxi.com/ci/";
  global.musics = "https://gmaxi.be/ci/musics/";
  global.pictures = "https://gmaxi.be/ci/pictures/";


  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <View style={styles_app.container}>
        <SafeAreaProvider>
          <Navigation colorScheme={"dark"} />
          <StatusBar />
        </SafeAreaProvider>
      </View>
    );
  }
}

const styles_app = StyleSheet.create({
  statusBar: {
    height: Constants.statusBarHeight
  },
  container: {
    flex: 1
  }
});