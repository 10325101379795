import React, { useEffect } from 'react';
import { getStyle, log, logerror } from './Common';

import { SafeAreaView } from 'react-native-safe-area-context';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import { TextInput, View, Text, FlatList, TouchableHighlight, Image, Linking, Platform } from 'react-native';
import styles_screens from '../assets/styles/styles_screens'
import SubFlatListSwapMusicSelected from './SubFlatListSwapMusicSelected';
import { getlink, getlink_thumb, getlink_item } from './Common';
import { Video, AVPlaybackStatus } from 'expo-av';

var lineid = true;

export default class MusicsScreen extends React.Component {
    constructor(props) {
        log('MusicsScreen constructor  ');
        super(props);
        this.video = React.createRef();
        this.state = {
            paused: props.paused,
            totalLength: 1,
            currentPosition: 0,
            selectedTrack: 0,
            repeatOn: false,
            shuffleOn: false,
            searchresults: [],
            isSearching: true,
            isMusic: false,
            isPhoto: false,
            isVideo: false,
            selected: [],
            search: "",
            videourl: ""
        }

    }

    componentDidMount() {
        log('MusicsScreen componentDidUpdate  ');
        //  this.stopVideo();
        this.getAllMusics("2022");
        lineid = true;
    }

    componentDidUpdate() {
        log('MusicsScreen componentDidUpdate  ');
        //  this.stopVideo();
    }

    setDuration(data) {
        this.setState({ totalLength: Math.floor(data.duration) });
        log('setDuration   ' + Math.floor(data.duration));
    }

    setTime(data) {
        this.setState({ currentPosition: Math.floor(data.currentTime) });
        log('setTime   ' + Math.floor(data.currentTime));
    }

    seek(time) {
        time = Math.round(time);
        this.refs.audioElement && this.refs.audioElement.seek(time);
        this.setState({
            currentPosition: time,
            paused: false,
        });
        log('seek   ' + time);
    }

    stopVideo() {

        try {
            log('stopVideo   ');
            this.video.current.pauseAsync();
            log('stopVideo done  ');
        } catch (e) {
            log('Error video  pauseAsync')
        }
        try {
            log('stopVideo   ');
            this.video.pauseAsync();
            log('stopVideo done  ');
        } catch (e) {
            log('Error video  pauseAsync')
        }
        log('stopVideo exit  ');
        this.state.videoloading = false;
    }

    getlinkVideo(item: []) {
        let href = global.videos_ovhbe;
        href = href + item.addfield1 + "/" + item.link_href;
        // log('getlinkVideo ' + href);
        return href;
    }

    getlinkVideoPhoto(item: []) {
        let href = global.videos_ovhbe;
        href = href + item.addfield1 + "/" + item.addfield2;
        // log('getlinkPhoto ' + href);
        return href;
    }


    getlinkPhoto_thumb(item: []) {
        let href = global.pictures + item.year + "/";
        if (item.direct != "") {
            href = href + item.direct + "/";
        }
        href = href + "thumb-" + item.link_href + ".jpg";

        // log('getlinkPhoto_thumb ' + href);
        return href;
    }

    getAllMusics = (type: Number) => {
        log('MusicsScreen getAllMusics  ');
        this.setState({ isSearching: true });
        var hlink = global.docker + 'search/allmusicssinger';
        if (type == 2) {
            hlink = global.docker + 'search/allmusicsyear';
        }
        fetch(encodeURI(hlink))
            .then((response) => response.json())
            .then((json) => {
                log('searchFunction ' + json.length);
                this.setState({ searchresults: json });
            }
            )
            .catch((error) => logerror("SearchScreen searchFunction error", error))
            .finally(() => this.setState({ isSearching: false }));
    };

    getTableName(tablename: string) {
        let resptxt = "unknown";
        if (tablename == "video") {
            resptxt = "Video"
        }
        if (tablename == "photo") {
            resptxt = "Photo"
        }
        if (tablename == "music") {
            resptxt = "Musique"
        }
        return resptxt;
    }

    selectItem(item) {
        log('MusicsScreen selectItem ' + JSON.stringify(item));
        //  log('item ' + JSON.stringify(item));
        this.stopVideo();

        global.yearselected = item.year;
        var items = item;
        items.oripicture = item.picture;
        items.orisinger = item.addfield2;
        items.orititle = item.addfield1;
        log('item ' + JSON.stringify(items));

        this.setState({ selected: items })

        if (item.tablename == "music") {
            this.setState({ isMusic: true })
        } else {
            this.setState({ isMusic: false })
        }
        if (item.tablename == "photo") {
            this.setState({ isPhoto: true })
        } else {
            this.setState({ isPhoto: false })
        }
        if (item.tablename == "video") {
            this.setState({ isVideo: true })
            this.setState({ videourl: this.getlinkVideo(item) })

            try {
                this.video.current.playAsync();
            } catch (e) {
                log('Error video  pauseAsync')
            }
        } else {
            this.setState({ isVideo: false })
        }
    }


    openphotourl(imageURL: string) {
        Linking.canOpenURL(imageURL).then(supported => {
            if (supported) {
                if (Platform.OS == 'web') {
                    window.open(imageURL, '_blank');
                } else {
                    Linking.openURL(imageURL);
                }
                1
            } else {
                log("Don't know how to open URI: " + href);
            }
        });
    }

    renderItem = (itemv: string) => {
        // log('SubFlatListPagination renderItem ');
        // log('item ' + JSON.stringify(item));
        var item = itemv.item;
        // log('item  ' + JSON.stringify(item));
        var f1 = this.getTableName(item.tablename);
        var f2 = item.year;
        var f3 = item.title;
        var f4 = "";
        var f5 = "";
        if (item.tablename == "music") {
            f4 = item.addfield1;
            f5 = item.addfield2;
        }
        var pictureUrl = "";
        if (item.tablename == "video") {
            pictureUrl = global.videos_ovhbe + item.addfield1 + "/" + item.addfield2;
        }

        return (
            < View style={[styles_screens.container_border]} >
                <TouchableHighlight
                    onPress={() => this.selectItem(item)}
                >
                    <View style={styles_screens.container_col}>
                        <View style={styles_screens.tableRow}>
                            <View style={styles_screens.tableColumnRegular0}>
                                <Text style={styles_screens.mtext1}>
                                    {f2}
                                </Text>
                            </View>
                            <View style={styles_screens.tableColumnRegular2}>
                                {item.tablename == "music" ?
                                    <Text style={styles_screens.mtext1}>
                                        {f5}
                                    </Text>
                                    :
                                    null
                                }
                            </View>
                            <View style={styles_screens.tableColumnRegular3}>
                                {item.tablename == "music" ?
                                    <Text style={styles_screens.mtext1}>
                                        {f4}
                                    </Text>
                                    :
                                    null
                                }
                            </View>
                        </View>
                        <View style={styles_screens.tableRow}>
                            <View style={styles_screens.tableColumnRegular0}>
                                <Text style={styles_screens.mtext1}>
                                    { }
                                </Text>
                            </View>
                            <View style={styles_screens.tableColumnRegular2}>
                                <Text style={styles_screens.mtext1}>
                                    { }
                                </Text>
                            </View>
                            <View style={styles_screens.tableColumnRegular3}>
                                <Text style={styles_screens.mtext1}>
                                    {f3}
                                </Text>
                            </View>
                            {item.tablename == "video" ?
                                <View style={styles_screens.tableColumnRegular2}>
                                    <Image
                                        style={styles_screens.imageStyleThumb}
                                        source={{ uri: this.getlinkVideoPhoto(item) }}
                                        resizeMode="contain"
                                    />
                                </View>
                                :
                                null
                            }
                            {item.tablename == "photo" ?
                                <Image
                                    style={styles_screens.imageStyleThumb}
                                    source={{ uri: this.getlinkPhoto_thumb(item) }}
                                    resizeMode="contain"
                                />
                                :
                                null
                            }
                        </View>
                    </View>
                </TouchableHighlight>
            </View >

        )
    }
    renderStopButton() {
        if (this.state.playPressed) {
            return (
                <Button
                    onPress={() => {
                        //stop() does not work and video plays again
                        //trick below is a dirty alternative but works
                        setTimeout(() => this.player.seek(0), 0);
                        this.player.pause();
                    }}
                    title="Stop"
                />
            );
        }
    }

    render() {
        log("***** SearchScreen render ");
        return (
            <View style={styles_screens.container}>
                <View style={styles_screens.container_dir}>
                    <View style={styles_screens.container}>
                        <View style={styles_screens.container_search}>
                            {this.state.isSearching ?
                                null
                                :
                                <View style={styles_screens.container}>
                                    <FlatList
                                        data={this.state.searchresults}
                                        renderItem={item => this.renderItem(item)}
                                    />
                                </View>
                            }
                        </View>
                    </View>
                    <View style={styles_screens.container}>
                        {this.state.isMusic ?
                            <View style={styles_screens.container}>
                                <SubFlatListSwapMusicSelected
                                    music={this.state.selected}
                                    yearsel={global.yearselected}
                                />

                            </View>
                            :
                            null
                        }
                        {this.state.isPhoto ?
                            <TouchableHighlight
                                style={styles_screens.imageStyle}
                                key={this.state.selected.id}
                                onPress={() => {


                                    this.openphotourl(getlink_item(this.state.selected.year, this.state.selected));
                                }}>
                                <Image
                                    style={styles_screens.imageStyle}
                                    source={{ uri: getlink_item(this.state.selected.year, this.state.selected) }}
                                    resizeMode="contain"
                                />
                            </TouchableHighlight>
                            :
                            null
                        }
                        {this.state.isVideo ?
                            <View style={styles_screens.container}>
                                <Video
                                    style={styles_screens.video}
                                    ref={r => this.video = r}
                                    source={{
                                        uri: this.state.videourl,
                                    }}

                                    rate={1.0}
                                    volume={1.0}
                                    isMuted={false}
                                    resizeMode="contain"
                                    shouldPlay={true}
                                    useNativeControls
                                />
                            </View>
                            :
                            null
                        }
                    </View>
                </View>
            </View>
        );
    }
}
