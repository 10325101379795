import React, { useEffect, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { BackHandler, ScrollView } from 'react-native';

import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { TouchableHighlight, Platform, Image } from 'react-native';
import { TouchableOpacity, FlatList, StatusBar, Linking } from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import { AntDesign } from '@expo/vector-icons';
import { Fontisto } from '@expo/vector-icons';

import styles_ori_landscape from '../assets/styles/styles_ori_landscape';
import styles_ori_portrait from '../assets/styles/styles_ori_portrait';

import styles_home from '../assets/styles/styles_home';
import styles_common from '../assets/styles/styles_common';
import styles_submenu from '../assets/styles/styles_submenu';

import { Entypo } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';

import cadrephoto from '../assets/images/cadrephoto.png';
import cadrevideo from '../assets/images/cadrevideo.png';
import apple from '../assets/images/apple.jpg';
import google from '../assets/images/google.png';
import ci from '../assets/images/ci.png';
import asbo from '../assets/images/asbo.png';
import empty from '../assets/images/empty.png';
import { checkYearExists } from './Common.tsx';
import { SafeAreaView } from 'react-native-safe-area-context';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import SubMenu from './SubMenu';
import SubMenus from './SubMenus';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getlink_photo_spec_direct, getStyle } from './Common';
import { log, logerror, TabBarIcon, TabBarAntIcon, normalize_style } from './Common';

const windowWidth = Math.round(Dimensions.get('window').width);
const windowHeight = Math.round(Dimensions.get('window').height);
const test = false;

export default function HomeScreen(this: any, { navigation }: RootTabScreenProps<'Home'>) {
  let [id, setId] = useState("");
  let [isLoadingyears, setLoadingyears] = useState(false);
  let [isLoadingyear, setLoadingyear] = useState(false);
  let [isLoadingphotos, setLoadingphotos] = useState(false);
  let [isLoadingvideos, setLoadingvideos] = useState(false);
  let [isLoadingmusics, setLoadingmusics] = useState(false);
  let [isLoading, setLoading] = useState(false);
  let [year, setYear] = useState([]);
  let [screen, setScreen] = useState(Dimensions.get('window'));
  let [styleOri, setStyleOri] = useState(getStyle());
  let [width, setWidth] = useState(windowWidth);
  let [height, setHeight] = useState(windowHeight);

  const [stat, setStat] = useState([]);
  const [years, setYears] = useState([]);
  const [affiche, setAffiche] = useState("https://www.gmaxi.be/ci.png");
  const [photo, setPhoto] = useState("https://www.gmaxi.be/ci.png");
  const [video, setVideo] = useState("https://www.gmaxi.be/ci.png");
  const [music, setMusic] = useState("");
  const [photos, setPhotos] = useState("");
  const [videos, setVideos] = useState("");
  const [musics, setMusics] = useState("");
  const [allmusics, setAllmusics] = useState("");
  const [documents, setDocuments] = useState("");
  const [acs, setAcs] = useState("");
  const [bests, setBests] = useState("");
  const [asbos, setAsbos] = useState("");
  const [tags, setTags] = useState("");

  let [isMenusVisible, setMenusVisible] = useState(true); // default true
  let [isAPPVisible, setAPPVisible] = useState(true); // default true

  const onlyForWeb = true;
  const getData = async () => {
    log('------------  HomeScreen getData ');
    try {
      const value = await AsyncStorage.getItem('@localid')
      log('------------  HomeScreen getData value ' + value);
      if (value !== null) {
        log('------------  HomeScreen getData yes value ');
        setId(value);
      } else {
        log('------------  HomeScreen getData no value ');
        getNewId();
      }
    } catch (e) {
      log('------------  HomeScreen getData error ');
    }
  }

  const storeData = async (value) => {
    log('------------  HomeScreen storeData ' + value);
    try {
      await AsyncStorage.setItem('@localid', JSON.stringify(value))
    } catch (e) {
      // saving error
    }
  }

  function getId() {
    log('------------  HomeScreen getId ');
    getData();
  }

  function handleYears(json: []) {
    log('------------  HomeScreen handleYears ' + json.length);
    setYears(json);
    getYear(global.yearselected);
  }

  function getDynamicflexDirectionmain(dir: string) {
    var stcolumn = { flexDirection: "column" };
    var strow = { flexDirection: "row" };
    var retv = stcolumn;
    if (width > height) {
      retv = strow;
    } else {
      retv = stcolumn;
    }
    // log("HomeScreen getDynamicflexDirectionmain " + dir + " result: " + JSON.stringify(retv) + " width: " + width + " height: " + height);
    return retv;
  }

  function getDynamicflexDirectionphotovideo(dir: string) {
    var retv = { flexDirection: "column" };
    var stcolumn = { flexDirection: "column" };
    var strow = { flexDirection: "row" };
    if (dir == "normal") {
      if (width > height) {
        retv = strow;
      } else {
        retv = stcolumn;
      }
    } else {
      if (width > height) {
        retv = stcolumn;
      } else {
        retv = strow;
      }
    }
    if (width > 700) {
      retv = stcolumn;
    }
    if (height < 700) {
      retv = stcolumn;
    }

    // log("HomeScreen getDynamicflexDirectionphotovideo " + dir + " result: " + JSON.stringify(retv) + " width: " + width + " height: " + height);
    return retv;
  }


  function checkYearsOk() {
    log('------------  HomeScreen checkYearsOk ');
    if (years.length < 50) {
      log('xxxxxxxxxxxx  HomeScreen checkYears NOk ');
      getYears();
    }
  }

  function setNewId(response: JSON) {
    log('------------  HomeScreen setNewId ' + JSON.stringify(response));
    log('------------  HomeScreen setNewId ' + response['insertId']);
    setId(response['insertId']);
    storeData(response['insertId']);
  }

  async function getYears() {
    log('------------  HomeScreen getYears');
    setLoadingyears(false);
    global.best = false;
    var dataObj = {}
    fetch(global.docker + 'year/list_reverse', {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((json) => handleYears(json))
      .catch((error) => logerror("HomeScreen getYears", error))
      .finally(() => setLoadingyears(false));
  }

  async function getNewId() {
    log('------------  HomeScreen getNewId');
    setLoadingyears(false);
    fetch(global.docker + 'stats/setnewid', {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((json) => setNewId(json))
      .catch((error) => logerror("HomeScreen getNewId", error))
  }

  function handleYear(response: JSON) {
    log('------------  HomeScreen handleYear ' + response);
    setYear(response);
    getStat(global.yearselected);
    if (response['key_affiche'] != "") {
      setAffiche(getlink_photo_spec_direct(response['key_affiche']));
    } else {
      setAffiche(ci);
    }
    if (response['key_photo'] != "") {
      setPhoto(getlink_photo_spec_direct(response['key_photo']));
    } else {
      setPhoto(ci);
    }
    if (response['key_afkey_videofiche'] != "") {
      setVideo(getlink_photo_spec_direct(response['key_video']));
    } else {
      setVideo(ci);
    }
    getStats(global.yearselected);
  }

  function setStatyear(response: JSON) {
    log('------------  HomeScreen setStatyear ' + JSON.stringify(response));
    // setStat(response);
    // log('------------  HomeScreen setStatyear response.photos ' + response[0].photos);
    // log('------------  HomeScreen setStatyear response.videos ' + response[0].videos);
    // log('------------  HomeScreen setStatyear response.musics ' + response[0].musics);
    // log('------------  HomeScreen setStatyear response.bests ' + response[0].bests);
    // log('------------  HomeScreen setStatyear response.acs ' + response[0].acs);
    // log('------------  HomeScreen setStatyear response.asbos ' + response[0].asbos);
    // log('------------  HomeScreen setStatyear response.documents ' + response[0].documents);
    setPhotos(response[0].photos);
    setVideos(response[0].videos);
    setMusics(response[0].musics);
    setDocuments(response[0].documents);
    setAcs(response[0].acs);
    setBests(response[0].bests);
    setAsbos(response[0].asbos);
    setTags(response[0].tags);
    setAllmusics(response[0].allmusics);
    global.enablevideo = response[0].enablevideo;
    global.enablevideosearch = response[0].enablevideosearch;
  }

  async function getStat(yearsel: string) {
    log('------------  HomeScreen getStat ' + yearsel);
    fetch(global.docker + 'year/stat?year=' + yearsel, {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((json) => {
        log('------------  HomeScreen getStat ' + JSON.stringify(json));
        setStatyear(json);
      }
      )
      .catch((error) => log("HomeScreen getStat", error))
      .finally(() => setLoadingyear(false));
  }

  async function getYear(yearsel: string) {
    log('------------  HomeScreen getYear ' + yearsel);
    setLoadingyear(true);
    fetch(global.docker + 'year/getyear?year=' + yearsel, {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((json) =>
        handleYear(json)
      )
      .catch((error) => logerror("HomeScreen getYears", error))
      .finally(() => setLoadingyear(false));
  }

  async function getStats(yearsel: string) {
    log('------------  HomeScreen getStats ' + yearsel);
    fetch(global.docker + 'stats/add?id=' + id + '&year=' + yearsel+ '&os=' + Platform.OS, {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((json) =>
        log('------------  HomeScreen getStats done')
      )
      .catch((error) => logerror("HomeScreen getStats", error));
  }


  function _onPhoto(text: String) {
    setLoadingyear(true);
    log('------------  HomeScreen _onPhoto ' + global.yearselected);
    var seldomain_sub = "2";
    if (text == "Affiches") {
      global.yearselected = "2022";
      seldomain_sub = "2";
    }
    navigation.navigate('Photo', { year: global.yearselected, domain_sub: seldomain_sub });
  }

  function _onASBO() {
    log('------------  HomeScreen _onBestOff ');
    global.videosupdaterequested = true;
    global.best = false;
    global.asbo = true;
    global.ac = false;
    navigation.navigate('ASBO', { yearsel: global.yearselected, spec: "asbo" });
  }

  function _onBestOff() {
    log('------------  HomeScreen _onBestOff');
    global.videosupdaterequested = true;
    global.best = true;
    global.asbo = false;
    global.ac = false;
    navigation.navigate('Bestoff', { yearsel: global.yearselected, spec: "best" });
  }

  function _onAcappella() {
    log('------------  HomeScreen _onAcappella');
    global.videosupdaterequested = true;
    global.ac = true;
    global.asbo = false;
    global.best = false;
    navigation.navigate('Acappella', { yearsel: global.yearselected, spec: "ac" });
  }

  function _onVideo() {
    log('------------  HomeScreen _onVideo');
    global.videosupdaterequested = true;
    global.best = false;
    global.asbo = false;
    global.ac = false;
    if (global.yearselected == "Affiches") {
      navigation.navigate('Photo', { yearsel: global.yearselected });
    } else {
      navigation.navigate('Video', { yearsel: global.yearselected, spec: "" });
    }
  }

  function _onMusic() {
    log('------------  HomeScreen _onMusic');
    if (global.yearselected == "Affiches") {
      navigation.navigate('Photo', { yearsel: global.yearselected });
    } else {
      navigation.navigate('Music', { yearsel: global.yearselected });
    }
  }

  function _onMusics() {
    log('------------  HomeScreen _onMusics');
    navigation.navigate('Musics', { yearsel: global.yearselected });
  }

  function _onDocument() {
    log('------------  HomeScreen _onDocument');
    navigation.navigate('Document');
  }

  function _about() {
    log('------------  HomeScreen _about');
    navigation.navigate('About');
  }

  function _test() {
    log('------------  HomeScreen _test');
    getYears();
  }

  function _onHistory() {
    log('------------  HomeScreen _onHistory');
    navigation.navigate('History');
  }

  function _onNews() {
    log('------------  HomeScreen _onNews');
    navigation.navigate('News', { link: "news" });
  }

  function _onContact() {
    log('------------  HomeScreen _onContact');
    navigation.navigate('Contact');
  }

  function _onSearch() {
    log('------------  HomeScreen _onContact');
    navigation.navigate('Search', { searchtxt: '' });
  }
  function _onTags() {
    log('------------  HomeScreen _onTags');
    navigation.navigate('Tags');
  }
  function _onLast() {
    log('------------  HomeScreen _onLast');
    navigation.navigate('Last');
  }

  function openAndroidDownload() {
    log('------------  HomeScreen _onContact');
    if (Platform.OS == "android") {
      Linking.openURL("market://details?id=com.gmaxi.civideoarchives");
    } else {
      Linking.openURL("https://play.google.com/store/apps/details?id=com.gmaxi.civideoarchives");
    }
  }

  function openIOSDownload() {
    log('------------  HomeScreen _onContact');
    Linking.openURL("https://apps.apple.com/be/app/preview-app/id1592954170");
  }

  function selectedId(yearsel: string) {
    log('------------  HomeScreen selectedId year ' + yearsel);
    if (yearsel != "Affiches") {
      log('------------  HomeScreen selectedId year ');

      setAPPVisible(false);
      global.yearselected = yearsel
      setYear(yearsel);

      setLoadingyear(true);
      setLoadingphotos(true);
      log('------------  HomeScreen selectedId ' + yearsel);
      getYear(global.yearselected);
      setMenusVisible(false);
      setAPPVisible(false);
    } else {
      log('------------  HomeScreen selectedId Affiches ');
      global.yearselected = yearsel
      _onPhoto(yearsel);
    }

    log('------------  HomeScreen selectedId closed ' + yearsel);
  }

  function onPressLower() {
    global.yearselected = parseInt(global.yearselected) - 1;
    setLoading(true);
    checkYearExists(-1);
    checkYearsOk();
    setYear(global.yearselected);
    global.yearselectedphotosub = global.yearselected;
    getStat(global.yearselected);
    getStats(global.yearselected);
    getYear(global.yearselected);
    setAPPVisible(false);
  }

  function onPressMore() {
    global.yearselected = parseInt(global.yearselected) + 1;
    checkYearExists(1);
    checkYearsOk();
    setYear(global.yearselected);
    global.yearselectedphotosub = global.yearselected;
    getStat(global.yearselected);
    getStats(global.yearselected);
    getYear(global.yearselected);
    setAPPVisible(false);
  }

  function setOrientation(text: string) {
    log('------------  HomeScreen setOrientation ' + text);
    const { width, height } = Dimensions.get('window');
    const ratio = width / 541; //541 is actual image width


    setStyleOri(styles_ori_landscape);
    setStyleOri(styles_ori_portrait);
  
  }

  useEffect(() => {
    log('------------  HomeScreen seEffect');
    if (Platform.OS != "web") {
      getId();
    }
    global.best = false;
    if (global.yearselected == null) {
      var RandomNumber = Math.floor(Math.random() * 37) + 1985 ;

      global.yearselected = RandomNumber;
      global.selGroupPhoto = 1;
    }
    setLoadingyears(true);
    getYears();

    Dimensions.addEventListener('change', ({ window: { width, height } }) => {
      // log('------------  HomeScreen addEventListener width ' + width + " height " + height);
      setWidth(width);
      setHeight(height);
    })

    StatusBar.setHidden(true, 'none');
    // _onASBO();
    //  _about();
    // _onVideo();
    // _onDocument();
    // _onPhoto();
    // _onMusic();
    // _onMusics();
    // _onNews();
    // _onBestOff();
    // _onSearch();
    // _onHistory();
    // _onTags();
    // _onLast();


    setTimeout(() => {
      setMenusVisible(false);
    }, 8000);

    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => true);
    setTimeout(() => {
      setAPPVisible(false);
      log('------------  HomeScreen time passed');
    }, 3500);
    return () => backHandler.remove()


  }, [])

  function renderMenuItem() {
    return (
      <Text style={styles_submenu.command}>
        is: {global.yearselected}
      </Text>
    )
  }

  function styleSelectedYear(seld: string, ayearselected: string) {
    if (seld == global.yearselected) {
      return styles_home.year_text;
    } else {
      return styles_home.year_text;
    }
  }

  function onLayout() {
    setScreen(Dimensions.get('window'));
  }

  return (
    <SafeAreaView style={[styles_common.container, styles_common.container_bg_main]}>
      <View style={styles_common.container_hor} >
        {/* LEFT */}
        <View style={styles_home.container_home_lefts}>

          <TouchableHighlight onPress={() => _onHistory()}>
            <Image source={ci} style={styles_home.image_ci} />
          </TouchableHighlight>

          <View style={styles_home.container_home_left_years}>
            {isLoadingyears ? <Text>Loading...</Text> :
              (
                <FlatList
                  nestedScrollEnabled={false}
                  data={years}
                  keyExtractor={({ id }) => id.toString()}
                  renderItem={
                    ({ item }) => <TouchableOpacity
                      onPress={() => selectedId(item.title)} >
                      <View>
                        <Text style={styleSelectedYear(item.title, year)}>{item.title}</Text>
                      </View>
                    </TouchableOpacity>
                  } />
              )}
          </View>
        </View>

        {/* RIGHT */}
        <View style={styles_home.container_home_rights}>
          {/* RIGHT YEAR SEL TOP */}
          <View style={styles_home.container_home_right_top_years} >

            <View style={styles_home.container_home_right_top_year_hor_main}>

              <View style={styles_home.container_home_right_top_year_hor}>
                <TouchableHighlight
                  onPress={() => onPressLower()}>
                  <Text style={styles_home.toplinetext}>{'<<'}</Text>
                </TouchableHighlight>

                {isLoadingyear ?
                  <Text style={styles_home.toplinetext}>---- </Text>
                  :
                  <Text style={styles_home.toplinetext}>{global.yearselected}</Text>
                }

                <TouchableHighlight
                  onPress={() => onPressMore()}>
                  <Text style={styles_home.toplinetext}>{'>>'}</Text>
                </TouchableHighlight>
              </View>

              {(width > 900) ?
                <View style={styles_home.container_home_cols}>
                  <View style={styles_home.container_hor_top}>
                    <TouchableHighlight
                      onPress={() => _onTags()}>
                      <Ionicons name="people" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ham_icon_sub1} />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => _onMusics()}>
                      <Entypo name="text-document" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ham_icon_sub1} />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => _onDocument()}>
                      <Ionicons name="document-attach-outline" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ham_icon_sub1} />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => _onLast()}>
                      <Entypo name="back-in-time" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ham_icon_sub1} />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => _onContact()}>
                      <AntDesign name="mail" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ham_icon_sub1} />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => _about()}>
                      <AntDesign name="infocirlceo" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ham_icon_sub1} />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => _onSearch()}>
                      <AntDesign name="search1" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ham_icon_sub1} />
                    </TouchableHighlight>
                  </View>
                </View>
                :
                null}

              <View style={styles_home.container_home_right_top_year_space}>
              </View>

              <View style={styles_home.container_home_right_top_year_ham}>
                <TouchableHighlight
                  onPress={() => setMenusVisible(!isMenusVisible)}>
                  <View style={styles_home.container}>
                    <Entypo name="menu" size={normalize_style(20)} color={"#ffffff"} style={styles_submenu.image_ham_icon} />
                    <Text style={styles_home.text_ham}>Menu</Text>
                  </View>
                </TouchableHighlight>

              </View>
            </View>

          </View>
          {/* RIGHT MAIN  */}
          <View style={[styles_home.container_home_right_main, getDynamicflexDirectionmain("normal")]} >

            {/* RIGHT MAIN MID PHOTOS - VIDEOS*/}
            <View style={[styles_home.container_home_right_main_zone_pv, getDynamicflexDirectionphotovideo("normal")]} >
              {/* PHOTOS */}
              <TouchableHighlight
                style={styles_home.zone_pv_photos_touchable}
                onPress={() => _onPhoto()} >

                <View style={styles_home.zone_pv_photos_view} >
                  <Image
                    source={{ uri: photo }}
                    style={styles_home.zone_pv_photos_image_sel}
                    resizeMode="contain"
                  />
                  <Image
                    source={cadrephoto}
                    style={styles_home.zone_pv_photos_image_bg}
                    resizeMode="stretch"
                  />
                </View>
              </TouchableHighlight>

              {/* VIDEOS */}
              <TouchableHighlight
                style={styles_home.zone_pv_videos_touchable}
                onPress={() => _onVideo()}>
                <View style={styles_home.zone_pv_videos_view}>
                  <View style={styles_home.zone_pv_videos_image_zone_image_sel_col} >
                    <View style={styles_home.zone_pv_videos_image_zone_image_sel_row} >
                      <Image
                        source={{ uri: video }}
                        style={styles_home.zone_pv_videos_image_sel}
                        resizeMode="contain"
                      />
                      <View style={styles_home.zone_pv_videos_image_zone_right} />
                    </View>
                  </View>
                  <Image
                    source={cadrevideo}
                    style={styles_home.zone_pv_videos_image_bg}
                    resizeMode="stretch"
                  />
                </View>
              </TouchableHighlight>
            </View>

            {/* RIGHT MAIN BOTTOM AFFICHE */}
            <View style={styles_home.container_home_right_main_zone_bottom_affiche} >
              <TouchableHighlight
                style={styles_home.zone_af_touchable}
                onPress={() => _onPhoto()}>
                <View style={styles_home.zone_af_view}>

                  {isMenusVisible ?
                    <Image source={empty} style={styles_home.zone_af_image} resizeMode="contain" />
                    :
                    <Image source={{
                      uri: affiche
                    }} style={styles_home.zone_af_image} resizeMode="contain" />

                  }

                </View>
              </TouchableHighlight>


            </View>

          </View>
        </View>

        {isMenusVisible ?
          <ScrollView
            showsVerticalScrollIndicator={onlyForWeb}
            style={styles_home.scrollView}>
            <View style={styles_home.container}>

              <TouchableHighlight
                onPress={() => _onNews("https://www.gmaxiapi.ovh/150/150.html")}>
                <View style={styles_submenu.container_linegr}>
                  <View style={[styles_submenu.container_line_left, styles_submenu.bgr0]}>
                    <Entypo name="new" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={[styles_submenu.container_line_mid, styles_submenu.bgr2]}>
                    <Text style={[styles_submenu.command, styles_submenu.padding4]}>
                      CI, AILouvain 150 ans en 2022!
                    </Text>
                  </View>
                  <View style={[styles_submenu.container_line_right, styles_submenu.bgr1]}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onTags()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Ionicons name="people" size={normalize_style(14)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      Personnes
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {tags}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onHistory()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Image source={ci} style={styles_submenu.image_ci_icon2} />
                  </View>
                  <View style={styles_submenu.container_line_mid}>
                    <Text style={styles_submenu.command}>
                      150 ans d'archives
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => {
                  _onPhoto((global.yearsel == "Affiches" ? "2022" : global.yearsel));
                }
                }>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <TabBarIcon name="camera" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      Photos {global.yearselected}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {photos}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onMusic()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Entypo name="beamed-note" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      Musiques {global.yearselected}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {musics}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onMusics()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Entypo name="text-document" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      Musiques liste
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {allmusics}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onVideo()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <MaterialIcons name="live-tv" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      Videos {global.yearselected}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {videos}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onBestOff()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <FontAwesome name="star" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      Best Off
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {bests}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onASBO()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Image source={asbo} style={styles_submenu.image_asbo_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      ASBO
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {asbos}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onAcappella()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Entypo name="note" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      A cappella
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {acs}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onDocument()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Ionicons name="document-attach-outline" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid1}>
                    <Text style={styles_submenu.command}>
                      Documents
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_mid2}>
                    <Text style={styles_submenu.commandright}>
                      {documents}
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              {!(Platform.OS === 'android') ?
                <TouchableHighlight
                  onPress={() => _onSearch()}>
                  <View style={styles_submenu.container_line}>
                    <View style={styles_submenu.container_line_left}>
                      <AntDesign name="search1" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                    </View>
                    <View style={styles_submenu.container_line_mid}>
                      <Text style={styles_submenu.command}>
                        Recherche
                      </Text>
                    </View>
                    <View style={styles_submenu.container_line_right}>
                      <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                    </View>
                  </View>
                </TouchableHighlight>
                :
                <TouchableHighlight
                  onPress={() => _onSearch()}>
                  <View style={styles_submenu.container_line}>
                    <View style={styles_submenu.container_line_left}>
                      <AntDesign name="search1" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                    </View>
                    <View style={styles_submenu.container_line_mid}>
                      <Text style={styles_submenu.command}>
                        Recherche
                      </Text>
                    </View>
                    <View style={styles_submenu.container_line_right}>
                      <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                    </View>
                  </View>
                </TouchableHighlight>
              }

              <TouchableHighlight
                onPress={() => _onLast()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <Entypo name="back-in-time" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid}>
                    <Text style={styles_submenu.command}>
                      Dernières entrées
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              <TouchableHighlight
                onPress={() => _onContact()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <AntDesign name="mail" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid}>
                    <Text style={styles_submenu.command}>
                      Contact 
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>


              <TouchableHighlight
                onPress={() => _about()}>
                <View style={styles_submenu.container_line}>
                  <View style={styles_submenu.container_line_left}>
                    <AntDesign name="infocirlceo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                  <View style={styles_submenu.container_line_mid}>
                    <Text style={styles_submenu.command}>
                      A propos
                    </Text>
                  </View>
                  <View style={styles_submenu.container_line_right}>
                    <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                  </View>
                </View>
              </TouchableHighlight>

              {test ?
                <TouchableHighlight
                  onPress={() => _test()}>
                  <View style={styles_submenu.container_line}>
                    <View style={styles_submenu.container_line_left}>
                      <AntDesign name="infocirlceo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                    </View>
                    <View style={styles_submenu.container_line_mid}>
                      <Text style={styles_submenu.command}>
                        test  - v167 {width} {height}
                      </Text>
                    </View>
                    <View style={styles_submenu.container_line_right}>
                      <AntDesign name="rightcircleo" size={normalize_style(10)} color={"#ffffff"} style={styles_submenu.image_ci_icon} />
                    </View>
                  </View>
                </TouchableHighlight>
                :
                null}
            </View>
          </ScrollView>
          :
          null
        }

        {/* ANDROID IOS */}
        {((Platform.OS == "web") && (isAPPVisible)) ?
          <View style={styles_home.zone_androidios_absolute}>
            <View style={styles_home.zone_androidios_container}>
              <View style={styles_home.zone_androidios_lineclosed}>
                <View style={styles_home.zone_androidios_line_midclosed}>
                  <Text style={styles_home.zone_androidios_text}>
                    CI 1872-2022 150 ans
                  </Text>
                  <Text style={styles_home.zone_androidios_text}>
                    Application
                    Disponible
                  </Text>
                </View>
              </View>
              <View style={styles_home.zone_androidios_container}>
                <TouchableHighlight style={styles_home.zone_androidios_container}
                  onPress={() => openAndroidDownload(true)}>
                  <View style={styles_home.zone_androidios_right}>
                    <Image source={google} style={styles_home.zone_androidios_ios_icon} />
                  </View>
                </TouchableHighlight>
                <TouchableHighlight style={styles_home.zone_androidios_container}
                  onPress={() => openIOSDownload(true)}>
                  <View style={styles_home.zone_androidios_right}>
                    <Image source={apple} style={styles_home.zone_androidios_ios_icon} />
                  </View>
                </TouchableHighlight>
              </View>
            </View>
          </View>
          :
          null
        }


      </View >
    </SafeAreaView >
  );
}