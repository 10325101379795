/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: ['https://www.gmaxi.ovh/'],
  config: {
    screens: {
      Root: {
        path: '',
        screens: {
          Home: '',
          Acappella: 'acappella',
          ASBO: 'asbo',
          Bestoff: 'bestoff',
          Music: 'music',
          Search: 'search',
          Video: 'video',
          Photo: 'photo',
          History: 'history',
          Musics: 'musics',
          Contact: 'contact',
          About: 'about',
          News: 'news',
          Tags: 'tags',
          Document: 'document',
          Last: 'last',
          NotFound: 'notfound',
      },
      },
    },
  },
};

export default linking;
/*
Photo: {
  path: 'photo/:year?',
  parse: {
    year: (year) => `${year}`,
  },
  stringify: {
    year: (year) => year,
  },
},
*/