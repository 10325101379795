import * as React from 'react';

import { Dimensions, Platform, PixelRatio } from 'react-native';

import styles_ori_landscape from '../assets/styles/styles_ori_landscape';
import styles_ori_portrait from '../assets/styles/styles_ori_portrait';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Value } from 'react-native-reanimated';


let { width, height } = Dimensions.get('window');


var scale = width / 640;

if (height > width) {
    scale = height / 500;
} 

export function log(text: string) {
 // console.log(text);  
}

export function logerror(functionText: string, text: string) {
// console.error(functionText + ": " + text);
}

export function normalize_style(size : Number) {
  const newSize = size * scale
  if (Platform.OS === 'ios') {
      return Math.round(PixelRatio.roundToNearestPixel(newSize))
  } else {
      return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
  }
}

export function normalize_style_font(size : Number) {
  const newSize = size * scale
  if (Platform.OS === 'ios') {
      return Math.round(PixelRatio.roundToNearestPixel(newSize))
  } else {
      return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
  }
}

export function getOrientation() {
  if (width > height) {
    //log("getOrientation LANDSCAPE");
    return 'LANDSCAPE';
  } else {
    //log("getOrientation PORTRAIT");
    return 'PORTRAIT';
  }
}

export function getStyle() {
  if (getOrientation() == 'LANDSCAPE') {
    return styles_ori_landscape;
  } else {
    return styles_ori_portrait;
  }
}

export function getlink_photo_spec_direct(photosel: string) {
  let href = global.pictures + photosel;
  // log("href " + href);
  return href;
}

export function getlink_photo_spec(yearsel: string, direct: string, photosel: string) {
  // log('getlink_photo_spec ');
  let href = yearsel + "/";
  if (direct != "") {
    href = href + direct + "/";
  }
  href = href + photosel + ".jpg";
  // log("href " + href);
  return getlink_photo_spec_direct(href); 
}

export function getlink_year(photo: string) {
  return getlink_photo_spec(global.yearsel, "", photo);
}

export function getlink_music_photo(yearsel: string, photo: string) {
  var purl = getlink_photo_spec(yearsel, "", photo);
  log('getlink_music_photo ' + purl);
  return purl ;
}

export function getlink_thumb_item(yearsel: string, item: []) {
  return getlink_photo_spec(yearsel, item.direct, item.link_href);
}

export function getlink_thumb(yearsel: string, data: []) {
  const item = data.item;
  return getlink_photo_spec(yearsel, item.direct, "thumb-" + item.link_href);
}

export function getlink_item(yearsel: string, item: []) {
  //  const item = data.item;
  log('getlink_item ' + yearsel + " " + item.direct + " " + item.link_href);
  return getlink_photo_spec(yearsel, item.direct, item.link_href);
}

export function getlink(yearsel: string, item: []) {
  const href = getlink_photo_spec(yearsel, item.direct, item.linl_href);
  return href;
}

export function getlink_photo(yearsel: string, item: []) {
  // const item = data.item;
  // log('item ' + JSON.stringify(item));
  const href = getlink_photo_spec(yearsel, item.direct, item.linl_href);
  return href;
}

export function checkYearExistsMusic(direction: Number) {
  const yearsel = global.yearselected.toString();
  if (yearsel < "1980") {
    global.yearselected = "1980";
  }
  checkYearExists(direction);
}

export function checkYearExistsVideo(direction: Number) {
  const yearsel = global.yearselected.toString();
  if (yearsel < "1981") {
    global.yearselected = "1981";
  }
  checkYearExists(direction);
}

export function checkYearExists(direction: Number) {
  var yearsel = global.yearselected.toString();
  if (yearsel == "NaN") {
    yearsel = "2022";
    global.yearselected = "2022";
  }
  log('---------------------------yearsel ' + yearsel);
  if (direction == 1) {
    switch (yearsel) {

      case '1876':
        global.yearselected = "1879";
        break;

      case '1884':
        global.yearselected = "1886";
        break;

      case '1887':
        global.yearselected = "1889";
        break;

      case '1892':
        global.yearselected = "1893";
        break;

      case '1895':
        global.yearselected = "1896";
        break;

      case '1897':
        global.yearselected = "1898";
        break;

      case '1915':
        global.yearselected = "1922";
        break;

      case '1923':
        global.yearselected = "1926";
        break;

      case '1932':
        global.yearselected = "1933";
        break;

      case '1936':
        global.yearselected = "1940";
        break;

      case '1941':
        global.yearselected = "1951";
        break;

      case '1952':
        global.yearselected = "1956";
        break;

      case '1973':
        global.yearselected = "1974";
        break;

      case '1975':
        global.yearselected = "1976";
        break;

      case '1871':
        global.yearselected = "1872";
        break;

      case '2023':
        global.yearselected = "2022";

    }
  }


  if (direction == -1) {
    switch (yearsel) {

      case '1878':
        global.yearselected = "1875";
        break;

      case '1880':
        global.yearselected = "1879";
        break;

      case '1885':
        global.yearselected = "1883";
        break;

      case '1888':
        global.yearselected = "1886";
        break;

      case '1895':
        global.yearselected = "1894";
        break;

      case '1897':
        global.yearselected = "1896";
        break;

      case '1921':
        global.yearselected = "1914";
        break;

      case '1925':
        global.yearselected = "1922";
        break;

      case '1932':
        global.yearselected = "1931";
        break;

      case '1939':
        global.yearselected = "1935";
        break;

      case '1950':
        global.yearselected = "1940";
        break;

      case '1955':
        global.yearselected = "1951";
        break;

      case '1973':
        global.yearselected = "1972";
        break;

      case '1975':
        global.yearselected = "1974";
        break;

      case '1871':
        global.yearselected = "1872";
        break;

      case '2023':
        global.yearselected = "2022";

    }
  }
}

export function setStats(title: string, oper: string, yearsel: string, id: string) {
  log('getgetStatsYear ' + yearsel);
  fetch(global.docker + 'stats/selection?title=' + title + "&oper=" + oper + "&yearsel=" + yearsel + "&id=" + id)
    .then((response) => response.json())
    .then((json) =>
      log("setStats done")
    )
    .catch((error) => logerror("setStats", error));
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */

export function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

export function TabBarAntIcon(props: {
  name: React.ComponentProps<typeof AntDesign>['name'];
  color: string;
}) {
  return <AntDesign size={30} style={{ marginBottom: -3 }} {...props} />;
}
