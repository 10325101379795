import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';

import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width

const fontfamilymain = "Roboto-Bold";

import { getLineTextPadding } from './styles__functions';

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.bg1_ci,
        flex: 1,
    },
      container0: {
        backgroundColor: COLORS.bg0_ci,
        flex: 1,
    },
    container_main: {
        backgroundColor: COLORS.bg1_ci,
    },
    container_row: {
        backgroundColor: "#555555",
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between',
    },
    container_block: {
        margin: 5,
        borderWidth: 1,
        alignItems: 'center',
        borderColor: "#ff0000",
        flex: 1,
        width: '100%',
    },
    titleStyle: {
        padding: 16,
        fontSize: 28,
        color: 'white',
    },
    container_main_hor: {
        backgroundColor: COLORS.bg1_ci,
        flexDirection: "row",
        justifyContent: 'space-between',
    },
    container_empty:{
        backgroundColor: COLORS.bg0_ci,
        height: 10,
    },
    container_years: {
        backgroundColor: COLORS.bg1_ci,
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
        flex: 4,
    },
    container_selection: {
        backgroundColor: COLORS.bg1_ci,
        flexDirection: "row",
        justifyContent: 'space-evenly',
        width: '100%',
        flex: 6,
    },
    container_space: {
        backgroundColor: COLORS.bg1_ci,
        width: '100%',
        flex: 1,
    },
    container_main_hor_right: {
        backgroundColor: COLORS.bg1_ci,
        flexDirection: "row",
        justifyContent: 'space-evenly',
    },

    container_main_hor_flex: {
        flex: 1,
        backgroundColor: COLORS.bg1_ci,
        flexDirection: "row",
        justifyContent: 'space-evenly',
    },
    touchable: {
        flex: 1,
        width: "100%"
    },
    container_main_ver: {
        backgroundColor: '#ff0066',
        flexDirection: "column",
        justifyContent: 'space-evenly',
    },
    container_left_flexhigh: {
        flex: 6,
    },
    container_left_flexlow: {
        flex: 1,
    },
    image_photo: {
        margin: 8,
        padding: 8,
        height: 362 * ratio, //362 is actual height of image
        width: width,
    },
    icon: {
        padding: 8,
    },
    toplinetext: {
        color: COLORS.white,
        ...FONTS.h3,
        backgroundColor: COLORS.bg1_ci,
        textAlign: 'center', // <-- the magic
        marginLeft: getLineTextPadding(),
        marginRight: getLineTextPadding(),
        paddingTop: getLineTextPadding(),
        paddingBottom: getLineTextPadding(),
    },
    toplinetextNotActive: {
        backgroundColor: COLORS.bg2_ci,
        flex: 1,
        justifyContent: 'space-evenly',
        color: "#333333",
        ...FONTS.h5,
        textAlign: 'center', // <-- the magic
        paddingTop: 4,
        paddingBottom: 2,
    },
    toplinetextSelected: {
        backgroundColor: COLORS.bg5_ci,
        flex: 1,
        color: COLORS.white,
        ...FONTS.h5,
        justifyContent: 'space-evenly',
        textAlign: 'center', // <-- the magic
        paddingTop: 4,
        paddingBottom: 2,
    },
    toplinetextNotSelected: {
        backgroundColor: COLORS.bg3_ci,
        flex: 1,
        color: "#333333",
        ...FONTS.h5,
        justifyContent: 'space-evenly',
        textAlign: 'center', // <-- the magic
        paddingTop: 4,
        paddingBottom: 2,
    },
    toplinetext2
        : {
            flex: 1,
            color: COLORS.white,
        ...FONTS.h5,
        justifyContent: 'center',
        textAlign: 'center', // <-- the magic
        paddingTop: 4,
        paddingBottom: 2,
        width: '100%',
    },
    year_text: {
        color: COLORS.white,
        ...FONTS.h3,
        backgroundColor: '#222288',
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },

    item_text: {
        color: COLORS.white,
        ...FONTS.h3,
        backgroundColor: '#4466ff',
        textAlign: 'center', // <-- the magic
        margin: 0,
        paddingTop: 8,
        paddingBottom: 4,
    },
    flex1: {
        flex: 1,
        color: COLORS.white,
        backgroundColor: '#ffffff',
    },

    viewThumbnail: {
        backgroundColor: COLORS.bg2_ci,
        margin: 3,
    },

    imageThumbnail: {
        justifyContent: 'center',
        alignItems: 'center',
        width: (width - 10 - (5 * 5)) / 5,
        height: height / 5,
        resizeMode: 'contain',
    },


    flatlist: {
        overflow: 'visible',
        backgroundColor: COLORS.bg2_ci,
        borderWidth: 0,
    },

    scrollview_left: {
        backgroundColor: COLORS.bg1_ci,
        flexGrow: 6,
        minWidth: 90,
        maxHeight: height - 115
    },
    container_main_left_down: {
        flex: 6,
        backgroundColor: COLORS.bg3_ci,
        flexDirection: "column",
    },

});

export default styles;
