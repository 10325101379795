import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';


const styles_splash = StyleSheet.create({
    container: {
      padding:30,
      flex: 1,
      flexDirection: 'column',
      backgroundColor: COLORS.bg2_ci,
    },
    container_top: {
      flex: 8,
      backgroundColor: COLORS.bg2_ci,
    },
    container_mid: {
      flex: 3,
      backgroundColor: COLORS.bg2_ci,
    },
    container_bottom: {
      flex: 1,
      backgroundColor: COLORS.bg2_ci,
    },
    image_ci: {
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      flex: 1,
      minHeight: 90,
      minWidth: 60,
      margin: 8,
      width: "95%",
      height: "95%",
      resizeMode: 'contain'
    },
  
  });
  

export default styles_splash;