import React, { useEffect, useState } from 'react';
import { StatusBar, Dimensions } from 'react-native';


import { RootTabScreenProps } from '../types';

import styles_common from '../assets/styles/styles_common';

import SubPhotosCountView from '../screens/SubPhotosCountView';
import { useIsFocused } from "@react-navigation/native";
import { SafeAreaView } from 'react-native-safe-area-context';
import { log, logerror } from './Common';

export default function PhotoScreen({ route, navigation }: RootTabScreenProps<'Photo'>) {
    log("****************** PhotoScreen");

    var vspec = "";
    var domain_sub = "1";
    try {
        log("PhotoScreen route " + route)
        log("PhotoScreen route.params " + JSON.stringify(route.params))
        log("PhotoScreen route.params.year " + route.params.year)
        log("PhotoScreen route.params.domain_sub " + route.params.domain_sub)
        vspec = route.params.year;
        domain_sub = route.params.domain_sub;
    } catch (e) {
        log('PhotoScreen route.params no param')
    }

    if (vspec) { global.yearselected = vspec; }

    if (global.yearselected == null) {
        global.yearselected = "1987";
        domain_sub = "1";
        global.yearselectedphoto = global.yearselected;
    }
    log("***** PhotoScreen year " + global.yearselected);

    function refreshFunction() {
        log("***** PhotoScreen refreshFunction ");

    }

    useEffect(() => {
        log("***** PhotoScreen useEffect ");
    }, [])

    return (
        <SafeAreaView style={styles_common.container_fl1_bg1}>
            <SubPhotosCountView
                year={global.yearselected}
                domain_sub={domain_sub}
            />
        </SafeAreaView>
    );
}

