import React, { useCallback, memo, useRef, useState } from "react";

import { StyleSheet, Text, View, Dimensions, WebView } from 'react-native';
import { SafeAreaView, FlatList, Modal, Image, TouchableHighlight } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import styles_swap from '../assets/styles/styles_swap';
import { getlink } from './Common.tsx';
import { getlink_thumb, getlink_item } from "./Common";
import ci from '../assets/images/ci.png';
import { Video, AVPlaybackStatus } from 'expo-av';
import { log } from './Common';
import { normalize_style } from "../assets/constants/theme";
import styles from '../assets/styles/styles_subFlatListSwapVideo';
import SubTagList from './SubTagList';

export default class SubFlatListSwapVideo extends React.Component {
  video = React.createRef();

  constructor(props) {
    log('SubFlatListSwapVideo constructors  ');
    super(props);
    this.state = {
      special: true,
      videoloading: true,
      playing: false,
      useYoutube: false,
      id_video: 0,
      index: 0,
      imageSrc: ci.ci,
      modalVisibleStatus: false,
      videos: [], 
      video_current_nosabam: "",
      videosexists: true,
      videosacive: false,
      videourl: ''
    }
    this.state.special = this.props.special;
    this.state.videos = this.props.videos;
    log('SubFlatListSwapVideo JSON.stringify(this.state.videos).length ' + JSON.stringify(this.state.videos).length);
    if (JSON.stringify(this.state.videos).length > 10) {
      this.state.videosexists = true;
    }
    else {
      this.state.videosexists = false;
    }
    log('SubFlatListSwapVideo constructors ' + this.props.special);
    // log('SubFlatListSwapVideo  ' + JSON.stringify(this.props.videos));
    this.yearsel = this.props.yearsel;
    log('yearsel  ' + this.yearsel);
    //  log('SubFlatListSwap constructors  ' + this.state.imageSrc);
  }

  // This method will execute everytime a state or props change.
  componentDidUpdate(prevProps) {
    if ((this.props.videos != prevProps.videos)) {
      log('SubFlatListSwapVideo componentDidUpdate  ');
      // log('SubFlatListSwapVideo componentDidUpdate prevProps ' + JSON.stringify(prevProps.videos));
      // log('SubFlatListSwapVideo componentDidUpdate videos ' + JSON.stringify(this.props.videos));
      log('SubFlatListSwapVideo JSON.stringify(this.props.videos).length ' + JSON.stringify(this.props.videos).length);
      log('SubFlatListSwapVideo JSON.stringify(prevProps.videos).length ' + JSON.stringify(prevProps.videos).length);
      this.setState({ videos: this.props.videos })
      log('SubFlatListSwapVideo JSON.stringify(this.state.videos).length ' + JSON.stringify(this.state.videos).length);
      if (JSON.stringify(this.props.videos).length > 10) {
        this.state.videosexists = true;
      }
      else {
        this.state.videosexists = false;
      }
      this.state.videosacive = (global.enablevideo == "1");
      this.state.videosacive = this.state.videosacive || (global.enablevideosearch && global.searchsucceed)

      log('SubFlatListSwapVideo videosacive  videosacive ' + this.state.videosacive);
      log('SubFlatListSwapVideo videosacive  global.enablevideo ' + global.enablevideo);
      log('SubFlatListSwapVideo videosacive  global.enablevideosearch ' + global.enablevideosearch);
      log('SubFlatListSwapVideo videosacive  global.searchsucceed ' + global.searchsucceed);
      // log('yearsel  ' + this.yearsel);
      // log('groupsel  ' + this.groupsel);
      this.groupsel = this.props.groupsel;
      this.yearsel = this.props.yearsel;
      this.setState({ index: 0 })
      this.setState({ special: this.props.special })
      const item = this.props.videos[0];
      this.setState({ loading: false })
      // log('SubFlatListSwap componentDidUpdate  item ' + JSON.stringify(item));
    }
    if (this.video && !this.state.videoloading) {
      log('video  pauseAsync');
      try {
        this.video.current.stopAsync();
      } catch (e) {
        log('Error video  pauseAsync')
      }
    }
    this.state.videoloading = false;
    //    log('SubFlatListSwap componentDidUpdate  ' + JSON.stringify(this.state.imageSrc));
  }

  closevideo() {
    log('SubFlatListSwapVideo closevideo');
    try {
      this.video.current.stopAsync();
    } catch (e) {
      log('Error video  pauseAsync')
    }
  }

  componentDidMount(props) {
    log('SubFlatListSwapVideo componentDidMount');
  }

  componentWillUnmount(props) {
    log('SubFlatListSwapVideo componentWillUnmount');
    this.closevideo();
  }

  getlinkVideo(item: []) {
    // log('item ' + JSON.stringify(item));
    // let href = global.docker + 'civideos/' + item.vdir + "/" + item.link_href;
    log('SubFlatListSwapVideo componentDidUpdate getlinkVideo ' + item.sourceFile);
    let href = global.videos_easycgi;
    if (item.sourceFile == "OVH") {
      href = global.videos_ovh;
    }
    if (item.sourceFile == "OVHbe") {
      href = global.videos_ovhbe;
    }
    if (item.sourceFile == "LOCAL") {
      href = global.videos_local;
    }
    href = href + item.vdir + "/" + item.link_href;
    log('SubFlatListSwapVideo componentDidUpdate getlinkVideo ' + href);
    return href;
  }

  getlinkPhoto(item: []) {
    //log("getlinkVideo " + item.sourceFile);
    let href = global.docker + 'ci/videos/';
    if (item.sourceFile == "OVH") {
      //log("getlinkVideo OVH " + item.sourceFile);
      href = global.videos_ovh;
    }
    if (item.sourceFile == "OVHbe") {
      //log("getlinkVideo OVHbe " + item.sourceFile);
      href = global.videos_ovhbe;
    }
    if (item.sourceFile == "LOCAL") {
      href = global.videos_local;
    }
    href = href + item.vdir + "/" + item.photo;

    //log('getlinkPhoto ' + href);
    return href;
  }

  viewItem(item) {
    log('videoloading: true ');
    this.setState({ videoloading: true })
    log('viewItem ');
    log('item ' + JSON.stringify(item));
    // setStats("video", "select", this.yearsel, item.link_href);
    this.setState({ videourl: this.getlinkVideo(item) })
    this.setState({ id_video: item.id });
    this.setState({ video_current_nosabam: (item.nosabam == "1") });

    //    this.setState({ videourl: 'https://youtu.be/NDQGWLE2_xQ' })
    // this.setState({ videourl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4' })
    //this.setState({ videourl: 'https://youtu.be/NDQGWLE2_xQ' })
    this.state.videoloading = false;
    log('videoloading: false ');
  }

  renderItem = (item) => {
    //  log('SubFlatListSwapVideo renderItem in ');
    //  log('SubFlatListSwapVideo renderItem item ' + JSON.stringify(item));
    //log('SubFlatListSwapVideo renderItem item ' + JSON.stringify(item.item));
    //  log('SubFlatListSwapVideo renderItem special  ' + this.state.special);
    const retval =
      < TouchableHighlight
        onPress={() => this.viewItem(item.item)
        }
        style={styles_swap.container}
      >
        <View style={styles_swap.container}>
          <Image
            style={styles.slideImageThumb}
            source={{ uri: this.getlinkPhoto(item.item) }}
            resizeMode="contain"
          />
          {
            this.state.special ?
              <Text style={styles_swap.text_video_year} >
                {item.item.year}
              </Text>
              : null
          }
        </View>
      </TouchableHighlight>

    //  log('SubFlatListSwapVideo renderItem out ');
    return retval;
  }

  render() {
    function viewPrevious() {
      indexRef.current = indexRef.current - 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);
    }

    function viewNext() {
      indexRef.current = indexRef.current + 1;
      setIndex(indexRef.current);
      log('indexRef.current ' + indexRef.current);
      onScroll;
    }

    return (
      <SafeAreaView style={styles.container_hor}>
        <View style={styles.container_videoleft}  >
          {(this.state.videosexists) ?
            <FlatList
              data={this.state.videos}
              style={styles.carousel}
              renderItem={item => this.renderItem(item)}
              pagingEnabled
              vertical
            />
            :
            <View style={styles.container}  >
              <Image
                style={styles.slideImageThumb}
                source={{ uri: "https://gmaxi.be/ci/pictures/novideo.png" }}
                resizeMode="contain"
              />
            </View>
          }

        </View>

        {this.state.loading ?
          <Text le={styles.titleStyle}>
            Loading
          </Text>
          :
          <View style={styles.container_videoright}>

            {(this.state.videourl == "") ?
              <Text style={styles.titleStyle}>
                {'<-- Sélectionner une vidéo'}
              </Text>
              :
              <View style={styles.container_max}>
                <SubTagList
                  type={"video"}
                  id={this.state.id_video}
                  style={styles.container_subtags}
                />
                {
                  (this.state.videosacive || this.state.video_current_nosabam)?
                    < Video
                      style={styles.video}
                      ref={this.video}
                      source={{
                        uri: this.state.videourl,
                      }}
                      rate={1.0}
                      volume={1.0}
                      isMuted={false}
                      resizeMode="contain"
                      shouldPlay={true}
                      useNativeControls
                    />
                    :
                    <Text style={styles.titleStyle}>
                      {"Désole, pour des raisons de droits d'auteur, nous ne pouvons diffuser cette vidéo."  }
                    </Text>

                }

              </View>
            }
          </View>
        }




      </SafeAreaView>
    )
  }
}
