import {
    StyleSheet,
} from 'react-native';

import { COLORS, FONTS, SIZES, icons, images } from '../constants';


const styles_common = StyleSheet.create({
    container: {
        flex: 1,
    },
    
    container_bg_main: {
        backgroundColor: COLORS.bg1_ci,
    },

    container_fl1_bg1: {
        backgroundColor: COLORS.bg1_ci,
        flex: 1,
    },

    container_hor: {
        backgroundColor: 'transparent', 
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        flex: 1,
        flexDirection: "row",  // enum('row', 'row-reverse', 'column', 'column-reverse')
        width: '100%',
    },

    title: {
        marginTop: 16,
        paddingVertical: 8,
        borderWidth: 4,
        borderColor: '#20232a',
        borderRadius: 6,
        backgroundColor: COLORS.bg4_ci,
        color: '#20232a',
        display: 'flex',
        textAlign: 'center',
        fontSize: 30,
        fontWeight: 'bold',
    },
    link: {
        marginTop: 16,
        paddingVertical: 8,
        borderWidth: 4,
        borderColor: '#20232a',
        borderRadius: 6,
        backgroundColor: COLORS.bg4_ci,
        color: '#20232a',
        display: 'flex',
        textAlign: 'center',
        fontSize: 30,
        fontWeight: 'bold',
    },

});

export default styles_common;