import React, { useCallback, memo, useRef, useState } from "react";

import { Text, View, Dimensions, TextInput } from 'react-native';
import { TouchableHighlight, FlatList } from 'react-native';
import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import styles_tag from '../assets/styles/styles_tag';
import { log, logerror } from './Common';


export default class SubTagList extends React.Component {

  constructor(props) {
    log('SubTagList constructors  ');
    super(props);
    this.state = {
      loading: false,
      tags: [],
      type: this.props.type,
      id: this.props.id,
      tagsearch: [],
      textsearch: "",
    }
    this.getTags(this.state.type, this.state.id);

  }

  componentDidUpdate(prevProps) {
    log('SubTagList componentDidUpdate ');
    if ((this.props != prevProps)) {
      log('SubTagList changed ');
      this.setState({ type: this.props.type })
      log('SubTagList type ' + this.state.type + ' id ' + this.state.id)
      if (this.props.id != this.state.id) {
        log('SubTagList this.props.id changed ' + this.props.id);
        this.setState({ id: this.props.id })
        this.getTags(this.state.type, this.props.id);
      }
    }
    this.state.loading = false;
    //    log('SubFlatListSwap componentDidUpdate  ' + JSON.stringify(this.state.imageSrc));
  }

  componentDidMount() {
    log('SubTagList  componentDidMount');
    this.getTags(this.state.type, this.state.id);
  }

  componentWillUnmount() {
    log('SubTagList  componentWillUnmount');
  }

  getTags = (type, id) => {
    log('SubTagList getTags ' + type + '&id=' + id);
    log('SubTagList geturl ' + global.docker + 'tag/listtypeid?type=' + type + '&id=' + id);
    fetch(global.docker + 'tag/listtypeid?type=' + type + '&id=' + id, {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    }
    )
      .then((response) => response.json())
      .then((json) => this.setState({ tags: json }))
      .catch((error) => logerror("SubTagList listtypeid", error))
      .finally(() => this.setState({ loading: false }));
  }

  searchFunction = (text) => {
    log('SubTagList searchFunction text ' + text);
    this.setState({ textsearch: text })
    var textlen = 2;
    if (text.length > 0) {
      var s = text.substring(0, 1);
      if ((s == "1") || (s == "2")) {
        textlen = 3;
      }
    }
    if (text.length > textlen) {
      this.setState({ isSearching: true });
      var hlink = global.docker + 'tag/searchtag?text=' + text;
      fetch(encodeURI(hlink), {
        method: 'get',
        headers: {
          'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        }
      }
      )
        .then((response) => response.json())
        .then((json) => {
          log('SubTagList searchFunction ' + json.length);
          this.setState({ tagsearch: json });
        }
        )
        .catch((error) => logerror("SearchScreen searchFunction error", error))
        .finally(() => this.setState({ isSearching: false }));
    }
  };

  addTag = () => {
    log('SubTagList addTag geturl ' + global.docker + 'tag/addtagnew?text=' + this.state.textsearch);
    if (this.state.textsearch.length > 4) {

      fetch(global.docker + 'tag/addtagnew?text=' + this.state.textsearch, {
        method: 'get',
        headers: {
          'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        }
      }
      )
        .then((response) => response.json())
        .then((json) => this.addlink(json.insertId))
        .catch((error) => logerror("addtagnew listtypeid", error))
        .finally(() => this.setState({ loading: false }));
    }
  }

  deleteTag = (id) => {
    log('SubTagList addTag geturl ' + global.docker + 'tag/deletemaptagid?id=' + id);
    fetch(global.docker + 'tag/deletemaptagid?id=' + id, {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    }
    )
      .then((response) => response.json())
      .then((json) => this.getTags(this.state.type, this.state.id))
      .catch((error) => logerror("deleteTag ", error))
      .finally(() => this.setState({ loading: false }));
  }

  addlink = (insertId) => {
    log('SubTagList json.insertId ' + insertId);
    var type = this.state.type
    var id = this.state.id
    log('SubTagList addlink geturl ' + global.docker + 'tag/addtaglink?type=' + this.props.type + '&id_ori=' + id + '&id_tag=' + insertId);

    fetch(global.docker + 'tag/addtaglink?type=' + this.props.type + '&id_ori=' + id + '&id_tag=' + insertId, {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      }
    }
    )
      .then((response) => response.json())
      .then((json) => this.getTags(this.state.type, this.state.id)
      )
      .catch((error) => logerror("addtagnew addlink", error))
  }


  renderItemSearchTag(itemv, type, id) {
    const item = itemv.item
    log("SubTagList renderItemSearchTag id " + item.id + " type " + type + " id " + id);
    return (
      <TouchableHighlight
        onPress={() => this.addlink(item.id)}>
        <View style={styles_tag.container_noflex}>
          <Text style={styles_tag.text_tags}> + {item.title}</Text>
        </View>
      </TouchableHighlight>
    );
  }


  renderItem(itemv) {
    const item = itemv.item
    // log("this.state.photos " + this.state.photos);
    return (
      <View style={styles_tag.container_row}>
        <Text style={styles_tag.text_tags}>- {item.title}   </Text>
        {item.today ?
          <TouchableHighlight
            onPress={() => this.deleteTag(item.id)}>
            <Text style={styles_tag.text_tags}>X</Text>
          </TouchableHighlight>
          :
          null
        }
      </View>
    );
  }

  render() {
    var stylesel = styles_tag.container_row_top;
    if (width < height) {
      stylesel = styles_tag.container_cols;
    }

    return (
      <View style={stylesel} >
        {(this.state.tags.length > 0) ?
          <View style={styles_tag.container_no_flex}>
            <Text style={styles_tag.text}>Tags :</Text>
            <FlatList
              data={this.state.tags}
              renderItem={item => this.renderItem(item)}
            />
          </View>
          :
          <View style={styles_tag.container_no_flex} >
            <Text style={styles_tag.text}>Pas encore de Tag</Text>
          </View>
        }
        <View style={styles_tag.container_add_tag} >
          <View style={styles_tag.container_row} >
            <TextInput
              autoCapitalize="none"
              autoCorrect={false}
              clearButtonMode="always"
              onChangeText={(text) => this.searchFunction(text)}
              placeholder="Ajouter tag"
              style={styles_tag.search}
            />
            <TouchableHighlight
              onPress={() => this.addTag()}>
              <Text style={styles_tag.text}> +</Text>
            </TouchableHighlight>
          </View>
          {this.state.isSearching ?
            <View style={styles_tag.container_search_flatlist}>
            </View>
            :
            <View style={styles_tag.container_search_flatlist}>
              <FlatList
                data={this.state.tagsearch}
                renderItem={item => this.renderItemSearchTag(item, this.state.type, this.state.id)}
              />
            </View>
          }
        </View>
      </View >
    )
  }
}

const { width: windowWidth, height: windowHeight } = Dimensions.get("window");
const { width, height } = Dimensions.get('window');
const ratio = width / 541; //541 is actual image width
