import React, { useCallback, memo, useRef, useState } from "react";

import { Text, View } from '../components/Themed';

import { ScrollView, FlatList, TouchableOpacity, TouchableHighlight, Linking } from 'react-native';

import styles_photos from '../assets/styles/styles_photos';
import SubFlatListSwapVideo from './SubFlatListSwapVideo';
import { log, logerror, checkYearExistsVideo } from './Common';

export default class SubVideosCountViev extends React.Component {
    constructor(props) {
        log('SubVideosCountViSubVideosCountVievev constructors  ');
        super(props);
        this.state = {
            ac: this.props.ac, asbo: this.props.asbo, best: this.props.best, special: (this.props.ac || this.props.asbo || this.props.best),
            groupsel: false, videos: [], isLoadingvideogroup: true, isLoadingvideoisOnlinegroup: true, yearsel: global.yearselected
        }
        log('SubVideosCountViev constructors special ' + this.state.special + ' ac ' + this.state.ac + ' asbo:' + this.state.asbo + ' best:' + this.state.best);
    }

    componentDidUpdate(prevProps) {
        //log('SubVideosCountViev componentDidUpdate prevProps ac ' + this.prevProps.ac + ' asbo:' + this.prevProps.asbo + ' best:' + this.prevProps.best);
        log('SubVideosCountViev componentDidUpdate props special ' + this.state.special + ' ac ' + this.props.ac + ' asbo:' + this.props.asbo + ' best:' + this.props.best);
        if (this.video && !this.state.videoloading) {
            log('SubVideosCountViev video  pauseAsync');
            try {
                this.video.current.pauseAsync();
            } catch (e) {
                log('SubVideosCountViev Error video  pauseAsync')
            }
        }
        if ((global.yearselectedvideosub != global.yearselected) || (this.props.videos != prevProps.videos)) {
            global.yearselectedvideosub = global.yearselected;
            this.getInitialData();
            this.groupsel = this.props.groupsel;
            this.yearsel = this.props.yearsel;
        }

    }


    componentDidMount(props) {
        log('SubVideosCountViev  componentDidMount');
        this.setState({
            ac: this.props.ac,
            asbo: this.props.asbo,
            best: this.props.best,
            special: (this.props.ac || this.props.asbo || this.props.best)
        })
        log('SubVideosCountViev componentDidMount special ' + this.state.special + ' ac ' + this.state.ac + ' asbo:' + this.state.asbo + ' best:' + this.state.best);

        global.yearselectedvideosub = global.yearselected;
        this.getInitialData();
    }

    onPressLower() {
        global.yearselected = parseInt(global.yearselected) - 1;
        this.setState({ best: false });
        checkYearExistsVideo(-1);
        global.yearselectedvideosub = global.yearselected;
        this.getInitialData();
    }

    onPressMore() {
        global.yearselected = parseInt(global.yearselected) + 1;
        this.setState({ best: false });
        checkYearExistsVideo(1);
        global.yearselectedvideosub = global.yearselected;
        this.getInitialData();
    }

    getlinkVideo(item: []) {
        let href = global.docker + 'civideos/' + item.vdir + "/" + item.link_href;
        log('SubVideosCountViev getlinkVideo ' + href);
        return href;
    }

    getVideosGroup = () => {
        log('SubVideosCountViev getVideoGroup ac ' + this.state.ac + ' asbo:' + this.state.asbo + ' best:' + this.state.best);
        var hlink = global.docker + 'video/getvideosfromgroup?year=' + global.yearselected;
        if (this.state.best) {
            hlink = global.docker + 'video/getvideosspec?spec=best';
        }
        if (this.state.asbo) {
            hlink = global.docker + 'video/getvideosspec?spec=asbo';
        }
        if (this.state.ac) {
            hlink = global.docker + 'video/getvideosspec?spec=ac';
        }
        log('SubVideosCountViev getVideoGroup ' + encodeURI(hlink));
        fetch(encodeURI(hlink), {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((json) => {
                log('SubVideosCountViev getVideosGroup json.length ' + json.length);
                // log('getVideosGroup ' + JSON.stringify(json));
                //     log('getVideosGroup > 0' + JSON.stringify(json));
                this.setState({ videos: json });
                // log('getVideosGroup 0 ' + JSON.stringify(json));
            }
            )
            .catch((error) => logerror("SubVideosCountViev getPhotosCount", error))
            .finally(() => this.setState({ isLoadingvideogroup: false }));
    };

    getInitialData = () => {
        log('SubVideosCountViev getInitialData ');
        this.state.yearsel = global.yearselected;
        this.setState({ videos: [] });

        this.getVideosGroup();
    }


    render() {

        return (
            <View style={styles_photos.container} >

                {!(this.state.best || this.state.asbo || this.state.ac) ?

                    <View style={styles_photos.container_main_hor}>
                        <View style={styles_photos.container_years}>
                            <TouchableHighlight
                                onPress={() => this.onPressLower()}>
                                <Text style={styles_photos.toplinetext}>{'<<'}</Text>
                            </TouchableHighlight>
                            <Text style={styles_photos.toplinetext}>{global.yearselected}</Text>
                            <TouchableHighlight
                                onPress={() => this.onPressMore()}>
                                <Text style={styles_photos.toplinetext}>{'>>'}</Text>
                            </TouchableHighlight>
                        </View>
                    </View>
                    :
                    null
                }
                <View style={styles_photos.container_main_hor}>
                    {this.state.best ?
                        <View style={styles_photos.container_main_hor}>
                            <Text style={styles_photos.titleStyle}>Best Off</Text>
                        </View>
                        :
                        null
                    }
                    {this.state.asbo ?
                        <View style={styles_photos.container_main_hor}>
                            <Text style={styles_photos.titleStyle}>ASBO</Text>
                        </View>
                        :
                        null
                    }
                    {this.state.ac ?
                        <View style={styles_photos.container_main_hor}>
                            <Text style={styles_photos.titleStyle}>A cappella</Text>
                        </View>
                        :
                        null
                    }
                </View>

                <View style={styles_photos.container}>
                    {(this.state.videos.lenght > 0) ?
                        <Text>
                            Pas de vidéos pour cette année.
                        </Text>
                        :
                        <SubFlatListSwapVideo
                            special={this.state.special}
                            videos={this.state.videos}
                            groupsel={this.state.groupsel}
                            yearsel={global.yearselected}
                        />
                    }
                </View>
            </View >
        )
    }
}

