import React, { useEffect, useCallback, memo, useRef, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';

import { RootTabScreenProps } from '../types';

import { Text, View } from '../components/Themed';

import { ScrollView, FlatList, TouchableOpacity, Image, Linking, TouchableHighlight } from 'react-native';
import { Button } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import { COLORS, FONTS, SIZES, icons, images } from '../assets/constants';
import styles_ori_landscape from '../assets/styles/styles_ori_landscape';
import styles_ori_portrait from '../assets/styles/styles_ori_portrait';
import styles_photos from '../assets/styles/styles_photos';
import styles_main from '../assets/styles/styles_main'

import SubFlatListBrowser from './SubFlatListBrowser.js'
import SubFlatListPagination from './SubFlatListPagination.js'
import SubFlatListSwap from './SubFlatListSwap.js'

import { checkYearExists } from './Common.tsx';

import { Entypo } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { log, logerror } from './Common';

const { width, height } = Dimensions.get('window');

export default class SubPhotosCountViev extends React.Component {
    constructor(props) {
        log('xxx SubPhotosCountViev ');
        super(props);
        this.state = {
            selGroupId: props.domain_sub,
            togdisplay: false,
            photos: false,
            photos1: [],
            photos2: [],
            photos3: [],
            photos4: [],
            isLoadingphotosgroup: false,
            isLoadingphotogroup: false,
            isOnline: false,
        }
    }

    viewGallery() {
        log('xxx SubPhotosCountViev viewGallery');
        this.setState({ togdisplay: true });
        this.getInitialData();
    }

    viewFlat() {
        log('xxx SubPhotosCountViev viewFlat');
        this.setState({ togdisplay: false });
        this.getInitialData();
    }

    onPressLower() {
        global.yearselected = parseInt(global.yearselected) - 1;
        checkYearExists(-1);
        global.yearselectedphotosub = global.yearselected;
        this.getInitialData();
    }

    onPressMore() {
        global.yearselected = parseInt(global.yearselected) + 1;
        checkYearExists(1);
        global.yearselectedphotosub = global.yearselected;
        this.getInitialData();
    }

    selectedId(seld: number, groupid: number) {
        // log('xxx SubPhotosCountViev selectedId');
        if (seld == groupid) {
            return styles_photos.toplinetextSelected;
        } else {
            return styles_photos.toplinetextNotSelected;
        }
    }

    getInitialData = () => {
        log('xxx SubPhotosCountViev SubPhotosCountViev getInitialData ' + global.yearselected);
        this.setState({ photos: [] });
        var selGroup = this.getGroupFromId(this.state.selGroupId);
        this.getPhotosStats();

    }
    setSelected(selid: string) {
        log('xxx SubPhotosCountViev setSelected ' + selid);
        global.selGroupPhoto = selid;
        this.setState({ selGroupId: selid });
        this.getPhotosGroup(selid);
    }

    getGroupFromId(selid: string) {
        log('xxx SubPhotosCountViev getGroupFromId ' + selid);
        var vselGroup = "program";
        if (selid == "1") {
            vselGroup = "photo";
        }
        if (selid == "2") {
            vselGroup = "program";
        }
        if (selid == "3") {
            vselGroup = "history";
        }
        if (selid == "4") {
            vselGroup = "ailv";
        }
        // log('getGroupFromId vselGroup ' + vselGroup);
        return vselGroup;
    }

    getNumCols() {
        var numcols = 4;
        if (width < 500) {
            numcols = 1;
        }
        if ((width >= 500) && (width < 900)) {
            numcols = 2;
        }
        if ((width >= 900) && (width < 1500)) {
            numcols = 3;
        }
        if ((width >= 1500) && (width < 2000)) {
            numcols = 4;
        }
        if ((width >= 2000) && (width < 2500)) {
            numcols = 5;
        }
        if ((width >= 3000)) {
            numcols = 6;
        }
        return numcols;
    }

    getNextGroupAvailable(ori: string, xph: string, xpr: string, xhi: string, xai: string) {
        log('xxx getNextGroupAvailable ori ' + ori + ' xph ' + xph + ' xpr ' + xpr + ' xhi ' + xhi + ' xai ' + xai);
        var retval = "1";
        var firstavailable = "1";
        var needAnother = false;
        if (xph != "0") { firstavailable = "1" }
        else {
            if (xpr != "0") { firstavailable = "2" }
            else {
                if (xhi != "0") { firstavailable = "3" }
                else {
                    if (xai != "0") { firstavailable = "4" }

                }
            }
        }
        switch (ori) {
            case '1':
                if (xph = "0") {
                    needAnother = true;
                }
                break;
            case '2':
                if (xpr = "0") {
                    needAnother = true;
                }
                break;
            case '3':
                if (xhi = "0") {
                    needAnother = true;
                }
                break;
            case '4':
                if (xai = "0") {
                    needAnother = true;
                }
                break;
        }

        if (needAnother) {
            retval = firstavailable;
        }
        log('xxx getNextGroupAvailable getStat firstavailable ' + firstavailable);
        log('xxx getNextGroupAvailable getStat needAnother ' + needAnother);
        log('xxx getNextGroupAvailable getStat ori ' + ori);
        log('xxx getNextGroupAvailable getStat retval ' + retval);

        return retval;
    }

    getSelected = (yearsel: string) => {
        log('xxx SubPhotosCountViev getSelected ' + yearsel);
        fetch(global.docker + 'photo/listgroups?year=' + yearsel, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((json) => this.handle_setPhotosGroups(json))
            .catch((error) => logerror("SubPhotosCountViev getPhotosCount", error))
            .finally(() => this.setState({ loadingphotosgroup: false })
            );
    }



    getPhotosStats = () => {
        log('xxx SubPhotosCountViev getPhotosStats ');
        const hlink = global.docker + 'photo/statsyear?year=' + global.yearselected;
        // log('getPhotoGroup ' + encodeURI(hlink));
        fetch(encodeURI(hlink), {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((json) => {
                // log("xxxxxxxx getPhotosStats " + JSON.stringify(json));
                // log("xxxxxxxx getPhotosStats ph " + (json[0].photos));
                // log("xxxxxxxx getPhotosStats pr " + (json[0].programs));
                // log("xxxxxxxx getPhotosStats hi " + (json[0].historys));
                // log("xxxxxxxx getPhotosStats ai " + (json[0].ailvs));
                this.setState({ photos1: json[0].photos })
                this.setState({ photos2: json[0].programs })
                this.setState({ photos3: json[0].historys })
                this.setState({ photos4: json[0].ailvs })
                var groupsel = this.getNextGroupAvailable(this.state.selGroupId, json[0].photos, json[0].programs, json[0].historys, json[0].ailvs)
                log("xxxxxxxx getPhotosStats groupsel " + groupsel);
                this.getPhotosGroup(groupsel);
            }
            )
            .catch((error) => logerror("SubPhotosCountViev getPhotosStats ", error))
            .finally(() => this.setState({ isOnline: true })
            );
    }

    getPhotosGroup = (selGroup: string) => {

        log('xxx SubPhotosCountViev getPhotosGroup ' + selGroup);
        const hlink = global.docker + 'photo/getphotosfromgroup?year=' + global.yearselected + "&kgroup=" + this.getGroupFromId(selGroup);
        log('xxx SubPhotosCountViev getPhotosGroup ' + encodeURI(hlink));
        fetch(encodeURI(hlink), {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((json) => {
                log('xxx SubPhotosCountViev getPhotosGroup handlePhotoGroup selgroup ' + selGroup);
                this.setState({ photos: json })
                this.setState({ selGroupId: selGroup })
            })
            .catch((error) => logerror("SubPhotosCountViev getPhotosCount", error))
            .finally(() => {
            });
    }


    componentDidMount(props) {
        log('xxx SubPhotosCountViev useEffect ');
        log('xxx SubPhotosCountViev useEffect ' + this.props.domain_sub);
        if (this.props.domain_sub > 0) {
            this.setState({ selGroupId: this.props.domain_sub })

        }
        if (global.yearselected != global.yearselectedphotosub) {
            global.yearselectedphotosub = global.yearselected;
        }
        this.getInitialData();
    }

    componentDidUpdate(prevProps) {
        //log('SubPhotosCountViev componentDidUpdate prevProps ac ' + this.prevProps.ac + ' asbo:' + this.prevProps.asbo + ' best:' + this.prevProps.best);
        log('SubPhotosCountViev componentDidUpdate props special ' + this.state.special + ' ac ' + this.props.ac + ' asbo:' + this.props.asbo + ' best:' + this.props.best);
       
        if ((global.yearselectedvideosub != global.yearselected) || (this.props.photos != prevProps.photos)) {
            global.yearselectedvideosub = global.yearselected;
            this.getInitialData();
            this.groupsel = this.props.groupsel;
            this.yearsel = this.props.yearsel;
        }
    }


    render() {
        return (
            <View style={styles_photos.container}>
                <View style={styles_photos.container_empty}>
                </View>
                <View style={styles_photos.container_main_hor}>
                    <View style={styles_photos.container_years}>
                        <TouchableHighlight
                            onPress={() => this.onPressLower()}>
                            <Text style={styles_photos.toplinetext}>{'<<'}</Text>
                        </TouchableHighlight>
                        <Text style={styles_photos.toplinetext}>{global.yearselected}</Text>
                        <TouchableHighlight
                            onPress={() => this.onPressMore()}>
                            <Text style={styles_photos.toplinetext}>{'>>'}</Text>
                        </TouchableHighlight>
                    </View>
                    <View style={styles_photos.container_space}>
                        <Text style={styles_photos.toplinetext2}>{''}</Text>
                    </View>

                    <View style={styles_photos.container_selection}>
                        {this.state.photos1 ?
                            <TouchableHighlight style={this.selectedId(1, this.state.selGroupId)}
                                onPress={() => this.setSelected("1")}>
                                <Text style={styles_photos.toplinetext2}>{'Photo'}</Text>
                            </TouchableHighlight>
                            :
                            <View style={styles_photos.toplinetextNotActive}>
                                <Text style={styles_photos.toplinetextNotActive}>{'Photo'}</Text>
                            </View>
                        }
                        {this.state.photos2 ?
                            <TouchableHighlight style={this.selectedId(2, this.state.selGroupId)}
                                onPress={() => this.setSelected("2")}>
                                <Text style={styles_photos.toplinetext2}>{'Prog'}</Text>
                            </TouchableHighlight>
                            :
                            <View style={styles_photos.toplinetextNotActive}>
                                <Text style={styles_photos.toplinetextNotActive}>{'Prog'}</Text>
                            </View>
                        }
                        {this.state.photos3 ?
                            <TouchableHighlight style={this.selectedId(3, this.state.selGroupId)}
                                onPress={() => this.setSelected("3")}>
                                <Text style={styles_photos.toplinetext2}>{'Hist'}</Text>
                            </TouchableHighlight>
                            :
                            <View style={styles_photos.toplinetextNotActive}>
                                <Text style={styles_photos.toplinetextNotActive}>{'Hist'}</Text>
                            </View>
                        }
                        {this.state.photos4 ?
                            <TouchableHighlight style={this.selectedId(4, this.state.selGroupId)}
                                onPress={() => this.setSelected("4")}>
                                <Text style={styles_photos.toplinetext2}>{'AILV'}</Text>
                            </TouchableHighlight>
                            :
                            <View style={styles_photos.toplinetextNotActive}>
                                <Text style={styles_photos.toplinetextNotActive}>{'AILV'}</Text>
                            </View>
                        }
                    </View>
                </View>
                <View style={styles_photos.container_empty}>
                </View>

                {
                    this.state.isOnline ?
                        <View style={styles_photos.container0}>
                            <SubFlatListBrowser
                                photos={this.state.photos}
                                groupsel={this.state.selGroupId}
                                yearsel={global.yearselected}
                                numcols={this.getNumCols()}
                            />
                        </View >
                        :
                        null
                }

            </View >
        )
    }
}

